// Home page - orchestrates components for display

import '../components/AppLayout/AppLayout.js';
import '../components/HeroSection/HeroSection.js';

export function loadHomePage() {
  const app = document.getElementById('app');
  if (!app) {
    return;
  }

  app.innerHTML = `
    <app-layout>
      <hero-section></hero-section>
      
      <section class="showcase-section">
        <div class="container">
          <h2>Crafted for Impact</h2>
          <p class="section-subtitle">Every detail designed to make your gratitude memorable</p>
          
          <div class="showcase-grid">
            <div class="showcase-item">
              <div class="card-showcase">
                <div class="card-mock card-mock-1">
                  <div class="card-stripe"></div>
                  <div class="card-content-preview">
                    <div class="card-preview-header"></div>
                    <div class="card-preview-body">
                      <div class="preview-line"></div>
                      <div class="preview-line"></div>
                    </div>
                  </div>
                </div>
                <div class="card-mock card-mock-2">
                  <div class="card-stripe"></div>
                  <div class="card-content-preview">
                    <div class="card-preview-header"></div>
                    <div class="card-preview-body">
                      <div class="preview-line"></div>
                      <div class="preview-line"></div>
                    </div>
                  </div>
                </div>
                <div class="card-mock card-mock-3">
                  <div class="card-stripe"></div>
                  <div class="card-content-preview">
                    <div class="card-preview-header"></div>
                    <div class="card-preview-body">
                      <div class="preview-line"></div>
                      <div class="preview-line"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="showcase-content">
                <h3>Thoughtfully Designed</h3>
                <p>Each template is crafted to perfectly frame your message of appreciation, featuring elegant layouts and beautiful typography.</p>
              </div>
            </div>
            
            <div class="showcase-item">
              <div class="showcase-content">
                <h3>Customizable Collection</h3>
                <p>Choose from dozens of designs, colors, and styles to match the perfect tone for your thank you message.</p>
              </div>
              <div class="card-showcase card-showcase-alt">
                <div class="card-mock card-mock-alt-1">
                  <div class="card-stripe card-stripe-alt-1"></div>
                  <div class="card-content-preview">
                    <div class="card-preview-header card-preview-header-alt"></div>
                    <div class="card-preview-body">
                      <div class="preview-line"></div>
                      <div class="preview-line"></div>
                    </div>
                  </div>
                </div>
                <div class="card-mock card-mock-alt-2">
                  <div class="card-stripe card-stripe-alt-2"></div>
                  <div class="card-content-preview">
                    <div class="card-preview-header card-preview-header-alt"></div>
                    <div class="card-preview-body">
                      <div class="preview-line"></div>
                      <div class="preview-line"></div>
                    </div>
                  </div>
                </div>
                <div class="card-mock card-mock-alt-3">
                  <div class="card-stripe card-stripe-alt-3"></div>
                  <div class="card-content-preview">
                    <div class="card-preview-header card-preview-header-alt"></div>
                    <div class="card-preview-body">
                      <div class="preview-line"></div>
                      <div class="preview-line"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="testimonials-section">
        <div class="container">
          <h2>What People Say</h2>
          <div class="testimonials-grid">
            <blockquote class="testimonial">
              <p>"The perfect way to express gratitude. Simple, elegant, and meaningful."</p>
              <cite>— Sarah Johnson, Designer</cite>
            </blockquote>
            <blockquote class="testimonial">
              <p>"Makes sending thank you cards a delightful experience. Highly recommended!"</p>
              <cite>— Michael Chen, Entrepreneur</cite>
            </blockquote>
          </div>
        </div>
      </section>

      <section class="cta-section">
        <div class="container">
          <h2>Ready to Express Your Gratitude?</h2>
          <p>Join thousands who make their thank you cards memorable</p>
          <div class="cta-buttons">
            <a href="#/create" class="btn-primary">Start Creating</a>
            <a href="#/pricing" class="btn-secondary">View Pricing</a>
          </div>
        </div>
      </section>
    </app-layout>
  `;

  // Add styles
  const style = document.createElement('style');
  style.textContent = `
    section {
      padding: var(--spacing-4xl) var(--spacing-md);
      position: relative;
      overflow: hidden;
    }

    .container {
      max-width: var(--content-width-lg);
      margin: 0 auto;
      padding: 0 var(--spacing-md);
      position: relative;
      z-index: 2;
    }

    h2 {
      font-family: var(--font-display);
      font-size: var(--text-4xl);
      font-weight: var(--font-bold);
      text-align: center;
      margin-bottom: var(--spacing-md);
      color: var(--color-gray-900);
      position: relative;
    }

    h2::after {
      content: '';
      display: block;
      width: 80px;
      height: 4px;
      background: linear-gradient(90deg, var(--color-primary), var(--color-primary-light));
      margin: var(--spacing-md) auto 0;
      border-radius: var(--radius-full);
    }

    .section-subtitle {
      font-size: var(--text-xl);
      color: var(--color-gray-500);
      text-align: center;
      margin-bottom: var(--spacing-2xl);
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      line-height: 1.6;
    }

    /* Showcase Section */
    .showcase-section {
      background: linear-gradient(180deg, var(--color-white) 0%, var(--color-gray-50) 100%);
      position: relative;
      padding-top: var(--spacing-6xl);
      margin-top: var(--spacing-2xl);
    }

    .showcase-section::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: radial-gradient(var(--color-gray-200) 1px, transparent 1px);
      background-size: 30px 30px;
      opacity: 0.3;
      z-index: 1;
    }

    .showcase-grid {
      display: grid;
      gap: var(--spacing-2xl);
      margin-top: var(--spacing-3xl);
      box-shadow: var(--shadow-xl);
      border-radius: var(--radius-lg);
      overflow: hidden;
      transition: transform var(--transition-normal);
    }

    .showcase-grid:hover {
      transform: translateY(-5px);
    }

    .showcase-item {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--spacing-2xl);
      align-items: center;
      background: var(--color-white);
      padding: var(--spacing-2xl);
    }

    .showcase-image {
      width: 100%;
      border-radius: var(--radius-lg);
      box-shadow: var(--shadow-lg);
      transition: transform var(--transition-normal);
    }

    .showcase-item:hover .showcase-image {
      transform: scale(1.03);
    }

    .showcase-content h3 {
      font-size: var(--text-2xl);
      font-weight: var(--font-semibold);
      margin-bottom: var(--spacing-md);
      color: var(--color-gray-900);
      position: relative;
      padding-bottom: var(--spacing-sm);
    }

    .showcase-content h3::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 50px;
      height: 3px;
      background: var(--color-primary-light);
      border-radius: var(--radius-full);
    }

    .showcase-content p {
      font-size: var(--text-lg);
      color: var(--color-gray-500);
      line-height: 1.6;
    }

    /* Testimonials Section */
    .testimonials-section {
      background: var(--color-white);
      position: relative;
    }

    .testimonials-section::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 300px;
      height: 300px;
      background: radial-gradient(circle, var(--color-primary-light) 0%, rgba(255,255,255,0) 70%);
      opacity: 0.1;
      border-radius: 100%;
      z-index: 1;
    }

    .testimonials-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: var(--spacing-xl);
      margin-top: var(--spacing-2xl);
    }

    .testimonial {
      background: var(--color-gray-50);
      padding: var(--spacing-xl);
      border-radius: var(--radius-lg);
      margin: 0;
      box-shadow: var(--shadow-md);
      border-left: 4px solid var(--color-primary);
      transition: transform var(--transition-normal), box-shadow var(--transition-normal);
      position: relative;
    }

    .testimonial::before {
      content: """;
      position: absolute;
      top: -20px;
      left: 20px;
      font-size: 100px;
      font-family: var(--font-display);
      color: var(--color-primary);
      opacity: 0.1;
      line-height: 1;
    }

    .testimonial:hover {
      transform: translateY(-5px);
      box-shadow: var(--shadow-lg);
    }

    .testimonial p {
      font-size: var(--text-lg);
      color: var(--color-gray-900);
      line-height: 1.6;
      margin-bottom: var(--spacing-md);
      position: relative;
      z-index: 2;
    }

    .testimonial cite {
      color: var(--color-gray-500);
      font-style: normal;
      font-size: var(--text-base);
      display: block;
      margin-top: var(--spacing-md);
      font-weight: var(--font-medium);
    }

    /* CTA Section */
    .cta-section {
      background: linear-gradient(135deg, var(--color-gray-900) 0%, var(--color-gray-800) 100%);
      color: var(--color-white);
      text-align: center;
      border-radius: var(--radius-lg) var(--radius-lg) 0 0;
      position: relative;
      overflow: hidden;
    }

    .cta-section::before {
      content: '';
      position: absolute;
      top: -50%;
      left: -50%;
      width: 200%;
      height: 200%;
      background: radial-gradient(ellipse at center, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%);
      transform: rotate(30deg);
      pointer-events: none;
    }

    .cta-section h2 {
      color: var(--color-white);
    }

    .cta-section h2::after {
      background: linear-gradient(90deg, var(--color-white), var(--color-gray-300));
    }

    .cta-section p {
      font-size: var(--text-xl);
      color: var(--color-gray-300);
      margin-bottom: var(--spacing-2xl);
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }

    .cta-buttons {
      display: flex;
      gap: var(--spacing-md);
      justify-content: center;
    }

    .btn-primary {
      background: var(--color-white);
      color: var(--color-gray-900);
      padding: var(--spacing-md) var(--spacing-2xl);
      border-radius: var(--radius-full);
      text-decoration: none;
      font-weight: var(--font-medium);
      transition: all var(--transition-normal);
      position: relative;
      overflow: hidden;
      display: inline-block;
      z-index: 1;
    }

    .btn-primary::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(255,255,255,0.1), rgba(255,255,255,0.4), rgba(255,255,255,0.1));
      transition: all 0.6s ease;
      z-index: -1;
    }

    .btn-primary:hover {
      transform: translateY(-2px);
      box-shadow: var(--shadow-lg);
    }

    .btn-primary:hover::before {
      left: 100%;
    }

    .btn-secondary {
      background: transparent;
      color: var(--color-white);
      padding: var(--spacing-md) var(--spacing-2xl);
      border-radius: var(--radius-full);
      text-decoration: none;
      font-weight: var(--font-medium);
      border: 2px solid var(--color-gray-500);
      transition: all var(--transition-normal);
    }

    .btn-secondary:hover {
      background: var(--color-gray-800);
      border-color: var(--color-white);
      transform: translateY(-2px);
    }

    @keyframes fadeInUp {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    @media (max-width: 768px) {
      section {
        padding: var(--spacing-2xl) var(--spacing-md);
      }

      .showcase-section {
        padding-top: var(--spacing-4xl);
        margin-top: var(--spacing-xl);
      }

      .showcase-item {
        grid-template-columns: 1fr;
        padding: var(--spacing-lg);
      }

      .showcase-content {
        padding-top: var(--spacing-md);
      }

      .cta-buttons {
        flex-direction: column;
        padding: 0 var(--spacing-xl);
        gap: var(--spacing-md);
      }

      h2 {
        font-size: var(--text-3xl);
      }

      .testimonials-grid {
        grid-template-columns: 1fr;
      }
    }

    @media (min-width: 769px) and (max-width: 1024px) {
      .showcase-item {
        grid-template-columns: 1fr 1fr;
        gap: var(--spacing-xl);
      }

      .showcase-section {
        padding-top: var(--spacing-5xl);
      }
    }

    /* Card Showcase Styles */
    .card-showcase {
      height: 300px;
      position: relative;
      width: 100%;
      animation: fadeIn 0.8s ease-out;
    }
    
    .card-mock {
      position: absolute;
      border-radius: var(--radius-md);
      background: white;
      box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.15), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
      transition: all 0.4s ease;
      overflow: hidden;
      border: 1px solid rgba(var(--color-primary-rgb), 0.1);
    }
    
    .card-stripe {
      height: 6px;
      width: 100%;
      background: linear-gradient(90deg, rgba(79, 70, 229, 0.9) 0%, rgba(124, 58, 237, 0.9) 100%);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
    
    .card-stripe-alt-1 {
      background: linear-gradient(90deg, rgba(225, 29, 72, 0.9) 0%, rgba(190, 18, 60, 0.9) 100%);
    }
    
    .card-stripe-alt-2 {
      background: linear-gradient(90deg, rgba(59, 130, 246, 0.9) 0%, rgba(37, 99, 235, 0.9) 100%);
    }
    
    .card-stripe-alt-3 {
      background: linear-gradient(90deg, rgba(16, 185, 129, 0.9) 0%, rgba(5, 150, 105, 0.9) 100%);
    }
    
    .card-content-preview {
      width: 100%;
      height: 100%;
      padding: 12px;
      padding-top: 18px; /* Additional space to account for the stripe */
    }
    
    .card-preview-header {
      height: 32px;
      background: rgba(var(--color-primary-rgb), 0.1);
      border-radius: var(--radius-sm);
      margin-bottom: 12px;
    }
    
    .card-preview-body {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    
    .preview-line {
      height: 10px;
      background: rgba(var(--color-gray-rgb), 0.15);
      border-radius: var(--radius-sm);
    }
    
    .preview-line:nth-child(2) {
      width: 70%;
    }
    
    .card-mock-1 {
      width: 240px;
      height: 160px;
      top: 60px;
      left: 50px;
      transform: rotate(-8deg);
      z-index: 1;
      background: linear-gradient(135deg, #ffffff 0%, #f9fafb 100%);
    }
    
    .card-mock-2 {
      width: 240px;
      height: 160px;
      top: 40px;
      left: 120px;
      transform: rotate(4deg);
      z-index: 2;
      background: linear-gradient(135deg, #f9fafb 0%, #f3f4f6 100%);
    }
    
    .card-mock-3 {
      width: 240px;
      height: 160px;
      top: 90px;
      left: 80px;
      transform: rotate(-3deg);
      z-index: 3;
      background: linear-gradient(135deg, #ffffff 0%, #f5f5f5 100%);
    }
    
    .card-mock:hover {
      transform: translateY(-10px) rotate(0deg) !important;
      box-shadow: 0 25px 30px -5px rgba(0, 0, 0, 0.2), 0 15px 15px -5px rgba(0, 0, 0, 0.1);
      z-index: 10 !important;
    }
    
    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }

    /* Alternative Card Mockups */
    .card-showcase-alt {
      perspective: 1000px;
    }
    
    .card-mock-alt-1 {
      width: 240px;
      height: 160px;
      top: 70px;
      left: 40px;
      transform: rotate(6deg);
      z-index: 1;
      background: linear-gradient(135deg, #fef3f2 0%, #fee2e2 100%);
    }
    
    .card-mock-alt-2 {
      width: 240px;
      height: 160px;
      top: 30px;
      left: 100px;
      transform: rotate(-5deg);
      z-index: 2;
      background: linear-gradient(135deg, #f0f9ff 0%, #e0f2fe 100%);
    }
    
    .card-mock-alt-3 {
      width: 240px;
      height: 160px;
      top: 100px;
      left: 90px;
      transform: rotate(2deg);
      z-index: 3;
      background: linear-gradient(135deg, #f0fdf4 0%, #dcfce7 100%);
    }
    
    .card-preview-header-alt {
      background: rgba(var(--color-primary-rgb), 0.2);
    }
    
    .card-mock-alt-1 .card-preview-header {
      background: rgba(225, 29, 72, 0.1);
    }
    
    .card-mock-alt-2 .card-preview-header {
      background: rgba(59, 130, 246, 0.1);
    }
    
    .card-mock-alt-3 .card-preview-header {
      background: rgba(16, 185, 129, 0.1);
    }
    
    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }
  `;
  document.head.appendChild(style);

  const heroFindOutMoreButton = document.getElementById('hero-find-out-more');
  if (heroFindOutMoreButton) {
    heroFindOutMoreButton.addEventListener('click', () => {
      document.getElementById('features-section')?.scrollIntoView({
        behavior: 'smooth',
      });
    });
  }

  // Add clipboard copy functionality to the template cards
  const copyButtons = document.querySelectorAll('.copy-button');
  copyButtons.forEach((button) => {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      const text = button.getAttribute('data-text');
      if (text) {
        navigator.clipboard.writeText(text).then(() => {
          // Show success message
          button.textContent = 'Copied!';
          setTimeout(() => {
            button.textContent = 'Copy';
          }, 2000);
        });
      }
    });
  });

  // Add animation on scroll
  const observerOptions = {
    threshold: 0.1,
    rootMargin: '0px 0px -50px 0px',
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.style.animation = `fadeInUp var(--transition-slow)`;
        entry.target.style.opacity = '1';
        observer.unobserve(entry.target);
      }
    });
  }, observerOptions);

  // Observe sections for scroll animation
  document.querySelectorAll('section').forEach((section, index) => {
    // Skip the hero section
    if (index > 0) {
      section.style.opacity = '0';
      observer.observe(section);
    }
  });
}
