// Entry point: register components, set up router, and fetch initial data
import '../styles/global.css';

import { initRouter } from './router/router.js';
import { store } from './store/store.js';
import { getUserData } from './services/api.js';
import { initStructuredData } from './utils/seo.js';
import { setupForceRefresh } from './utils/force-refresh.js';
import { initPocketBase } from './services/PocketBaseClient.js';
import './components/CustomButton/CustomButton.js';
import './pages/HomePage.js';

// Initialize the app
async function bootstrap() {
  // Initialize router
  initRouter();

  // Initialize structured data for SEO
  initStructuredData();

  // Force refresh meta tags to prevent caching issues
  setupForceRefresh();

  // Initialize PocketBase client
  initPocketBase();

  // Initialize store with user data if available
  try {
    const userData = await getUserData();
    if (userData) {
      store.setUser(userData);
    }
  } catch (error) {
    console.error('Failed to load user data:', error);
  }
}

// Start the app
bootstrap();
