import '../components/AppLayout/AppLayout.js';

export function loadTermsPage() {
  const app = document.getElementById('app');
  if (!app) {
    return;
  }

  app.innerHTML = `
    <app-layout>
      <div class="terms-container">
        <div class="terms-header">
          <h1>Terms of Service</h1>
          <p class="subtitle">Last updated: March 2024</p>
        </div>

        <div class="terms-content">
          <section class="terms-section">
            <h2>1. Introduction</h2>
            <p>Welcome to SendAThankYouCard.com! These Terms of Service ("Terms") govern your use of our website, services, and products. By accessing or using our services, you agree to be bound by these Terms.</p>
          </section>

          <section class="terms-section">
            <h2>2. Definitions</h2>
            <p>Throughout these Terms, we use certain terms with specific meanings:</p>
            <ul>
              <li><strong>"Service"</strong> refers to the SendAThankYouCard.com website and all related services.</li>
              <li><strong>"User"</strong> refers to any individual who uses our Service.</li>
              <li><strong>"Content"</strong> refers to text, images, designs, and other materials uploaded to or created through our Service.</li>
              <li><strong>"Account"</strong> refers to a User's registration with our Service.</li>
            </ul>
          </section>

          <section class="terms-section">
            <h2>3. Account Registration and Security</h2>
            <p>To use certain features of our Service, you may need to create an Account. You agree to:</p>
            <ul>
              <li>Provide accurate, current, and complete information</li>
              <li>Maintain and update your Account information</li>
              <li>Keep your password secure and confidential</li>
              <li>Notify us immediately of any unauthorized access to your Account</li>
              <li>Be responsible for all activities that occur under your Account</li>
            </ul>
          </section>

          <section class="terms-section">
            <h2>4. User Content and Conduct</h2>
            <p>Our Service allows you to create and share personalized thank you cards. You retain ownership of your Content, but grant us certain rights to use it for providing our Service. You agree that:</p>
            <ul>
              <li>You own or have the necessary rights to the Content you upload</li>
              <li>Your Content does not violate any third-party rights</li>
              <li>You grant us a non-exclusive license to use your Content to provide and improve our Service</li>
              <li>You will not use our Service to create or share Content that is unlawful, harmful, threatening, abusive, harassing, defamatory, or otherwise objectionable</li>
            </ul>
          </section>

          <section class="terms-section">
            <h2>5. Payments and Subscriptions</h2>
            <p>Some features of our Service may require payment or a subscription. You agree that:</p>
            <ul>
              <li>You are responsible for all charges incurred under your Account</li>
              <li>You will provide accurate and complete billing information</li>
              <li>We may change our prices with reasonable notice</li>
              <li>Subscriptions automatically renew unless canceled before the renewal date</li>
              <li>Refunds are provided in accordance with our Refund Policy</li>
            </ul>
          </section>

          <section class="terms-section">
            <h2>6. Intellectual Property</h2>
            <p>All content, features, and functionality of our Service, including but not limited to design, text, graphics, logos, icons, images, audio clips, software, and the selection and arrangement thereof, are owned by SendAThankYouCard.com or our licensors and are protected by copyright, trademark, and other intellectual property laws.</p>
          </section>

          <section class="terms-section">
            <h2>7. Limitation of Liability</h2>
            <p>To the fullest extent permitted by law, SendAThankYouCard.com shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.</p>
          </section>

          <section class="terms-section">
            <h2>8. Termination</h2>
            <p>We may terminate or suspend your Account and access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms.</p>
          </section>

          <section class="terms-section">
            <h2>9. Changes to Terms</h2>
            <p>We reserve the right to modify these Terms at any time. If we make changes, we will provide notice by posting the updated Terms on our website and updating the "Last updated" date. Your continued use of our Service after any such changes constitutes your acceptance of the new Terms.</p>
          </section>

          <section class="terms-section">
            <h2>10. Contact Us</h2>
            <p>If you have any questions about these Terms, please contact us at:</p>
            <a href="mailto:legal@sendathankyoucard.com" class="contact-link">
              legal@sendathankyoucard.com
            </a>
          </section>
        </div>
      </div>
    </app-layout>
  `;

  // Add styles
  const style = document.createElement('style');
  style.textContent = `
    .terms-container {
      padding: var(--spacing-3xl) var(--spacing-md);
      max-width: var(--content-width-md);
      margin: 0 auto;
    }

    .terms-header {
      text-align: center;
      margin-bottom: var(--spacing-3xl);
    }

    .terms-header h1 {
      font-family: var(--font-display);
      font-size: var(--text-4xl);
      color: var(--color-gray-900);
      margin-bottom: var(--spacing-sm);
    }

    .subtitle {
      font-size: var(--text-lg);
      color: var(--color-gray-500);
    }

    .terms-content {
      background: var(--color-white);
      border-radius: var(--radius-lg);
      padding: var(--spacing-2xl);
      box-shadow: var(--shadow-md);
    }

    .terms-section {
      margin-bottom: var(--spacing-2xl);
    }

    .terms-section:last-child {
      margin-bottom: 0;
    }

    .terms-section h2 {
      font-family: var(--font-display);
      font-size: var(--text-2xl);
      color: var(--color-gray-900);
      margin-bottom: var(--spacing-lg);
    }

    .terms-section h3 {
      font-size: var(--text-xl);
      color: var(--color-gray-800);
      margin: var(--spacing-xl) 0 var(--spacing-md);
    }

    .terms-section p {
      color: var(--color-gray-700);
      line-height: 1.6;
      margin-bottom: var(--spacing-md);
    }

    .terms-section ul {
      list-style-type: disc;
      padding-left: var(--spacing-xl);
      margin-bottom: var(--spacing-lg);
    }

    .terms-section li {
      color: var(--color-gray-700);
      line-height: 1.6;
      margin-bottom: var(--spacing-xs);
    }

    .contact-link {
      color: var(--color-primary);
      text-decoration: none;
      font-weight: var(--font-medium);
    }

    .contact-link:hover {
      text-decoration: underline;
    }

    @media (max-width: 768px) {
      .terms-header h1 {
        font-size: var(--text-3xl);
      }

      .terms-content {
        padding: var(--spacing-xl);
      }

      .terms-section h2 {
        font-size: var(--text-xl);
      }

      .terms-section h3 {
        font-size: var(--text-lg);
      }
    }
  `;
  document.head.appendChild(style);
}
