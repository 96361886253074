import '../components/AppLayout/AppLayout.js';

export function loadAboutPage() {
  const app = document.getElementById('app');
  if (!app) {
    return;
  }

  app.innerHTML = `
    <app-layout>
      <div class="about-container">
        <div class="about-header">
          <h1>About Us</h1>
          <p class="subtitle">Making gratitude beautiful and meaningful</p>
        </div>

        <div class="about-content">
          <div class="mission-section">
            <h2>Our Mission</h2>
            <p>We believe in the power of gratitude to transform relationships and create positive change. Our mission is to make expressing thanks beautiful, meaningful, and effortless.</p>
          </div>

          <div class="values-section">
            <h2>Our Values</h2>
            <div class="values-grid">
              <div class="value-card">
                <h3>Simplicity</h3>
                <p>We believe in making things simple and intuitive, without sacrificing quality or meaning.</p>
              </div>
              <div class="value-card">
                <h3>Quality</h3>
                <p>Every template, every feature, and every interaction is crafted with attention to detail.</p>
              </div>
              <div class="value-card">
                <h3>Privacy</h3>
                <p>Your messages are personal. We ensure they stay that way with strong privacy protections.</p>
              </div>
              <div class="value-card">
                <h3>Sustainability</h3>
                <p>We're committed to environmentally responsible practices in everything we do.</p>
              </div>
            </div>
          </div>

          <div class="story-section">
            <h2>Our Story</h2>
            <p>SendAThankYouCard was born from a simple idea: gratitude should be beautiful, personal, and easy to express. We started with a small collection of card templates and a vision to make thank you notes meaningful again in our digital age.</p>
            <p>Today, we help thousands of people express their appreciation through thoughtfully designed cards that combine the convenience of digital with the personal touch of traditional stationery.</p>
          </div>
        </div>

        <div class="cta-section">
          <h2>Join Us in Spreading Gratitude</h2>
          <p>Start creating beautiful thank you cards today</p>
          <a href="#/create" class="btn-primary">Get Started</a>
        </div>
      </div>
    </app-layout>
  `;

  // Add styles
  const style = document.createElement('style');
  style.textContent = `
    .about-container {
      padding: var(--spacing-3xl) var(--spacing-md);
    }

    .about-header {
      text-align: center;
      max-width: var(--content-width-md);
      margin: 0 auto var(--spacing-3xl);
    }

    .about-header h1 {
      font-family: var(--font-display);
      font-size: var(--text-5xl);
      color: var(--color-gray-900);
      margin-bottom: var(--spacing-md);
    }

    .subtitle {
      font-size: var(--text-xl);
      color: var(--color-gray-500);
    }

    .about-content {
      max-width: var(--content-width-md);
      margin: 0 auto;
    }

    .mission-section {
      text-align: center;
      margin-bottom: var(--spacing-3xl);
    }

    .mission-section h2 {
      font-family: var(--font-display);
      font-size: var(--text-3xl);
      color: var(--color-gray-900);
      margin-bottom: var(--spacing-md);
    }

    .mission-section p {
      font-size: var(--text-lg);
      color: var(--color-gray-700);
      line-height: 1.6;
      max-width: 800px;
      margin: 0 auto;
    }

    .values-section {
      margin-bottom: var(--spacing-3xl);
    }

    .values-section h2 {
      font-family: var(--font-display);
      font-size: var(--text-3xl);
      color: var(--color-gray-900);
      text-align: center;
      margin-bottom: var(--spacing-xl);
    }

    .values-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: var(--spacing-xl);
    }

    .value-card {
      background: var(--color-white);
      padding: var(--spacing-xl);
      border-radius: var(--radius-lg);
      box-shadow: var(--shadow-md);
      transition: all var(--transition-normal);
    }

    .value-card:hover {
      transform: translateY(-4px);
      box-shadow: var(--shadow-lg);
    }

    .value-card h3 {
      font-size: var(--text-xl);
      color: var(--color-gray-900);
      margin-bottom: var(--spacing-md);
    }

    .value-card p {
      color: var(--color-gray-500);
      line-height: 1.6;
    }

    .story-section {
      margin-bottom: var(--spacing-3xl);
    }

    .story-section h2 {
      font-family: var(--font-display);
      font-size: var(--text-3xl);
      color: var(--color-gray-900);
      text-align: center;
      margin-bottom: var(--spacing-xl);
    }

    .story-section p {
      color: var(--color-gray-700);
      line-height: 1.8;
      margin-bottom: var(--spacing-lg);
    }

    .cta-section {
      text-align: center;
      background: var(--color-gray-50);
      border-radius: var(--radius-lg);
      padding: var(--spacing-2xl);
      max-width: var(--content-width-md);
      margin: var(--spacing-3xl) auto 0;
    }

    .cta-section h2 {
      font-family: var(--font-display);
      font-size: var(--text-3xl);
      color: var(--color-gray-900);
      margin-bottom: var(--spacing-sm);
    }

    .cta-section p {
      color: var(--color-gray-500);
      margin-bottom: var(--spacing-xl);
    }

    .btn-primary {
      display: inline-block;
      padding: var(--spacing-md) var(--spacing-2xl);
      background: var(--color-primary);
      color: var(--color-white);
      text-decoration: none;
      border-radius: var(--radius-full);
      font-weight: var(--font-medium);
      transition: all var(--transition-normal);
    }

    .btn-primary:hover {
      transform: translateY(-2px);
      box-shadow: var(--shadow-lg);
    }

    @media (max-width: 768px) {
      .about-header h1 {
        font-size: var(--text-4xl);
      }

      .values-grid {
        grid-template-columns: 1fr;
        padding: 0 var(--spacing-md);
      }

      .about-content {
        padding: 0 var(--spacing-md);
      }
    }
  `;
  document.head.appendChild(style);
}
