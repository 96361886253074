import '../components/AppLayout/AppLayout.js';

export function loadContactPage() {
  const app = document.getElementById('app');
  if (!app) {
    return;
  }

  app.innerHTML = `
    <app-layout>
      <div class="contact-container">
        <div class="contact-header">
          <h1>Contact Us</h1>
          <p class="subtitle">We're here to help with any questions you may have</p>
        </div>

        <div class="contact-content">
          <div class="contact-info">
            <div class="info-section">
              <h2>Email Us</h2>
              <p>For general inquiries:</p>
              <a href="mailto:hello@sendathankyoucard.com" class="contact-link">
                hello@sendathankyoucard.com
              </a>
              <p>For support:</p>
              <a href="mailto:support@sendathankyoucard.com" class="contact-link">
                support@sendathankyoucard.com
              </a>
            </div>

            <div class="info-section">
              <h2>Business Hours</h2>
              <p>Saturday & Sunday</p>
              <p>9:00 AM - 5:00 PM PST</p>
            </div>

            <div class="info-section">
              <h2>Response Time</h2>
              <p>We typically respond to all inquiries within 7 days.</p>
            </div>
          </div>

          <div class="contact-form-container">
            <h2>Send us a Message</h2>
            <form class="contact-form">
              <div class="form-group">
                <label for="name">Name</label>
                <input type="text" id="name" required placeholder="Your name">
              </div>

              <div class="form-group">
                <label for="email">Email</label>
                <input type="email" id="email" required placeholder="Your email">
              </div>

              <div class="form-group">
                <label for="subject">Subject</label>
                <select id="subject" required>
                  <option value="">Select a subject</option>
                  <option value="general">General Inquiry</option>
                  <option value="support">Technical Support</option>
                  <option value="billing">Billing Question</option>
                  <option value="feedback">Feedback</option>
                </select>
              </div>

              <div class="form-group">
                <label for="message">Message</label>
                <textarea id="message" required placeholder="How can we help?" rows="5"></textarea>
              </div>

              <button type="submit" class="btn-primary">Send Message</button>
              <p class="response-note">Note: Please expect a response within 7 days as we operate only on weekends.</p>
            </form>
          </div>
        </div>
      </div>
    </app-layout>
  `;

  // Add styles
  const style = document.createElement('style');
  style.textContent = `
    .contact-container {
      padding: var(--spacing-3xl) var(--spacing-md);
      max-width: var(--content-width-lg);
      margin: 0 auto;
    }

    .contact-header {
      text-align: center;
      margin-bottom: var(--spacing-3xl);
    }

    .contact-header h1 {
      font-family: var(--font-display);
      font-size: var(--text-5xl);
      color: var(--color-gray-900);
      margin-bottom: var(--spacing-md);
    }

    .subtitle {
      font-size: var(--text-xl);
      color: var(--color-gray-500);
    }

    .contact-content {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: var(--spacing-2xl);
    }

    .contact-info {
      background: var(--color-gray-50);
      padding: var(--spacing-xl);
      border-radius: var(--radius-lg);
    }

    .info-section {
      margin-bottom: var(--spacing-xl);
    }

    .info-section:last-child {
      margin-bottom: 0;
    }

    .info-section h2 {
      font-size: var(--text-xl);
      color: var(--color-gray-900);
      margin-bottom: var(--spacing-md);
    }

    .info-section p {
      color: var(--color-gray-500);
      margin-bottom: var(--spacing-xs);
    }

    .contact-link {
      color: var(--color-primary);
      text-decoration: none;
      display: block;
      margin-bottom: var(--spacing-md);
      font-weight: var(--font-medium);
    }

    .contact-link:hover {
      text-decoration: underline;
    }

    .contact-form-container {
      background: var(--color-white);
      padding: var(--spacing-xl);
      border-radius: var(--radius-lg);
      box-shadow: var(--shadow-md);
    }

    .contact-form-container h2 {
      font-size: var(--text-2xl);
      color: var(--color-gray-900);
      margin-bottom: var(--spacing-xl);
    }

    .contact-form {
      display: grid;
      gap: var(--spacing-lg);
    }

    .form-group {
      display: grid;
      gap: var(--spacing-xs);
    }

    .form-group label {
      color: var(--color-gray-700);
      font-weight: var(--font-medium);
    }

    .form-group input,
    .form-group select,
    .form-group textarea {
      padding: var(--spacing-md);
      border: 1px solid var(--color-gray-200);
      border-radius: var(--radius-md);
      font-size: var(--text-base);
      width: 100%;
      transition: all var(--transition-normal);
    }

    .form-group input:focus,
    .form-group select:focus,
    .form-group textarea:focus {
      outline: none;
      border-color: var(--color-primary);
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    }

    .form-group textarea {
      resize: vertical;
    }

    .btn-primary {
      padding: var(--spacing-md);
      background: var(--color-primary);
      color: var(--color-white);
      border: none;
      border-radius: var(--radius-md);
      font-weight: var(--font-medium);
      cursor: pointer;
      transition: all var(--transition-normal);
    }

    .btn-primary:hover {
      transform: translateY(-2px);
      box-shadow: var(--shadow-lg);
    }
    
    .response-note {
      margin-top: var(--spacing-md);
      color: var(--color-gray-500);
      font-size: 0.9rem;
      font-style: italic;
    }

    @media (max-width: 768px) {
      .contact-header h1 {
        font-size: var(--text-4xl);
      }

      .contact-content {
        grid-template-columns: 1fr;
      }

      .contact-form-container,
      .contact-info {
        padding: var(--spacing-lg);
      }
    }
  `;
  document.head.appendChild(style);
}
