// Basic router (hash-based or history-based)

import { loadHomePage } from '../pages/HomePage.js';
import { loadTemplatesPage } from '../pages/TemplatesPage.js';
import { loadPricingPage } from '../pages/PricingPage.js';
import { loadLoginPage } from '../pages/LoginPage.js';
import { loadSignupPage } from '../pages/SignupPage.js';
import { loadCreateCardPage } from '../pages/CreateCardPage.js';
import { loadFeaturesPage } from '../pages/FeaturesPage.js';
import { loadAboutPage } from '../pages/AboutPage.js';
import { loadBlogPage } from '../pages/BlogPage.js';
import { loadContactPage } from '../pages/ContactPage.js';
import { loadPrivacyPage } from '../pages/PrivacyPage.js';
import { loadTermsPage } from '../pages/TermsPage.js';
import { loadViewCardPage } from '../pages/ViewCardPage.js';
import { loadSettingsPage } from '../pages/SettingsPage.js';
import { loadResetPasswordPage } from '../pages/ResetPasswordPage.js';
import { loadNotFoundPage } from '../pages/NotFoundPage.js';
import { loadComingSoonPage } from '../pages/ComingSoonPage.js';
import { updateMetadata } from '../utils/seo.js';
import { isLoggedIn, getCurrentUser } from '../services/auth.js';

// Define route metadata for SEO
const routeMetadata = {
  '/': {
    title: 'Send a Thank You Card - Express Your Gratitude',
    description:
      'Create and send personalized thank you cards to express your gratitude to friends, family, colleagues, and clients.',
  },
  '/login': {
    title: 'Log In - Send a Thank You Card',
    description: 'Log in to your account to create, send, and manage your thank you cards.',
  },
  '/signup': {
    title: 'Sign Up - Send a Thank You Card',
    description: 'Create an account to start designing and sending personalized thank you cards.',
  },
  '/settings': {
    title: 'Account Settings - Send a Thank You Card',
    description: 'Manage your account settings and preferences.',
  },
  '/reset-password': {
    title: 'Reset Password - Send a Thank You Card',
    description: 'Reset your password to regain access to your account.',
  },
  '/view': {
    title: 'View Card - Send a Thank You Card',
    description: 'View a personalized thank you card sent to you.',
  },
  '/not-found': {
    title: 'Page Not Found - Send a Thank You Card',
    description: 'The page you are looking for could not be found.',
  },
};

// Define which routes are implemented
const routes = {
  '/': loadHomePage,
  '/templates': loadTemplatesPage,
  '/pricing': loadPricingPage,
  '/login': loadLoginPage,
  '/signup': loadSignupPage,
  '/create': loadCreateCardPage,
  '/features': loadFeaturesPage,
  '/about': loadAboutPage,
  '/blog': loadBlogPage,
  '/contact': loadContactPage,
  '/privacy': loadPrivacyPage,
  '/terms': loadTermsPage,
  '/view': loadViewCardPage,
  '/settings': loadSettingsPage,
  '/reset-password': loadResetPasswordPage,
  '/not-found': loadNotFoundPage,
};

// Define routes that should show "Coming Soon" instead of 404
const comingSoonRoutes = [
  '/dashboard',
  '/profile',
  '/cards',
  '/notifications',
  '/billing',
  '/support',
  '/templates/create',
  '/templates/custom',
  '/account',
  '/subscription',
  '/settings/notifications',
  '/settings/billing',
  '/settings/security',
  // Add routes from the pricing page
  '/create/free',
  '/create/premium',
  '/create/business',
];

// Keep track of the current route for navigation state
let currentRoute = '';

export function initRouter() {
  // Handle both hashchange and initial load
  window.addEventListener('hashchange', handleRouteChange);
  window.addEventListener('load', handleRouteChange);

  // Handle clicks on navigation links
  document.addEventListener('click', (e) => {
    const link = e.target.closest('a');
    if (link && link.getAttribute('href')?.startsWith('#')) {
      e.preventDefault();
      const path = link.getAttribute('href').slice(1); // Remove the # from the href
      navigateTo(path);
    }
  });
}

export function navigateTo(path) {
  window.location.hash = path;
}

// Handle route changes
export function handleRouteChange() {
  console.log('Router: handleRouteChange called');

  const hash = window.location.hash.slice(1) || '/';
  console.log('Router: Current hash:', hash);

  // Store the current route for navigation state
  currentRoute = hash;

  // Handle special debug routes
  if (hash.startsWith('/debug')) {
    console.log('Router: Debug route detected');

    // Log debug information
    console.log('Router: Current app state:', {
      url: window.location.href,
      hash,
      isLoggedIn: isLoggedIn(),
      user: getCurrentUser(),
    });
  }

  // Extract the main route without parameters
  let route = hash;
  let fullRoute = hash; // Store the full route for coming soon checks

  // Remove query parameters from the route if present
  if (route.includes('?')) {
    route = route.substring(0, route.indexOf('?'));
    fullRoute = route; // Update fullRoute too
    console.log('Router: Removed query parameters, route is now:', route);
  }

  // Handle parameterized routes (like /view/123)
  if (route.includes('/')) {
    const parts = route.split('/');

    // Check if we have a format like /view/cardId
    if (parts.length > 2 && parts[1] === 'view') {
      // This is a view route with parameters
      route = '/view';
      console.log('Router: View route detected with card ID:', parts[2]);
    }
    // Other parameterized routes - extract the main part
    else if (parts.length > 1) {
      route = `/${parts[1]}`;
      console.log('Router: Parameterized route detected:', route);
    }
  }

  console.log('Router: Looking for handler for route:', route);
  console.log('Router: Full route for coming soon check:', fullRoute);

  // Determine which page loader to use
  let pageLoader;

  // Check if this is a coming soon route (check full route first)
  if (comingSoonRoutes.includes(fullRoute)) {
    console.log('Router: Coming soon route detected:', fullRoute);
    pageLoader = loadComingSoonPage;
  }
  // Check if this is a standard route
  else if (routes[route]) {
    pageLoader = routes[route];
  }
  // Check if this is a coming soon route with the simplified path
  else if (comingSoonRoutes.includes(route)) {
    pageLoader = loadComingSoonPage;
  }
  // Default to not found
  else {
    pageLoader = loadNotFoundPage;
  }

  // Update metadata for SEO
  const title = routeMetadata[route]?.title || 'Page Not Found';
  const description =
    routeMetadata[route]?.description || 'The page you are looking for could not be found.';
  updateMetadata(title, description);

  // Update the active nav link
  updateActiveNavLink(route);

  // Scroll to top on navigation
  window.scrollTo(0, 0);

  // Load the page
  try {
    console.log('Router: Loading page handler for route:', route);
    pageLoader();
  } catch (error) {
    console.error('Router: Error loading page:', error);
    loadNotFoundPage();
  }
}

// Update the active navigation link based on the current route
function updateActiveNavLink(route) {
  console.log('Router: Updating active nav link for route:', route);

  // Find all navigation links
  const navLinks = document.querySelectorAll('a.nav-link');

  // Remove active class from all links
  navLinks.forEach((link) => {
    link.classList.remove('active');
  });

  // Add active class to the matching link
  navLinks.forEach((link) => {
    const href = link.getAttribute('href');
    if (href === `#${route}` || (route === '/' && href === '#/')) {
      link.classList.add('active');
      console.log('Router: Set active nav link:', href);
    }
  });
}
