/**
 * SEO Utility for dynamically updating page metadata
 */

const siteTitle = 'Send a Thank You Card';
const siteUrl = 'https://sendathankyoucard.com';

// Page-specific metadata
const pageMetadata = {
  '/': {
    title: `${siteTitle} - Create and Share Beautiful Gratitude`,
    description:
      'Create beautiful thank you cards online with our easy-to-use editor. Personalize and send digital or printed thank you notes for any occasion.',
    image: '/assets/images/og-image.svg',
    canonical: '/',
  },
  '/templates': {
    title: `Card Templates | ${siteTitle}`,
    description:
      'Browse our collection of beautifully designed thank you card templates. Find the perfect design for your message of gratitude.',
    image: '/assets/images/og-image.svg',
    canonical: '/templates',
  },
  '/pricing': {
    title: `Pricing Plans | ${siteTitle}`,
    description:
      'Affordable pricing plans for creating and sending thank you cards. Choose between digital delivery and premium printed options.',
    image: '/assets/images/og-image.svg',
    canonical: '/pricing',
  },
  '/create': {
    title: `Card Editor | ${siteTitle}`,
    description:
      'Create your custom thank you card with our intuitive editor. Add personal touches, photos, and heartfelt messages of gratitude.',
    image: '/assets/images/editor-og.jpg',
    canonical: '/create',
  },
  '/features': {
    title: `Features & Benefits | ${siteTitle}`,
    description:
      'Discover all the features that make creating thank you cards easy and delightful. From templates to customization options.',
    image: '/assets/images/features-og.jpg',
    canonical: '/features',
  },
  '/about': {
    title: `About Us | ${siteTitle}`,
    description:
      'Learn about our mission to help people express gratitude through beautiful, personalized thank you cards.',
    image: '/assets/images/about-og.jpg',
    canonical: '/about',
  },
  '/blog': {
    title: `Gratitude Blog | ${siteTitle}`,
    description:
      'Read articles about gratitude, thank you card etiquette, and the power of expressing appreciation.',
    image: '/assets/images/blog-og.jpg',
    canonical: '/blog',
  },
  '/contact': {
    title: `Contact Us | ${siteTitle}`,
    description:
      "Questions or feedback? Get in touch with our team. We're here to help with all your thank you card needs.",
    image: '/assets/images/contact-og.jpg',
    canonical: '/contact',
  },
  '/privacy': {
    title: `Privacy Policy | ${siteTitle}`,
    description:
      'Our privacy policy details how we protect your information when you use our thank you card services.',
    image: '/assets/images/privacy-og.jpg',
    canonical: '/privacy',
  },
  '/terms': {
    title: `Terms of Service | ${siteTitle}`,
    description:
      'Our terms of service outline the rules, guidelines, and agreements for using our thank you card platform.',
    image: '/assets/images/privacy-og.jpg',
    canonical: '/terms',
  },
  '/login': {
    title: `Login | ${siteTitle}`,
    description:
      'Log in to your account to create, manage, and send your personalized thank you cards.',
    image: '/assets/images/login-og.jpg',
    canonical: '/login',
  },
};

/**
 * Updates the document metadata based on the current route
 * @param {string} title - The page title
 * @param {string} description - The page description
 * @param {string} path - Optional: The current route path for canonical URL
 */
export function updateMetadata(title, description, path = null) {
  // If we only received one parameter, assume it's the path (for backward compatibility)
  if (arguments.length === 1 && typeof title === 'string') {
    const pathValue = title;

    // Default to home page metadata if path not found
    const metadata = pageMetadata[pathValue] || pageMetadata['/'];

    // Set values from metadata
    title = metadata.title;
    description = metadata.description;
    path = metadata.canonical;
  } else {
    // Make sure we have a path for canonical URL
    path = path || '/';
  }

  // Ensure the title has the site name if not already included
  if (!title.includes(siteTitle)) {
    title = `${title} | ${siteTitle}`;
  }

  // Update document title
  document.title = title;

  // Update meta tags
  const metaTags = {
    description,
    'og:title': title,
    'og:description': description,
    'og:url': `${siteUrl}/#${path}`,
    'og:image': `/assets/images/og-image.svg`,
    'twitter:title': title,
    'twitter:description': description,
    'twitter:url': `${siteUrl}/#${path}`,
    'twitter:image': `/assets/images/og-image.svg`,
  };

  // Update each meta tag
  Object.entries(metaTags).forEach(([name, content]) => {
    // Handle both name and property attributes
    const selector = `meta[name="${name}"], meta[property="${name}"]`;
    const metaElement = document.querySelector(selector);

    if (metaElement) {
      metaElement.setAttribute(metaElement.hasAttribute('name') ? 'content' : 'content', content);
    }
  });

  // Update canonical link
  const canonicalLink = document.querySelector('link[rel="canonical"]');
  if (canonicalLink) {
    canonicalLink.setAttribute('href', `${siteUrl}/#${path}`);
  }
}

/**
 * Initialize structured data (JSON-LD) for better SEO
 */
export function initStructuredData() {
  const scriptElement = document.createElement('script');
  scriptElement.type = 'application/ld+json';

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebApplication',
    name: 'Send a Thank You Card',
    url: 'https://sendathankyoucard.com',
    description: 'Create beautiful thank you cards online with our easy-to-use editor',
    applicationCategory: 'DesignApplication',
    operatingSystem: 'All',
    offers: {
      '@type': 'Offer',
      price: '0',
      priceCurrency: 'USD',
    },
    creator: {
      '@type': 'Organization',
      name: 'Send a Thank You Card',
      url: 'https://sendathankyoucard.com',
    },
  };

  scriptElement.textContent = JSON.stringify(structuredData);
  document.head.appendChild(scriptElement);
}

/**
 * Updates metadata for a specific card to enhance social sharing previews
 * @param {Object} card - The card data object containing information about the card
 * @param {string} cardId - The unique ID of the card
 * @param {string} cardImageUrl - Optional URL to an image of the card for sharing preview
 */
export function updateCardMetadata(card, cardId, cardImageUrl = null) {
  if (!card) {
    console.error('updateCardMetadata: No card data provided');
    return;
  }

  console.log('SEO: Updating card metadata for social sharing', { cardId });

  // Use the card's title or a default message
  const cardTitle = card.title || 'Thank You Card';

  // Create a dynamic title
  const title = `${cardTitle} | ${siteTitle}`;

  // Create a dynamic description
  const description = card.message
    ? `"${card.message.substring(0, 100)}${card.message.length > 100 ? '...' : ''}" - View this thank you card sent with love.`
    : 'Someone sent you a personalized thank you card. Click to view their message of gratitude.';

  // Use the provided card image URL or generate a dynamic card preview
  let imageUrl = cardImageUrl;

  // If no image is provided, generate a dynamic card preview
  if (!imageUrl) {
    // If the site has a dynamic image generation service, use it
    if (typeof window !== 'undefined' && window.location.hostname) {
      const templateId = card.template || 'default';

      // More robust sender name extraction
      let senderName = 'Someone';
      if (card.sender) {
        senderName = card.sender;
      } else if (card.from) {
        senderName = card.from;
      } else if (card.senderName) {
        senderName = card.senderName;
      } else if (card.author) {
        senderName = card.author;
      }

      // Encode parameters for URL
      const encodedSender = encodeURIComponent(senderName);
      const fontColor = encodeURIComponent(card.fontColor || '000000');
      const bgColor = encodeURIComponent(card.backgroundColor || 'ffffff');

      try {
        // Create a dynamic preview image using the card-preview API
        const previewUrl = `/api/card-preview/${cardId}?template=${templateId}&sender=${encodedSender}&color=${fontColor}&bg=${bgColor}`;

        // Test if the URL is reachable (in a real environment)
        const isProduction =
          window.location.hostname === 'sendathankyoucard.com' ||
          window.location.hostname.includes('netlify.app');

        if (isProduction) {
          imageUrl = previewUrl;
          console.log('SEO: Using dynamic preview image URL in production:', imageUrl);
          console.log('SEO: Using sender name:', senderName);
        } else {
          // In development, fallback to the default image since the API might not be available
          imageUrl = `/assets/images/og-image.svg`;
          console.log('SEO: Using default image in development environment');
        }
      } catch (error) {
        console.error('SEO: Error generating dynamic preview URL:', error);
        imageUrl = `/assets/images/og-image.svg`;
      }
    } else {
      // Fallback to the default OpenGraph image
      imageUrl = `/assets/images/og-image.svg`;
    }
  } else if (imageUrl.startsWith('http')) {
    // If the image URL is absolute, keep it as is
    console.log('SEO: Using provided absolute image URL:', imageUrl);
  } else if (!imageUrl.startsWith('/')) {
    // If the image URL is relative but doesn't start with /, add /
    imageUrl = `/${imageUrl}`;
    console.log('SEO: Converted relative URL to root-relative:', imageUrl);
  }

  // Build the canonical URL for this specific card
  const cardPath = `/view/${cardId}`;
  const canonicalUrl = `${siteUrl}/#${cardPath}`;

  // Update document title
  document.title = title;

  // Update meta tags
  const metaTags = {
    description,
    'og:title': title,
    'og:description': description,
    'og:url': canonicalUrl,
    'og:image': imageUrl,
    'og:type': 'article',
    'twitter:card': 'summary_large_image',
    'twitter:title': title,
    'twitter:description': description,
    'twitter:url': canonicalUrl,
    'twitter:image': imageUrl,
  };

  // Update each meta tag
  Object.entries(metaTags).forEach(([name, content]) => {
    // Handle both name and property attributes
    const selector = `meta[name="${name}"], meta[property="${name}"]`;
    const metaElement = document.querySelector(selector);

    if (metaElement) {
      // Fix: always use 'content' attribute regardless of whether it's a name or property meta tag
      metaElement.setAttribute('content', content);
    } else {
      // If the meta tag doesn't exist, create it
      const newMeta = document.createElement('meta');
      // Fix: Use 'name' attribute for Twitter cards instead of 'property'
      if (name.startsWith('twitter:')) {
        newMeta.setAttribute('name', name);
      } else if (name.startsWith('og:')) {
        newMeta.setAttribute('property', name);
      } else {
        newMeta.setAttribute('name', name);
      }
      newMeta.setAttribute('content', content);
      document.head.appendChild(newMeta);
      console.log(`SEO: Created new meta tag: ${name}=${content}`);
    }
  });

  // Update canonical link
  const canonicalLink = document.querySelector('link[rel="canonical"]');
  if (canonicalLink) {
    canonicalLink.setAttribute('href', canonicalUrl);
  }
}
