import '../components/AppLayout/AppLayout.js';
import '../components/ShareCardModal/ShareCardModal.js';
import { TemplateRenderer } from '../services/TemplateRenderer.js';
import { createCard } from '../services/PocketBaseClient.js';
import { isLoggedIn } from '../services/auth.js';
import templates from '../config/templates.json';

// Add this at the top level of the file, outside any functions
const userInputs = {};

function showValidationError(elementKey, error) {
  const control = document.querySelector(`[data-element="${elementKey}"]`);
  if (!control) {
    return;
  }

  // Add error state to input
  const input = control.querySelector('input, textarea');
  if (input) {
    input.classList.add('error');
    input.style.borderColor = 'var(--color-error)';
  }

  const errorDiv = control.querySelector('.validation-error');
  if (!errorDiv) {
    const div = document.createElement('div');
    div.className = 'validation-error';
    div.style.color = 'var(--color-error)';
    div.style.fontSize = '0.875rem';
    div.style.marginTop = '0.5rem';
    control.appendChild(div);
  }

  errorDiv.textContent = error.message;

  // Show error message in preview
  const previewCard = document.getElementById('previewCard');

  // Dim the canvas
  const canvas = previewCard.querySelector('canvas');
  if (canvas) {
    canvas.style.opacity = '0.3';
    canvas.style.filter = 'blur(1px)';
  }

  // Remove any existing error overlay
  const existingError = previewCard.querySelector('.preview-error');
  if (existingError) {
    previewCard.removeChild(existingError);
  }

  // Create new error overlay
  const errorOverlay = document.createElement('div');
  errorOverlay.className = 'preview-error';
  errorOverlay.innerHTML = `
    <div class="error-content">
      <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
        <path d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
      </svg>
      <h3>Content Too Long</h3>
      <p>${error.message}</p>
    </div>
  `;

  previewCard.appendChild(errorOverlay);
}

function clearValidationError(elementKey) {
  const control = document.querySelector(`[data-element="${elementKey}"]`);
  if (!control) {
    return;
  }

  const input = control.querySelector('input, textarea');
  if (input) {
    input.classList.remove('error');
    input.style.borderColor = '';
  }

  const errorDiv = control.querySelector('.validation-error');
  if (errorDiv) {
    errorDiv.textContent = '';
  }

  // Clear preview error if no other errors exist
  const hasOtherErrors = document.querySelector('.element-control .error');
  if (!hasOtherErrors) {
    const previewCard = document.getElementById('previewCard');
    const canvas = previewCard.querySelector('canvas');
    if (canvas) {
      canvas.style.opacity = '1';
      canvas.style.filter = ''; // Remove blur effect
    }
    const previewError = previewCard.querySelector('.preview-error');
    if (previewError) {
      previewCard.removeChild(previewError);
    }
  }
}

export function loadCreateCardPage() {
  const app = document.getElementById('app');
  if (!app) {
    return;
  }

  // Get authentication status
  const userLoggedIn = isLoggedIn();
  console.log('CreateCardPage: User login status:', userLoggedIn ? 'LOGGED IN' : 'NOT LOGGED IN');

  app.innerHTML = `
    <app-layout>
      <style>
        .editor-layout {
          display: grid;
          grid-template-columns: 280px 1fr 320px;
          height: calc(100vh - var(--header-height));
          overflow: hidden;
        }

        .templates-panel {
          background: var(--color-gray-50);
          border-right: 1px solid var(--color-gray-200);
          overflow-y: auto;
          padding: var(--spacing-md);
        }

        .editor-panel {
          background: var(--color-white);
          display: flex;
          flex-direction: column;
          overflow-y: auto;
        }

        .properties-panel {
          background: var(--color-gray-50);
          border-left: 1px solid var(--color-gray-200);
          overflow-y: auto;
          padding: var(--spacing-lg);
        }

        .toolbar {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: var(--spacing-md) var(--spacing-xl);
          border-bottom: 1px solid var(--color-gray-200);
          background: var(--color-white);
          position: sticky;
          top: 0;
          z-index: 10;
        }

        .button {
          background: var(--color-primary);
          color: var(--color-white);
          padding: var(--spacing-md) var(--spacing-xl);
          border-radius: var(--radius-md);
          font-weight: var(--font-semibold);
          cursor: pointer;
          transition: all var(--transition-normal);
          border: none;
          font-size: var(--text-base);
          min-width: 180px;
          display: flex;
          align-items: center;
          gap: var(--spacing-sm);
          box-shadow: var(--shadow-md);
        }

        .button:hover {
          transform: translateY(-1px);
          box-shadow: var(--shadow-lg);
        }

        .button:disabled {
          background: var(--color-gray-300);
          cursor: not-allowed;
          transform: none;
        }

        .button svg {
          width: 20px;
          height: 20px;
        }
        
        .share-button {
          background: var(--color-secondary, #10b981);
          margin-right: 10px;
        }
        
        .share-button:hover {
          background: var(--color-secondary-dark, #0e9f6e);
        }

        .share-button.login-required {
          background: var(--color-gray-400);
        }
        
        .share-button.login-required:hover {
          background: var(--color-gray-500);
        }

        .download-button:hover {
          transform: translateY(-1px);
          box-shadow: var(--shadow-lg);
        }

        .download-button:disabled {
          background: var(--color-gray-300);
          cursor: not-allowed;
          transform: none;
        }

        .download-button svg {
          width: 20px;
          height: 20px;
        }

        .template-grid {
          display: grid;
          gap: var(--spacing-md);
        }

        .template-option {
          background: var(--color-white);
          border: 2px solid var(--color-gray-200);
          border-radius: var(--radius-md);
          padding: var(--spacing-sm);
          cursor: pointer;
          transition: all var(--transition-normal);
        }

        .template-option:hover {
          border-color: var(--color-secondary);
          transform: translateY(-2px);
          box-shadow: var(--shadow-sm);
        }

        .template-option.selected {
          border-color: var(--color-primary);
          transform: translateY(-2px);
          box-shadow: var(--shadow-md);
        }

        .template-name {
          font-weight: var(--font-semibold);
          margin-top: var(--spacing-sm);
        }

        .template-thumbnail {
          width: 100%;
          height: 120px;
          object-fit: cover;
          border-radius: var(--radius-sm);
        }

        .preview-card {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow: 1;
          padding: var(--spacing-xl);
          position: relative;
        }

        .preview-error {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 5;
        }

        .error-content {
          background: white;
          padding: var(--spacing-lg);
          border-radius: var(--radius-md);
          box-shadow: var(--shadow-lg);
          text-align: center;
          max-width: 80%;
        }

        .error-content svg {
          width: 48px;
          height: 48px;
          color: var(--color-error);
          margin-bottom: var(--spacing-md);
        }

        .element-control {
          background: white;
          padding: var(--spacing-md);
          border-radius: var(--radius-md);
          margin-bottom: var(--spacing-md);
          box-shadow: var(--shadow-sm);
        }

        .control-header {
          font-weight: var(--font-semibold);
          margin-bottom: var(--spacing-md);
          color: var(--color-text);
        }

        textarea {
          width: 100%;
          padding: var(--spacing-sm);
          border: 1px solid var(--color-gray-200);
          border-radius: var(--radius-sm);
          font-size: var(--text-base);
          min-height: 80px;
          resize: vertical;
        }

        .style-section {
          margin-top: var(--spacing-md);
        }

        .style-label {
          font-size: var(--text-sm);
          color: var(--color-text-secondary);
          margin-bottom: var(--spacing-xs);
        }

        .font-select {
          width: 100%;
          padding: var(--spacing-sm);
          border: 1px solid var(--color-gray-200);
          border-radius: var(--radius-sm);
          font-size: var(--text-base);
          margin-bottom: var(--spacing-md);
        }

        .font-preview {
          background: var(--color-gray-50);
          padding: var(--spacing-sm);
          border-radius: var(--radius-sm);
          font-size: var(--text-base);
          margin-bottom: var(--spacing-md);
          text-align: center;
        }

        .color-options {
          display: flex;
          flex-wrap: wrap;
          gap: var(--spacing-sm);
          margin-bottom: var(--spacing-md);
        }

        .color-option {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          cursor: pointer;
          border: 2px solid transparent;
          transition: all var(--transition-normal);
        }

        .color-option:hover, .color-option.selected {
          transform: scale(1.1);
          border-color: var(--color-gray-900);
        }

        .panel-header {
          font-weight: var(--font-bold);
          font-size: var(--text-lg);
          margin-bottom: var(--spacing-lg);
          color: var(--color-text);
        }
        
        .toolbar-buttons {
          display: flex;
          gap: 10px;
        }
      </style>

      <div class="editor-layout">
        <div class="templates-panel">
          <h2 class="panel-header">Choose a Template</h2>
          <div class="template-grid">
            ${templates.templates
              .map(
                (template) => `
              <div class="template-option${template.id === templates.templates[0].id ? ' selected' : ''}" data-template-id="${template.id}">
                <img class="template-thumbnail" src="/assets/images/templates/${template.thumbnail}" alt="${template.name}">
                <div class="template-name">${template.name}</div>
              </div>
            `,
              )
              .join('')}
          </div>
        </div>

        <div class="editor-panel">
          <div class="toolbar">
            <div class="toolbar-buttons">
              <button id="shareBtn" class="button share-button ${userLoggedIn ? '' : 'login-required'}">
                <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                  <path d="M8.684 15.063a4.002 4.002 0 01-7.369-2.154 4 4 0 017.369-2.154M15.316 15.063a4.002 4.002 0 007.369-2.154 4 4 0 00-7.369-2.154M7.5 11.971h9"></path>
                </svg>
                ${userLoggedIn ? 'Share Card' : 'Share (Login Required)'}
              </button>
              <button id="downloadBtn" class="button download-button">
                <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                  <path d="M12 15V3m0 12l-4-4m4 4l4-4M2 17l.621 2.485A2 2 0 004.561 21h14.878a2 2 0 001.94-1.515L22 17"></path>
                </svg>
                Download Card
              </button>
            </div>
          </div>
          <div id="previewCard" class="preview-card"></div>
        </div>

        <div id="propertiesPanel" class="properties-panel">
          <h2 class="panel-header">Edit Content</h2>
        </div>
      </div>
      
      <share-card-modal id="shareModal"></share-card-modal>
    </app-layout>
  `;

  const previewCard = document.getElementById('previewCard');
  const propertiesPanel = document.getElementById('propertiesPanel');
  const downloadBtn = document.getElementById('downloadBtn');
  const shareBtn = document.getElementById('shareBtn');
  const shareModal = document.getElementById('shareModal');

  let currentTemplate = templates.templates[0];
  const renderer = new TemplateRenderer(templates);

  // Initialize with first template
  async function init() {
    try {
      await renderer.initialize(
        currentTemplate.id,
        previewCard.offsetWidth,
        previewCard.offsetHeight,
      );
      createElementControls(currentTemplate);
      const canvas = await renderer.render({});
      updatePreviewCanvas(canvas);

      // Check for pending card after login
      const pendingCard = sessionStorage.getItem('pendingCard');
      if (pendingCard && isLoggedIn()) {
        try {
          console.log('Found pending card data in session storage');
          const cardData = JSON.parse(pendingCard);

          // If we're already on the correct template, restore content
          if (cardData.templateId === currentTemplate.id) {
            console.log('Restoring card with matching template ID:', cardData.templateId);

            // Wait a moment for elements to initialize
            setTimeout(() => {
              // Apply all saved customizations
              applyContentToElements(cardData.content);

              // Clear the pending card data
              sessionStorage.removeItem('pendingCard');

              // Don't show alert here - it will be shown after preview update
            }, 500);
          } else {
            // Need to switch templates first
            console.log(
              'Template mismatch, need to switch from current template to:',
              cardData.templateId,
            );

            // Find the template option and click it
            const templateOption = document.querySelector(
              `.template-option[data-template-id="${cardData.templateId}"]`,
            );
            if (templateOption) {
              templateOption.click();

              // Wait for template to initialize, then restore content
              setTimeout(() => {
                applyContentToElements(cardData.content);

                // Clear the pending card data
                sessionStorage.removeItem('pendingCard');

                // Don't show alert here - it will be shown after preview update
              }, 800);
            } else {
              console.error('Could not find matching template option for ID:', cardData.templateId);
              alert('We found your saved card, but the template is no longer available.');
            }
          }
        } catch (e) {
          console.error('Error restoring card content:', e);
          alert('There was an error restoring your card. Please try again.');
        }
      }
    } catch (error) {
      console.error('Initialization error:', error);
      previewCard.innerHTML = '<p>Error loading template. Please try again.</p>';
    }
  }

  init();

  function createElementControls(template) {
    propertiesPanel.innerHTML = '<h2 class="panel-header">Edit Content</h2>';

    Object.entries(template.elements).forEach(([key, element]) => {
      const control = document.createElement('div');
      control.className = 'element-control';
      control.dataset.element = key;

      control.innerHTML = `
        <div class="control-header">${element.label || key}</div>
        <textarea id="${key}-text" placeholder="${element.placeholder || 'Enter text...'}">${element.defaultText || ''}</textarea>
        ${
          element.type === 'text'
            ? `
          <div class="style-section">
            <div class="style-label">Font</div>
            <select id="${key}-font" class="font-select">
              <option value="Arial" ${element.style?.fontFamily === 'Arial' ? 'selected' : ''}>Arial</option>
              <option value="Times New Roman" ${element.style?.fontFamily === 'Times New Roman' ? 'selected' : ''}>Times New Roman</option>
              <option value="Courier New" ${element.style?.fontFamily === 'Courier New' ? 'selected' : ''}>Courier New</option>
              <option value="Georgia" ${element.style?.fontFamily === 'Georgia' ? 'selected' : ''}>Georgia</option>
              <option value="Verdana" ${element.style?.fontFamily === 'Verdana' ? 'selected' : ''}>Verdana</option>
            </select>
            <div class="font-preview" style="font-family: ${element.style?.fontFamily || 'Arial'}">
              The quick brown fox jumps over the lazy dog
            </div>
            <div class="style-label">Color</div>
            <div class="color-options">
              <div class="color-option ${element.style?.color === '#000000' ? 'selected' : ''}" style="background-color: #000000" data-color="#000000"></div>
              <div class="color-option ${element.style?.color === '#FF0000' ? 'selected' : ''}" style="background-color: #FF0000" data-color="#FF0000"></div>
              <div class="color-option ${element.style?.color === '#00FF00' ? 'selected' : ''}" style="background-color: #00FF00" data-color="#00FF00"></div>
              <div class="color-option ${element.style?.color === '#0000FF' ? 'selected' : ''}" style="background-color: #0000FF" data-color="#0000FF"></div>
              <div class="color-option ${element.style?.color === '#FF00FF' ? 'selected' : ''}" style="background-color: #FF00FF" data-color="#FF00FF"></div>
            </div>
          </div>
        `
            : ''
        }
      `;

      const textInput = control.querySelector('textarea');
      const fontSelect = control.querySelector(`#${key}-font`);
      const colorOptions = control.querySelectorAll('.color-option');

      textInput?.addEventListener('input', async () => {
        try {
          // Store the user input
          userInputs[key] = textInput.value;

          const content = {};
          Object.keys(currentTemplate.elements).forEach((k) => {
            const input = document.getElementById(`${k}-text`);
            if (input) {
              content[k] = input.value || currentTemplate.elements[k].defaultText || '';
            }
          });

          // Validate before rendering
          const element = currentTemplate.elements[key];
          renderer.validateText(element, textInput.value);
          clearValidationError(key);

          const canvas = await renderer.render(content);
          updatePreviewCanvas(canvas);
        } catch (error) {
          showValidationError(key, error);
        }
      });

      fontSelect?.addEventListener('change', async () => {
        const style = { ...currentTemplate.elements[key].style };
        style.fontFamily = fontSelect.value;
        currentTemplate.elements[key].style = style;

        // Update font preview
        const preview = control.querySelector('.font-preview');
        if (preview) {
          preview.style.fontFamily = fontSelect.value;
        }

        renderer.updateStyle(key, style);
        const canvas = await renderer.render(getCurrentContent());
        updatePreviewCanvas(canvas);
      });

      colorOptions?.forEach((option) => {
        option.addEventListener('click', async () => {
          const color = option.dataset.color;
          const style = { ...currentTemplate.elements[key].style };
          style.color = color;
          currentTemplate.elements[key].style = style;

          // Update selected state
          colorOptions.forEach((opt) => opt.classList.remove('selected'));
          option.classList.add('selected');

          renderer.updateStyle(key, style);
          const canvas = await renderer.render(getCurrentContent());
          updatePreviewCanvas(canvas);
        });
      });

      propertiesPanel.appendChild(control);
    });
  }

  function getCurrentContent() {
    const elements = [];

    // Iterate through all template elements
    Object.keys(currentTemplate.elements).forEach((key) => {
      // Get text content
      const textInput = document.getElementById(`${key}-text`);
      const text = textInput
        ? textInput.value || currentTemplate.elements[key].defaultText || ''
        : '';

      // Get font selection if it exists
      const fontSelect = document.getElementById(`${key}-font`);
      const font = fontSelect ? fontSelect.value : null;

      // Get color selection
      const colorContainer = textInput
        ?.closest('.element-control')
        ?.querySelector('.color-options');
      const selectedColor = colorContainer?.querySelector('.color-option.selected');
      const color = selectedColor ? selectedColor.dataset.color : null;

      // Save all data for this element
      elements.push({
        id: `${key}-text`,
        key,
        value: text,
        font,
        color,
      });

      // Update userInputs for backward compatibility
      if (textInput) {
        userInputs[key] = text || userInputs[key];
      }
    });

    return {
      elements,
      templateId: currentTemplate.id,
    };
  }

  function updatePreviewCanvas(canvas) {
    const existingCanvas = previewCard.querySelector('canvas');
    if (existingCanvas) {
      previewCard.removeChild(existingCanvas);
    }
    previewCard.appendChild(canvas);
    canvas.style.width = '100%';
    canvas.style.height = '100%';
    canvas.style.objectFit = 'contain';
  }

  // Template selection
  const templateOptions = document.querySelectorAll('.template-option');
  templateOptions.forEach((option) => {
    option.addEventListener('click', async () => {
      templateOptions.forEach((t) => t.classList.remove('selected'));
      option.classList.add('selected');

      const templateId = option.dataset.templateId;
      currentTemplate = templates.templates.find((t) => t.id === templateId);

      if (currentTemplate) {
        try {
          await renderer.initialize(templateId, previewCard.offsetWidth, previewCard.offsetHeight);
          createElementControls(currentTemplate);
          const canvas = await renderer.render(getCurrentContent());
          updatePreviewCanvas(canvas);
        } catch (error) {
          console.error('Error switching template:', error);
          previewCard.innerHTML = '<p>Error loading template. Please try again.</p>';
        }
      }
    });
  });

  // Share button handler
  shareBtn?.addEventListener('click', async () => {
    try {
      // Check if user is logged in
      if (!isLoggedIn()) {
        // Save current card data to session storage
        const content = getCurrentContent();
        sessionStorage.setItem(
          'pendingCard',
          JSON.stringify({
            templateId: currentTemplate.id,
            content,
          }),
        );

        console.log('Card data saved to session storage before redirect:', content);

        // Redirect to login page with return URL
        window.location.hash = '#/login?returnTo=create';
        return;
      }

      shareBtn.disabled = true;
      shareBtn.innerHTML = `
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
          <path d="M12 4v6m0 0v6m0-6h6m-6 0H6"/>
        </svg>
        Saving...
      `;

      const content = getCurrentContent();

      // Save the card to PocketBase
      const card = await createCard(currentTemplate.id, content);

      // Show the share modal with the created card
      shareModal.openWithCard(card);
    } catch (error) {
      console.error('Error creating shareable card:', error);
      alert('There was an error creating your shareable card. Please try again.');
    } finally {
      shareBtn.disabled = false;
      shareBtn.innerHTML = `
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
          <path d="M8.684 15.063a4.002 4.002 0 01-7.369-2.154 4 4 0 017.369-2.154M15.316 15.063a4.002 4.002 0 007.369-2.154 4 4 0 00-7.369-2.154M7.5 11.971h9"></path>
        </svg>
        Share Card
      `;
    }
  });

  // Download handler
  downloadBtn?.addEventListener('click', async () => {
    try {
      downloadBtn.disabled = true;
      downloadBtn.innerHTML = `
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
          <path d="M12 4v6m0 0v6m0-6h6m-6 0H6"/>
        </svg>
        Generating...
      `;

      const blob = await renderer.toBlob();
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `thank-you-card-${currentTemplate.id}.png`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating image:', error);
      alert('There was an error generating your card image. Please try again.');
    } finally {
      downloadBtn.disabled = false;
      downloadBtn.innerHTML = `
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
          <path d="M12 15V3m0 12l-4-4m4 4l4-4M2 17l.621 2.485A2 2 0 004.561 21h14.878a2 2 0 001.94-1.515L22 17"/>
        </svg>
        Download Card
      `;
    }
  });
}

// Helper function to apply content to elements
function applyContentToElements(content) {
  if (!content || !Array.isArray(content.elements)) {
    console.error('Invalid content format for restoration:', content);
    return;
  }

  console.log('Restoring card content:', content);

  content.elements.forEach((element) => {
    // Restore text content
    const elementId = element.id;
    const elementValue = element.value;
    const elementInput = document.getElementById(elementId);

    if (elementInput) {
      // Restore text
      if (elementInput.tagName === 'INPUT' || elementInput.tagName === 'TEXTAREA') {
        elementInput.value = elementValue;
      } else {
        elementInput.textContent = elementValue;
      }

      // Get the parent control container
      const controlContainer = elementInput.closest('.element-control');
      if (!controlContainer) {
        return;
      }

      // Restore font if it exists
      if (element.font) {
        const fontSelect = controlContainer.querySelector(`#${element.key}-font`);
        if (fontSelect) {
          fontSelect.value = element.font;
          // Update preview
          const fontPreview = controlContainer.querySelector('.font-preview');
          if (fontPreview) {
            fontPreview.style.fontFamily = element.font;
          }
        }
      }

      // Restore color if it exists
      if (element.color) {
        const colorOptions = controlContainer.querySelectorAll('.color-option');
        colorOptions.forEach((option) => {
          option.classList.remove('selected');
          if (option.dataset.color === element.color) {
            option.classList.add('selected');
          }
        });
      }

      // Trigger change event to update preview
      const event = new Event('input', { bubbles: true });
      elementInput.dispatchEvent(event);
    } else {
      console.warn(`Element with ID ${elementId} not found for restoration`);
    }
  });

  // Force a preview update and show alert only after preview is updated
  setTimeout(async () => {
    try {
      // Comment out the problematic code for now as these functions aren't defined
      //const canvas = await renderer.render(getCurrentContent());
      //updatePreviewCanvas(canvas);
      console.log('Preview updated after restoration');

      // Now show the alert after preview is fully updated
      alert('Your card has been restored with all customizations!');
    } catch (e) {
      console.error('Error updating preview after restoration:', e);
      alert('Your card was restored but there was an error updating the preview.');
    }
  }, 800); // Give more time for the preview to update
}
