// Reset Password page
import '../components/AppLayout/AppLayout.js';

export function loadResetPasswordPage() {
  const app = document.getElementById('app');
  if (!app) {
    return;
  }

  app.innerHTML = `
    <app-layout>
      <div class="reset-password-container">
        <div class="reset-password-card">
          <div class="card-header">
            <div class="card-icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
              </svg>
            </div>
            <h1>Reset Your Password</h1>
            <p class="site-name">SendAThankyouCard.com</p>
          </div>
          
          <div class="support-message">
            <p>
              Email <a href="mailto:support@sendathankyoucard.com">support@sendathankyoucard.com</a> from the email address 
              associated with your account and a member of our team will assist you.
            </p>
          </div>
          
          <div class="card-showcase">
            <div class="card-mock card-mock-1">
              <div class="card-stripe"></div>
              <div class="card-content-preview">
                <div class="card-preview-header"></div>
                <div class="card-preview-body">
                  <div class="preview-line"></div>
                  <div class="preview-line"></div>
                </div>
              </div>
            </div>
            <div class="card-mock card-mock-2">
              <div class="card-stripe"></div>
              <div class="card-content-preview">
                <div class="card-preview-header"></div>
                <div class="card-preview-body">
                  <div class="preview-line"></div>
                  <div class="preview-line"></div>
                </div>
              </div>
            </div>
            <div class="card-mock card-mock-3">
              <div class="card-stripe"></div>
              <div class="card-content-preview">
                <div class="card-preview-header"></div>
                <div class="card-preview-body">
                  <div class="preview-line"></div>
                  <div class="preview-line"></div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="back-to-login">
            <a href="#/login" class="back-link btn btn-primary">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
              Back to Login
            </a>
          </div>
        </div>
        
        <!-- Background decorative elements -->
        <div class="bg-circle bg-circle-1"></div>
        <div class="bg-circle bg-circle-2"></div>
        <div class="bg-circle bg-circle-3"></div>
      </div>
    </app-layout>
  `;

  // Add some page-specific styles
  const style = document.createElement('style');
  style.textContent = `
    .reset-password-container {
      min-height: calc(100vh - var(--header-height) - 100px);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--spacing-3xl) var(--spacing-md);
      position: relative;
      overflow: hidden;
    }
    
    .bg-circle {
      position: absolute;
      border-radius: 50%;
      z-index: 0;
      opacity: 0.7;
    }
    
    .bg-circle-1 {
      width: 600px;
      height: 600px;
      top: -200px;
      right: -200px;
      background: radial-gradient(circle, rgba(79, 70, 229, 0.08) 0%, rgba(79, 70, 229, 0) 70%);
      animation: float 20s ease-in-out infinite;
    }
    
    .bg-circle-2 {
      width: 400px;
      height: 400px;
      bottom: -100px;
      left: -100px;
      background: radial-gradient(circle, rgba(236, 72, 153, 0.08) 0%, rgba(236, 72, 153, 0) 70%);
      animation: float 15s ease-in-out infinite reverse;
    }
    
    .bg-circle-3 {
      width: 300px;
      height: 300px;
      top: 40%;
      left: 40%;
      background: radial-gradient(circle, rgba(16, 185, 129, 0.08) 0%, rgba(16, 185, 129, 0) 70%);
      animation: float 25s ease-in-out infinite;
    }
    
    .reset-password-card {
      background: var(--color-white);
      border-radius: var(--radius-xl);
      padding: var(--spacing-2xl);
      width: 100%;
      max-width: 500px;
      box-shadow: var(--shadow-lg);
      text-align: center;
      position: relative;
      z-index: 1;
      animation: fadeIn 0.8s ease-out;
      border: 1px solid var(--color-gray-100);
      overflow: hidden;
    }
    
    .card-header {
      margin-bottom: var(--spacing-xl);
      display: flex;
      flex-direction: column;
      align-items: center;
      animation: slideUp 0.8s ease-out;
    }
    
    .card-icon {
      width: 60px;
      height: 60px;
      background: linear-gradient(135deg, var(--color-primary-light), var(--color-primary));
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: var(--spacing-md);
      color: white;
      box-shadow: var(--shadow-md);
    }
    
    .card-icon svg {
      width: 30px;
      height: 30px;
    }
    
    .reset-password-card h1 {
      font-family: var(--font-display);
      font-size: var(--text-2xl);
      font-weight: var(--font-bold);
      color: var(--color-gray-900);
      margin-bottom: var(--spacing-xs);
      position: relative;
    }
    
    .reset-password-card h1::after {
      content: '';
      display: block;
      width: 60px;
      height: 3px;
      background: linear-gradient(90deg, var(--color-primary-light), var(--color-primary));
      margin: var(--spacing-xs) auto 0;
      border-radius: var(--radius-full);
    }
    
    .site-name {
      font-size: var(--text-md);
      font-weight: var(--font-medium);
      color: var(--color-primary);
      margin-top: 0;
      opacity: 0.9;
    }
    
    .support-message {
      background: linear-gradient(to right, rgba(var(--color-primary-rgb), 0.05), rgba(var(--color-primary-rgb), 0.1));
      border-radius: var(--radius-lg);
      padding: var(--spacing-xl);
      margin-bottom: var(--spacing-xl);
      text-align: left;
      border-left: 4px solid var(--color-primary);
      animation: fadeIn 0.8s ease-out 0.2s backwards;
    }
    
    .support-message p {
      margin: 0;
      line-height: 1.6;
      color: var(--color-gray-700);
    }
    
    .support-message a {
      color: var(--color-primary);
      font-weight: var(--font-semibold);
      text-decoration: none;
      transition: all var(--transition-normal);
      position: relative;
      display: inline-block;
    }
    
    .support-message a::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -2px;
      left: 0;
      background-color: var(--color-primary);
      transform: scaleX(0);
      transform-origin: bottom right;
      transition: transform 0.3s ease-out;
    }
    
    .support-message a:hover {
      color: var(--color-primary-dark);
    }
    
    .support-message a:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
    
    .back-to-login {
      margin-top: var(--spacing-xl);
      animation: fadeIn 0.8s ease-out 0.3s backwards;
    }
    
    .back-link {
      color: var(--color-white);
      text-decoration: none;
      font-weight: var(--font-medium);
      display: inline-flex;
      align-items: center;
      padding: var(--spacing-sm) var(--spacing-md);
      transition: all 0.3s ease;
      border-radius: var(--radius-full);
      background: linear-gradient(135deg, var(--color-primary-light), var(--color-primary));
      position: relative;
      z-index: 1;
      overflow: hidden;
    }
    
    .back-link svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      transition: transform 0.3s ease;
    }
    
    .back-link:before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent);
      transition: all 0.6s ease;
      z-index: -1;
    }
    
    .back-link:hover:before {
      left: 100%;
    }
    
    .back-link:hover {
      transform: translateY(-2px);
      box-shadow: 0 7px 14px rgba(var(--color-primary-rgb), 0.3), 0 3px 6px rgba(0, 0, 0, 0.1);
    }
    
    .back-link:hover svg {
      transform: translateX(-3px);
    }
    
    .card-showcase {
      height: 160px;
      position: relative;
      margin: var(--spacing-xl) auto;
      width: 100%;
      max-width: 300px;
      animation: fadeIn 0.8s ease-out 0.5s backwards;
    }
    
    .card-mock {
      position: absolute;
      border-radius: var(--radius-md);
      background: white;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
      transition: all 0.3s ease;
      overflow: hidden;
      border: 1px solid rgba(var(--color-primary-rgb), 0.1);
    }
    
    .card-stripe {
      height: 6px;
      width: 100%;
      background: linear-gradient(90deg, rgba(79, 70, 229, 0.9) 0%, rgba(124, 58, 237, 0.9) 100%);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
    
    .card-content-preview {
      width: 100%;
      height: 100%;
      padding: 8px;
      padding-top: 14px; /* Additional space to account for the stripe */
    }
    
    .card-preview-header {
      height: 20px;
      background: rgba(79, 70, 229, 0.1);
      border-radius: var(--radius-sm);
      margin-bottom: 8px;
    }
    
    .card-preview-body {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
    
    .preview-line {
      height: 6px;
      background: rgba(var(--color-gray-rgb), 0.2);
      border-radius: var(--radius-sm);
    }
    
    .preview-line:nth-child(2) {
      width: 70%;
    }
    
    .card-mock-1 {
      width: 160px;
      height: 100px;
      top: 10px;
      left: 30px;
      transform: rotate(-8deg);
      z-index: 1;
      background: linear-gradient(135deg, #ffffff 0%, #f9fafb 100%);
    }
    
    .card-mock-2 {
      width: 160px;
      height: 100px;
      top: 0;
      left: 100px;
      transform: rotate(4deg);
      z-index: 2;
      background: linear-gradient(135deg, #f9fafb 0%, #f3f4f6 100%);
    }
    
    .card-mock-3 {
      width: 160px;
      height: 100px;
      top: 30px;
      left: 70px;
      transform: rotate(-3deg);
      z-index: 3;
      background: linear-gradient(135deg, #ffffff 0%, #f5f5f5 100%);
    }
    
    .card-mock:hover {
      transform: translateY(-5px) rotate(0deg) !important;
      box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
      z-index: 10 !important;
    }
    
    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }
    
    @keyframes slideUp {
      from { transform: translateY(20px); opacity: 0; }
      to { transform: translateY(0); opacity: 1; }
    }
    
    @keyframes float {
      0%, 100% { transform: translate(0, 0); }
      50% { transform: translate(-20px, 20px); }
    }
    
    @media (max-width: 768px) {
      .reset-password-card {
        padding: var(--spacing-xl);
        max-width: 90%;
      }
      
      .card-icon {
        width: 50px;
        height: 50px;
      }
      
      .card-icon svg {
        width: 24px;
        height: 24px;
      }
    }
  `;
  document.head.appendChild(style);
}
