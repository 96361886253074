import '../components/AppLayout/AppLayout.js';

export function loadPricingPage() {
  const app = document.getElementById('app');
  if (!app) {
    return;
  }

  app.innerHTML = `
    <app-layout>
      <div class="pricing-container">
        <div class="pricing-header">
          <h1>Simple, Transparent Pricing</h1>
          <p>Choose the plan that works best for you. All plans include access to our beautiful templates and customization tools.</p>
          
          <!-- Background decorative elements -->
          <div class="header-bg-circle header-bg-circle-1"></div>
          <div class="header-bg-circle header-bg-circle-2"></div>
        </div>

        <div class="pricing-grid">
          <div class="pricing-card" data-plan="free">
            <div class="card-content">
              <h3>Free</h3>
              <div class="price">$0<small>/month</small></div>
              <ul class="features">
                <li><span class="feature-highlight">20</span> Digital Cards per Month</li>
                <li><span class="feature-highlight">Basic</span> Templates</li>
                <li><span class="feature-highlight">Standard</span> Customization</li>
                <li>Email Delivery</li>
                <li>Community Support</li>
              </ul>
              <div class="button-wrapper">
                <a href="#/create/free" class="cta-button">Get Started</a>
              </div>
            </div>
          </div>

          <div class="pricing-card featured" data-plan="premium">
            <div class="popular-tag">Most Popular</div>
            <div class="card-content">
              <h3>Premium</h3>
              <div class="price">$9<small>/month</small></div>
              <ul class="features">
                <li><span class="feature-highlight">Unlimited</span> Digital Cards</li>
                <li><span class="feature-highlight">4</span> Physical Cards per Month</li>
                <li><span class="feature-highlight">Premium</span> Templates</li>
                <li><span class="feature-highlight">Advanced</span> Customization</li>
                <li>Priority Support</li>
                <li>High-Quality Printing</li>
                <li>Worldwide Shipping</li>
              </ul>
              <div class="button-wrapper">
                <a href="#/create/premium" class="cta-button premium-button">Choose Premium</a>
              </div>
            </div>
          </div>

          <div class="pricing-card" data-plan="business">
            <div class="card-content">
              <h3>Business</h3>
              <div class="price">Custom</div>
              <ul class="features">
                <li><span class="feature-highlight">Unlimited</span> Digital Cards</li>
                <li><span class="feature-highlight">Custom</span> Physical Card Volume</li>
                <li>Branded Templates</li>
                <li>Team Management</li>
                <li>API Access</li>
                <li>Dedicated Support</li>
                <li>Volume Discounts</li>
              </ul>
              <div class="button-wrapper">
                <a href="#/create/business" class="cta-button">Contact Sales</a>
              </div>
            </div>
          </div>
        </div>

        <p class="pricing-note">All prices in USD. Physical cards include premium paper stock, envelopes, and shipping worldwide.</p>
      </div>

      <style>
        .pricing-container {
          max-width: 1200px;
          margin: 0 auto;
          padding: var(--spacing-4xl) var(--spacing-md);
          position: relative;
        }

        .pricing-header {
          text-align: center;
          margin-bottom: var(--spacing-4xl);
          position: relative;
          padding: var(--spacing-2xl) var(--spacing-md);
          overflow: hidden;
        }

        .header-bg-circle {
          position: absolute;
          border-radius: 50%;
          z-index: -1;
        }

        .header-bg-circle-1 {
          width: 400px;
          height: 400px;
          top: -180px;
          right: -100px;
          background: radial-gradient(circle, rgba(79, 70, 229, 0.08) 0%, rgba(79, 70, 229, 0) 70%);
        }

        .header-bg-circle-2 {
          width: 300px;
          height: 300px;
          bottom: -100px;
          left: -80px;
          background: radial-gradient(circle, rgba(16, 185, 129, 0.08) 0%, rgba(16, 185, 129, 0) 70%);
        }

        .pricing-header h1 {
          font-size: var(--text-5xl);
          font-weight: var(--font-bold);
          color: var(--color-gray-900);
          margin-bottom: var(--spacing-md);
          position: relative;
          display: inline-block;
          animation: fadeIn 0.8s ease-out, float 6s ease-in-out infinite;
        }

        .pricing-header h1::after {
          content: '';
          position: absolute;
          bottom: -10px;
          left: 50%;
          transform: translateX(-50%);
          width: 80px;
          height: 4px;
          background: linear-gradient(90deg, var(--color-primary-light), var(--color-primary));
          border-radius: var(--radius-full);
        }

        .pricing-header p {
          font-size: var(--text-xl);
          color: var(--color-gray-500);
          max-width: 600px;
          margin: 0 auto var(--spacing-md);
          line-height: 1.6;
          animation: slideUp 0.8s ease-out;
        }

        .pricing-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          gap: var(--spacing-xl);
          margin-top: var(--spacing-2xl);
          position: relative;
          z-index: 1;
        }

        .pricing-card {
          background: white;
          border-radius: var(--radius-xl);
          padding: var(--spacing-xs);
          text-align: center;
          box-shadow: var(--shadow-md);
          transition: all var(--transition-normal);
          display: flex;
          flex-direction: column;
          position: relative;
          border: 1px solid var(--color-gray-100);
          overflow: hidden;
          animation: fadeIn 0.6s ease-out backwards;
        }

        .pricing-card[data-plan="free"] {
          animation-delay: 0.1s;
        }

        .pricing-card[data-plan="premium"] {
          animation-delay: 0.2s;
        }

        .pricing-card[data-plan="business"] {
          animation-delay: 0.3s;
        }

        .card-content {
          padding: var(--spacing-xl);
          display: flex;
          flex-direction: column;
          height: 100%;
        }

        .pricing-card:hover {
          transform: translateY(-8px);
          box-shadow: var(--shadow-xl);
          border-color: var(--color-gray-200);
        }

        .pricing-card.featured {
          border: 2px solid transparent;
          background: linear-gradient(white, white) padding-box,
                     linear-gradient(135deg, var(--color-primary-light), var(--color-primary)) border-box;
          transform: scale(1.05);
          z-index: 2;
        }

        .pricing-card.featured:hover {
          transform: translateY(-8px) scale(1.05);
          box-shadow: var(--shadow-xl);
        }

        .popular-tag {
          position: absolute;
          top: 12px;
          right: -30px;
          background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-dark) 100%);
          color: white;
          padding: 8px 30px;
          font-size: var(--text-sm);
          font-weight: var(--font-medium);
          transform: rotate(45deg);
          box-shadow: var(--shadow-md);
        }

        .pricing-card h3 {
          font-size: var(--text-2xl);
          font-weight: var(--font-bold);
          color: var(--color-gray-900);
          margin-bottom: var(--spacing-md);
          position: relative;
          display: inline-block;
        }
        
        .pricing-card h3::after {
          content: '';
          display: block;
          width: 40px;
          height: 3px;
          background: linear-gradient(90deg, var(--color-primary-light), var(--color-primary));
          margin: var(--spacing-xs) auto 0;
          border-radius: var(--radius-full);
          transition: width var(--transition-normal);
        }
        
        .pricing-card:hover h3::after {
          width: 60px;
        }

        .price {
          font-size: var(--text-4xl);
          font-weight: var(--font-bold);
          background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-dark) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin: var(--spacing-md) 0 var(--spacing-xl);
          position: relative;
        }

        .price small {
          font-size: var(--text-base);
          color: var(--color-gray-500);
          font-weight: var(--font-normal);
          -webkit-text-fill-color: var(--color-gray-500);
        }

        .features {
          list-style: none;
          padding: 0;
          margin: var(--spacing-xl) 0;
          flex-grow: 1;
        }

        .features li {
          margin: var(--spacing-md) 0;
          color: var(--color-gray-500);
          position: relative;
          transition: transform var(--transition-normal);
        }
        
        .pricing-card:hover .features li {
          transform: translateY(-2px);
          transition-delay: calc(var(--transition-fast) * var(--n));
        }

        .features li:nth-child(1) { --n: 1; }
        .features li:nth-child(2) { --n: 2; }
        .features li:nth-child(3) { --n: 3; }
        .features li:nth-child(4) { --n: 4; }
        .features li:nth-child(5) { --n: 5; }
        .features li:nth-child(6) { --n: 6; }
        .features li:nth-child(7) { --n: 7; }

        .feature-highlight {
          color: var(--color-gray-900);
          font-weight: var(--font-semibold);
        }

        .button-wrapper {
          margin-top: auto;
          padding-top: var(--spacing-md);
        }

        .cta-button {
          display: inline-block;
          padding: var(--spacing-md) var(--spacing-2xl);
          background: var(--color-gray-100);
          color: var(--color-gray-900);
          text-decoration: none;
          border-radius: var(--radius-full);
          font-weight: var(--font-medium);
          transition: all var(--transition-normal);
          box-shadow: var(--shadow-sm);
          position: relative;
          overflow: hidden;
          width: 100%;
        }

        .cta-button::after {
          content: '';
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,0.4), rgba(255,255,255,0));
          transition: all 0.8s ease;
          z-index: 1;
        }

        .cta-button:hover {
          transform: translateY(-3px);
          box-shadow: var(--shadow-md);
          background: var(--color-gray-200);
          color: var(--color-gray-900);
        }

        .cta-button:hover::after {
          left: 100%;
        }

        .premium-button {
          background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-dark) 100%);
          color: white;
          box-shadow: var(--shadow-md);
        }

        .premium-button:hover {
          background: linear-gradient(135deg, var(--color-primary-dark) 0%, var(--color-primary) 100%);
          color: white;
          box-shadow: var(--shadow-lg);
        }

        .pricing-note {
          text-align: center;
          margin-top: var(--spacing-4xl);
          color: var(--color-gray-500);
          font-size: var(--text-sm);
          animation: fadeIn 1s ease-out 0.6s backwards;
        }

        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }

        @keyframes slideUp {
          from { transform: translateY(20px); opacity: 0; }
          to { transform: translateY(0); opacity: 1; }
        }

        @keyframes float {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
        }

        @media (max-width: 768px) {
          .pricing-header h1 {
            font-size: var(--text-3xl);
          }
          
          .pricing-grid {
            grid-template-columns: 1fr;
            max-width: 400px;
            margin: var(--spacing-2xl) auto;
          }

          .pricing-card.featured {
            transform: scale(1);
          }

          .pricing-card.featured:hover {
            transform: translateY(-8px) scale(1);
          }
        }
      </style>
    </app-layout>
  `;
}
