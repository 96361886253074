export class TemplateRenderer {
  constructor(templateConfig) {
    this.config = templateConfig;
    this.canvas = document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d');
    this.dpr = window.devicePixelRatio || 1;
    this.loadedFonts = new Set();
  }

  async loadFonts(template) {
    const fonts = new Set();
    Object.values(template.elements).forEach((element) => {
      fonts.add(element.style.fontFamily);
    });

    const fontsToLoad = Array.from(fonts).filter((font) => !this.loadedFonts.has(font));

    if (fontsToLoad.length === 0) {
      return;
    }

    try {
      await Promise.all(
        fontsToLoad.map(async (font) => {
          const fontFace = new FontFace(
            font,
            `local("${font}"), url(https://fonts.gstatic.com/s/${font.toLowerCase().replace(/\s+/g, '')}/v1/font.woff2)`,
          );
          await fontFace.load();
          document.fonts.add(fontFace);
          this.loadedFonts.add(font);
        }),
      );
    } catch (error) {
      console.warn('Some fonts failed to load:', error);
      // Continue anyway as the fonts might be available through Google Fonts
    }

    // Wait a bit to ensure fonts are applied
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  async initialize(templateId, width, height) {
    this.template = this.config.templates.find((t) => t.id === templateId);
    if (!this.template) {
      throw new Error(`Template ${templateId} not found`);
    }

    // Load fonts before setting up canvas
    await this.loadFonts(this.template);

    // Set canvas dimensions with DPR
    this.canvas.width = width * this.dpr;
    this.canvas.height = height * this.dpr;
    this.canvas.style.width = `${width}px`;
    this.canvas.style.height = `${height}px`;
    this.ctx.scale(this.dpr, this.dpr);

    // Load template SVG
    await this.loadTemplateSVG();
  }

  async loadTemplateSVG() {
    return new Promise((resolve, _reject) => {
      const img = new Image();
      img.crossOrigin = 'anonymous'; // Enable CORS for SVGs
      img.src = `/assets/images/templates/${this.template.templateFile}`;

      img.onload = () => {
        this.templateImage = img;
        resolve();
      };

      img.onerror = () => {
        console.error(`Failed to load template SVG: ${this.template.templateFile}`);
        // Resolve anyway to allow rendering without background
        resolve();
      };
    });
  }

  applyElementStyle(ctx, element) {
    const { style } = element;
    const fontWeight = style.fontWeight || 'normal';
    const fontSize = style.fontSize || 16;
    const fontStyle = style.fontStyle || 'normal';

    ctx.font = `${fontStyle} ${fontWeight} ${fontSize}px "${style.fontFamily}"`;
    ctx.fillStyle = style.color || '#000000';
    ctx.textAlign = element.position.align || 'center';

    if (style.letterSpacing) {
      ctx.letterSpacing = `${style.letterSpacing}px`;
    }
  }

  validateText(element, text) {
    const { maxLines, maxCharsPerLine } = element;
    const lines = text.split(/\\n|\n/);

    if (maxLines && lines.length > maxLines) {
      throw new Error(`Text exceeds maximum of ${maxLines} lines`);
    }

    if (maxCharsPerLine) {
      const longLines = lines.filter((line) => line.length > maxCharsPerLine);
      if (longLines.length > 0) {
        throw new Error(`Line exceeds maximum of ${maxCharsPerLine} characters`);
      }
    }
  }

  renderText(element, inputText) {
    this.validateText(element, inputText);

    const { position } = element;
    const x = (this.canvas.width * position.x) / this.dpr;
    const y = (this.canvas.height * position.y) / this.dpr;

    this.applyElementStyle(this.ctx, element);

    let displayText = inputText;
    if (element.style.textTransform) {
      displayText = this.transformText(displayText, element.style.textTransform);
    }

    const lines = displayText.split(/\\n|\n/);
    const lineHeight = element.style.fontSize * (element.style.lineHeight || 1.2);

    lines.forEach((line, index) => {
      this.ctx.fillText(line.trim(), x, y + index * lineHeight);
    });
  }

  renderTextBlock(element, inputText) {
    this.validateText(element, inputText);

    const { position, style } = element;
    const x = (this.canvas.width * position.x) / this.dpr;
    const y = (this.canvas.height * position.y) / this.dpr;
    const maxWidth = (this.canvas.width / this.dpr) * (style.maxWidth || 0.8);

    this.applyElementStyle(this.ctx, element);

    let displayText = inputText;
    if (element.style.textTransform) {
      displayText = this.transformText(displayText, element.style.textTransform);
    }

    // Split into paragraphs first
    const paragraphs = displayText.split(/\\n|\n/);
    const allLines = [];

    paragraphs.forEach((paragraph) => {
      const words = paragraph.split(' ');
      let currentLine = '';

      words.forEach((word) => {
        const testLine = `${currentLine + word} `;
        const metrics = this.ctx.measureText(testLine);

        if (metrics.width > maxWidth && currentLine !== '') {
          if (element.maxCharsPerLine && currentLine.length > element.maxCharsPerLine) {
            throw new Error(`Line exceeds maximum of ${element.maxCharsPerLine} characters`);
          }
          allLines.push(currentLine.trim());
          currentLine = `${word} `;
        } else {
          currentLine = testLine;
        }
      });
      if (currentLine.trim()) {
        if (element.maxCharsPerLine && currentLine.length > element.maxCharsPerLine) {
          throw new Error(`Line exceeds maximum of ${element.maxCharsPerLine} characters`);
        }
        allLines.push(currentLine.trim());
      }
    });

    if (element.maxLines && allLines.length > element.maxLines) {
      throw new Error(`Text exceeds maximum of ${element.maxLines} lines`);
    }

    const lineHeight = style.fontSize * (style.lineHeight || 1.5);
    const totalHeight = allLines.length * lineHeight;
    const startY = y - totalHeight / 2 + (style.padding?.top || 0);

    allLines.forEach((line, index) => {
      this.ctx.fillText(line, x, startY + index * lineHeight);
    });
  }

  transformText(text, transform) {
    switch (transform) {
      case 'uppercase':
        return text.toUpperCase();
      case 'lowercase':
        return text.toLowerCase();
      case 'capitalize':
        return text.replace(/\b\w/g, (l) => l.toUpperCase());
      default:
        return text;
    }
  }

  async render(content = {}) {
    // Clear canvas
    this.ctx.clearRect(0, 0, this.canvas.width / this.dpr, this.canvas.height / this.dpr);

    // Draw background
    this.ctx.fillStyle = '#ffffff';
    this.ctx.fillRect(0, 0, this.canvas.width / this.dpr, this.canvas.height / this.dpr);

    // Draw template SVG if available
    if (this.templateImage) {
      try {
        this.ctx.drawImage(
          this.templateImage,
          0,
          0,
          this.canvas.width / this.dpr,
          this.canvas.height / this.dpr,
        );
      } catch (error) {
        console.error('Error drawing template image:', error);
      }
    }

    // Render each element
    Object.entries(this.template.elements).forEach(([key, element]) => {
      const text = content[key] || element.defaultText || '';

      if (element.type === 'text') {
        this.renderText(element, text);
      } else if (element.type === 'text-block') {
        this.renderTextBlock(element, text);
      }
    });

    return this.canvas;
  }

  async toBlob() {
    // Validate all text content before starting the high-res render
    const textContent = {};
    Object.entries(this.template.elements).forEach(([key, element]) => {
      const text = document.getElementById(`${key}-text`)?.value || element.defaultText || '';
      this.validateText(element, text);
      textContent[key] = text;
    });

    // Create a high-resolution canvas for download
    const downloadCanvas = document.createElement('canvas');
    const ctx = downloadCanvas.getContext('2d');
    const scale = 3; // 3x resolution for download

    // Set dimensions without DPR since we're using scale for high res
    const baseWidth = this.canvas.width / this.dpr;
    const baseHeight = this.canvas.height / this.dpr;

    downloadCanvas.width = baseWidth * scale;
    downloadCanvas.height = baseHeight * scale;

    // Scale context for high-res rendering
    ctx.scale(scale, scale);

    // Draw background
    ctx.fillStyle = '#ffffff';
    ctx.fillRect(0, 0, baseWidth, baseHeight);

    // Draw template
    if (this.templateImage) {
      ctx.drawImage(this.templateImage, 0, 0, baseWidth, baseHeight);
    }

    // Create a renderer instance for high-res rendering with adjusted dimensions
    const highResRenderer = {
      canvas: downloadCanvas,
      ctx,
      dpr: 1,
      template: this.template,
      templateImage: this.templateImage,
      transformText: this.transformText,
      validateText: this.validateText.bind(this),
      applyElementStyle: (ctx, element) => {
        const { style } = element;
        const fontWeight = style.fontWeight || 'normal';
        const fontSize = style.fontSize || 16;
        const fontStyle = style.fontStyle || 'normal';

        ctx.font = `${fontStyle} ${fontWeight} ${fontSize}px "${style.fontFamily}"`;
        ctx.fillStyle = style.color || '#000000';
        ctx.textAlign = element.position.align || 'center';

        if (style.letterSpacing) {
          ctx.letterSpacing = `${style.letterSpacing}px`;
        }
      },
      renderText: (element, text) => {
        const { position } = element;
        const x = baseWidth * position.x;
        const y = baseHeight * position.y;

        highResRenderer.applyElementStyle(ctx, element);

        let displayText = text;
        if (element.style.textTransform) {
          displayText = this.transformText(displayText, element.style.textTransform);
        }

        const lines = displayText.split(/\\n|\n/);
        const lineHeight = element.style.fontSize * (element.style.lineHeight || 1.2);

        lines.forEach((line, index) => {
          ctx.fillText(line.trim(), x, y + index * lineHeight);
        });
      },
      renderTextBlock: (element, text) => {
        const { position, style } = element;
        const x = baseWidth * position.x;
        const y = baseHeight * position.y;
        const maxWidth = baseWidth * (style.maxWidth || 0.8);

        highResRenderer.applyElementStyle(ctx, element);

        // Split into paragraphs first
        const paragraphs = text.split(/\\n|\n/);
        const allLines = [];

        paragraphs.forEach((paragraph) => {
          const words = paragraph.split(' ');
          let currentLine = '';

          words.forEach((word) => {
            const testLine = `${currentLine + word} `;
            const metrics = ctx.measureText(testLine);

            if (metrics.width > maxWidth && currentLine !== '') {
              allLines.push(currentLine.trim());
              currentLine = `${word} `;
            } else {
              currentLine = testLine;
            }
          });
          if (currentLine.trim()) {
            allLines.push(currentLine.trim());
          }
        });

        const lineHeight = style.fontSize * (style.lineHeight || 1.5);
        const totalHeight = allLines.length * lineHeight;
        const startY = y - totalHeight / 2 + (style.padding?.top || 0);

        allLines.forEach((line, index) => {
          ctx.fillText(line, x, startY + index * lineHeight);
        });
      },
    };

    // Ensure fonts are loaded before rendering high-res version
    await this.loadFonts(this.template);

    // Copy styles and render text at high resolution
    Object.entries(this.template.elements).forEach(([key, element]) => {
      const text = textContent[key];

      if (element.type === 'text') {
        highResRenderer.renderText(element, text);
      } else if (element.type === 'text-block') {
        highResRenderer.renderTextBlock(element, text);
      }
    });

    return new Promise((resolve) => downloadCanvas.toBlob(resolve, 'image/png', 1.0));
  }

  getCustomizationOptions() {
    return this.template.customization;
  }

  updateStyle(elementKey, newStyle) {
    const element = this.template.elements[elementKey];
    if (!element) {
      return;
    }

    element.style = { ...element.style, ...newStyle };
  }
}
