// Signup page

import '../components/SignupForm.js';
import '../components/AppLayout/AppLayout.js';
import { isLoggedIn, getCurrentUser, onAuthChange } from '../services/auth.js';

export function loadSignupPage() {
  const app = document.getElementById('app');
  if (!app) {
    return;
  }

  // Check for returnTo parameter
  const hash = window.location.hash.slice(1);
  let returnTo = null;

  if (hash.includes('?')) {
    const pathPart = hash.substring(0, hash.indexOf('?'));
    const queryPart = hash.substring(hash.indexOf('?'));
    const searchParams = new URLSearchParams(queryPart);
    returnTo = searchParams.get('returnTo');

    console.log('SignupPage: Path part:', pathPart);
    console.log('SignupPage: Query part:', queryPart);
    console.log('SignupPage: Return to:', returnTo);
  }

  // Check if user is already logged in
  const loggedIn = isLoggedIn();
  const user = loggedIn ? getCurrentUser() : null;

  console.log('Loading signup page, auth state:', loggedIn ? 'LOGGED IN' : 'LOGGED OUT');

  // If user is logged in and there's a returnTo parameter, redirect immediately
  if (loggedIn && returnTo) {
    setTimeout(() => {
      window.location.hash = `#/${returnTo}`;
    }, 0);
    return;
  }

  // Render the page content
  renderSignupPage(app, loggedIn, user, returnTo);

  // Set up auth change listener
  const unsubscribe = onAuthChange(() => {
    const newLoggedIn = isLoggedIn();
    const newUser = newLoggedIn ? getCurrentUser() : null;
    console.log(
      'Auth changed in SignupPage:',
      newLoggedIn ? 'LOGGED IN' : 'LOGGED OUT',
      loggedIn !== newLoggedIn ? '(state changed)' : '(same state)',
    );

    // Re-render if the state changed
    if (loggedIn !== newLoggedIn) {
      if (newLoggedIn && returnTo) {
        // Redirect to the returnTo page if logged in
        window.location.hash = `#/${returnTo}`;
      } else {
        // Otherwise just re-render
        renderSignupPage(app, newLoggedIn, newUser, returnTo);
      }
    }
  });

  // Clean up event listener when page changes
  const cleanup = () => {
    unsubscribe();
    window.removeEventListener('hashchange', cleanup);
  };
  window.addEventListener('hashchange', cleanup);
}

function renderSignupPage(app, loggedIn, user, returnTo) {
  app.innerHTML = `
    <app-layout>
      <div class="signup-container">
        <div class="signup-columns">
          <div class="signup-column welcome-column">
            <div class="welcome-content">
              <h2>Join</h2>
              <p>Create an account to start crafting personalized thank you notes
              <br>that show your appreciation in style.</p>
              
              <div class="benefits-list">
                <div class="benefit-item">
                  <div class="benefit-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <div class="benefit-text">Free digital cards every month</div>
                </div>
                <div class="benefit-item">
                  <div class="benefit-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <div class="benefit-text">Save your favorite templates</div>
                </div>
                <div class="benefit-item">
                  <div class="benefit-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <div class="benefit-text">Track your sent cards</div>
                </div>
                <div class="benefit-item">
                  <div class="benefit-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <div class="benefit-text">Premium upgrade options</div>
                </div>
              </div>
              
              <div class="illustration">
                <div class="card-mock card-mock-1">
                  <div class="card-stripe"></div>
                  <div class="card-content-preview">
                    <div class="card-preview-header"></div>
                    <div class="card-preview-body">
                      <div class="preview-line"></div>
                      <div class="preview-line"></div>
                    </div>
                  </div>
                </div>
                <div class="card-mock card-mock-2">
                  <div class="card-stripe"></div>
                  <div class="card-content-preview">
                    <div class="card-preview-header"></div>
                    <div class="card-preview-body">
                      <div class="preview-line"></div>
                      <div class="preview-line"></div>
                    </div>
                  </div>
                </div>
                <div class="card-mock card-mock-3">
                  <div class="card-stripe"></div>
                  <div class="card-content-preview">
                    <div class="card-preview-header"></div>
                    <div class="card-preview-body">
                      <div class="preview-line"></div>
                      <div class="preview-line"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="signup-column form-column">
            <div class="signup-card">
              ${
                loggedIn
                  ? `
                <div class="card-content">
                  <h1>You're Already Signed In</h1>
                  <p class="subtitle">Welcome back, ${user?.name || user?.username || 'there'}!</p>
                  
                  <div class="logged-in-actions">
                    ${
                      returnTo
                        ? `<a href="#/${returnTo}" class="btn btn-primary">Continue to ${returnTo.charAt(0).toUpperCase() + returnTo.slice(1)}</a>`
                        : `<a href="#/" class="btn btn-primary">Go to Home</a>`
                    }
                    <a href="#/settings" class="btn btn-secondary">Account Settings</a>
                  </div>
                </div>
              `
                  : `
                <div class="card-content">
                  <h1>Create an Account</h1>
                  <p class="subtitle">Join <span class="text-sm">SendAThankyouCard.com</span> and start creating beautiful thank you cards${returnTo ? ' to continue' : ''}</p>
                  
                  <signup-form data-return-to="${returnTo || ''}"></signup-form>
                  
                  <p class="login-prompt">
                    Already have an account? <a href="#/login${returnTo ? `?returnTo=${returnTo}` : ''}">Sign in</a>
                  </p>
                </div>
              `
              }
            </div>
          </div>
        </div>
        
        <!-- Background decorative elements -->
        <div class="bg-circle bg-circle-1"></div>
        <div class="bg-circle bg-circle-2"></div>
        <div class="bg-circle bg-circle-3"></div>
      </div>
    </app-layout>
  `;

  // Add some page-specific styles
  const style = document.createElement('style');
  style.textContent = `
    .signup-container {
      min-height: calc(100vh - var(--header-height) - 100px);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--spacing-3xl) var(--spacing-md);
      position: relative;
      overflow: hidden;
    }
    
    .signup-columns {
      display: flex;
      width: 100%;
      max-width: 1000px;
      box-shadow: var(--shadow-xl);
      border-radius: var(--radius-xl);
      overflow: hidden;
      background: white;
      position: relative;
      z-index: 1;
      animation: fadeIn 0.8s ease-out;
    }
    
    .signup-column {
      flex: 1;
    }
    
    .welcome-column {
      background: linear-gradient(135deg, rgba(16, 185, 129, 0.95) 0%, rgba(14, 159, 110, 0.95) 100%);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--spacing-2xl);
      position: relative;
      overflow: hidden;
    }
    
    .welcome-column::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='rgba(255,255,255,.075)' fill-rule='evenodd'/%3E%3C/svg%3E");
      opacity: 0.6;
    }
    
    .welcome-content {
      position: relative;
      z-index: 1;
    }
    
    .welcome-column h2 {
      font-family: var(--font-display);
      font-size: var(--text-3xl);
      font-weight: var(--font-bold);
      margin-bottom: var(--spacing-sm);
      text-shadow: 0 2px 4px rgba(0,0,0,0.1);
      animation: fadeIn 0.8s ease-out;
      line-height: 1.2;
    }
    
    .brand-name {
      color: #fff;
      background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(224,242,254,1) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: var(--text-4xl);
      margin-bottom: var(--spacing-md);
    }
    
    .welcome-column p {
      font-size: var(--text-lg);
      margin-bottom: var(--spacing-2xl);
      opacity: 0.9;
      max-width: 400px;
      line-height: 1.6;
      animation: fadeIn 0.8s ease-out 0.1s backwards;
    }
    
    .benefits-list {
      margin-bottom: var(--spacing-2xl);
    }
    
    .benefit-item {
      display: flex;
      align-items: center;
      margin-bottom: var(--spacing-md);
      animation: slideUp 0.5s ease-out backwards;
    }
    
    .benefit-item:nth-child(1) {
      animation-delay: 0.2s;
    }
    
    .benefit-item:nth-child(2) {
      animation-delay: 0.3s;
    }
    
    .benefit-item:nth-child(3) {
      animation-delay: 0.4s;
    }
    
    .benefit-item:nth-child(4) {
      animation-delay: 0.5s;
    }
    
    .benefit-icon {
      width: 20px;
      height: 20px;
      margin-right: var(--spacing-md);
      color: rgba(255, 255, 255, 0.9);
      flex-shrink: 0;
    }
    
    .benefit-text {
      font-size: var(--text-base);
      font-weight: var(--font-medium);
    }
    
    .illustration {
      height: 200px;
      position: relative;
      margin-top: var(--spacing-xl);
      animation: fadeIn 0.8s ease-out 0.5s backwards;
    }
    
    .card-mock {
      position: absolute;
      border-radius: var(--radius-md);
      background: white;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2);
      transition: all 0.3s ease;
      overflow: hidden;
      border: 1px solid rgba(var(--color-primary-rgb), 0.1);
    }
    
    .card-stripe {
      height: 6px;
      width: 100%;
      background: linear-gradient(90deg, rgba(16, 185, 129, 0.9) 0%, rgba(5, 150, 105, 0.9) 100%);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
    
    .card-mock-1 {
      width: 180px;
      height: 120px;
      top: 10px;
      left: 30px;
      transform: rotate(-8deg);
      z-index: 1;
      background: linear-gradient(135deg, #ffffff 0%, #f9fafb 100%);
    }
    
    .card-mock-2 {
      width: 180px;
      height: 120px;
      top: 0;
      left: 90px;
      transform: rotate(4deg);
      z-index: 2;
      background: linear-gradient(135deg, #f9fafb 0%, #f3f4f6 100%);
    }
    
    .card-mock-3 {
      width: 180px;
      height: 120px;
      top: 30px;
      left: 60px;
      transform: rotate(-3deg);
      z-index: 3;
      background: linear-gradient(135deg, #ffffff 0%, #f5f5f5 100%);
    }
    
    .card-content-preview {
      width: 100%;
      height: 100%;
      padding: 8px;
      padding-top: 14px; /* Additional space to account for the stripe */
    }
    
    .card-preview-header {
      height: 24px;
      background: rgba(16, 185, 129, 0.1);
      border-radius: var(--radius-sm);
      margin-bottom: 8px;
    }
    
    .card-preview-body {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
    
    .preview-line {
      height: 8px;
      background: rgba(var(--color-gray-rgb), 0.2);
      border-radius: var(--radius-sm);
    }
    
    .preview-line:nth-child(2) {
      width: 70%;
    }
    
    .card-mock:hover {
      transform: translateY(-5px) rotate(0deg) !important;
      box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
      z-index: 10 !important;
    }
    
    .form-column {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--spacing-xl);
      background: white;
    }
    
    .signup-card {
      width: 100%;
      position: relative;
      z-index: 1;
      border: none;
      box-shadow: none;
      animation: slideUp 0.5s ease-out;
    }
    
    .card-content {
      padding: var(--spacing-md) var(--spacing-xl);
    }
    
    .signup-card h1 {
      font-family: var(--font-display);
      font-size: var(--text-3xl);
      text-align: center;
      margin-bottom: var(--spacing-xs);
      color: var(--color-gray-900);
      font-weight: var(--font-bold);
    }
    
    .subtitle {
      text-align: center;
      color: var(--color-gray-500);
      margin-bottom: var(--spacing-xl);
    }
    
    .login-prompt {
      text-align: center;
      margin-top: var(--spacing-xl);
      color: var(--color-gray-500);
      font-size: var(--text-sm);
    }
    
    .login-prompt a {
      color: var(--color-primary);
      text-decoration: none;
      font-weight: var(--font-medium);
      transition: all var(--transition-normal);
    }
    
    .login-prompt a:hover {
      color: var(--color-primary-dark);
      text-decoration: underline;
    }
    
    .logged-in-actions {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-md);
      margin: var(--spacing-xl) 0;
    }
    
    .btn {
      display: block;
      width: 100%;
      padding: var(--spacing-md);
      border-radius: var(--radius-full);
      font-weight: var(--font-medium);
      text-align: center;
      text-decoration: none;
      transition: all var(--transition-normal);
    }
    
    .btn-primary {
      background: linear-gradient(135deg, var(--color-primary-light), var(--color-primary));
      color: white;
      border: none;
      transition: all 0.3s ease;
      position: relative;
      z-index: 1;
      overflow: hidden;
    }
    
    .btn-primary:before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent);
      transition: all 0.6s ease;
      z-index: -1;
    }
    
    .btn-primary:hover:before {
      left: 100%;
    }
    
    .btn-primary:hover {
      transform: translateY(-2px);
      box-shadow: 0 7px 14px rgba(var(--color-primary-rgb), 0.3), 0 3px 6px rgba(0, 0, 0, 0.1);
    }
    
    .btn-secondary {
      background: var(--color-gray-100);
      color: var(--color-gray-800);
      border: 1px solid var(--color-gray-200);
    }
    
    .btn-secondary:hover {
      background: var(--color-gray-200);
      transform: translateY(-3px);
      box-shadow: var(--shadow-md);
    }
    
    .bg-circle {
      position: absolute;
      border-radius: 50%;
      z-index: 0;
    }
    
    .bg-circle-1 {
      width: 600px;
      height: 600px;
      top: -200px;
      right: -200px;
      background: radial-gradient(circle, rgba(16, 185, 129, 0.08) 0%, rgba(16, 185, 129, 0) 70%);
      animation: float 15s ease-in-out infinite;
    }
    
    .bg-circle-2 {
      width: 400px;
      height: 400px;
      bottom: -100px;
      left: -100px;
      background: radial-gradient(circle, rgba(79, 70, 229, 0.08) 0%, rgba(79, 70, 229, 0) 70%);
      animation: float 20s ease-in-out infinite reverse;
    }
    
    .bg-circle-3 {
      width: 300px;
      height: 300px;
      top: 40%;
      left: 40%;
      background: radial-gradient(circle, rgba(236, 72, 153, 0.08) 0%, rgba(236, 72, 153, 0) 70%);
      animation: float 25s ease-in-out infinite;
    }
    
    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }
    
    @keyframes slideUp {
      from { transform: translateY(20px); opacity: 0; }
      to { transform: translateY(0); opacity: 1; }
    }
    
    @keyframes float {
      0%, 100% { transform: translate(0, 0); }
      50% { transform: translate(-20px, 20px); }
    }
    
    @media (max-width: 940px) {
      .signup-columns {
        flex-direction: column;
        max-width: 500px;
      }
      
      .welcome-column {
        padding: var(--spacing-xl);
        text-align: center;
      }
      
      .welcome-column p {
        margin-left: auto;
        margin-right: auto;
      }
      
      .benefit-item {
        justify-content: center;
      }
      
      .illustration {
        margin-top: var(--spacing-md);
        height: 160px;
      }
      
      .card-mock {
        transform: scale(0.8);
      }
      
      .form-column {
        padding: var(--spacing-md);
      }
    }
    
    @media (max-width: 600px) {
      .welcome-column {
        display: none;
      }
      
      .signup-card {
        border: 1px solid var(--color-gray-100);
        border-radius: var(--radius-xl);
        box-shadow: var(--shadow-lg);
      }
      
      .card-content {
        padding: var(--spacing-xl);
      }
      
      .signup-card h1 {
        font-size: var(--text-2xl);
      }
    }
    
    .text-sm {
      font-size: 0.9em;
    }
  `;
  document.head.appendChild(style);
}
