// API calls (fetch data from server, e.g., cards, user info)

import { API_BASE_URL } from '../config.js';

// Mock user data to prevent unnecessary API calls during development
const MOCK_USER_DATA = {
  id: 'mock-user-1',
  name: 'Demo User',
  email: 'demo@example.com',
  role: 'user',
};

export async function getUserData() {
  // Use mock data in development to prevent unnecessary API calls
  const isDev = process.env.NODE_ENV !== 'production';
  if (isDev) {
    // Always use mock in dev environment
    console.log('Using mock user data');
    return MOCK_USER_DATA;
  }

  try {
    const response = await fetch(`${API_BASE_URL}/user`);
    if (!response.ok) {
      throw new Error('Failed to fetch user data.');
    }
    return response.json();
  } catch (error) {
    console.error(error);
    return null;
  }
}

// Example: create a card
export async function createCard(data) {
  // This function is not used - we use PocketBaseClient.createCard instead
  console.warn(
    'The API createCard function is deprecated. Use PocketBaseClient.createCard instead.',
  );
  return null;

  /* 
  try {
    const response = await fetch(`${API_BASE_URL}/cards`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error('Failed to create card.');
    }
    return response.json();
  } catch (error) {
    console.error(error);
    return null;
  }
  */
}
