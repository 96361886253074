import '../components/AppLayout/AppLayout.js';

export function loadPrivacyPage() {
  const app = document.getElementById('app');
  if (!app) {
    return;
  }

  app.innerHTML = `
    <app-layout>
      <div class="privacy-container">
        <div class="privacy-header">
          <h1>Privacy Policy</h1>
          <p class="subtitle">Last updated: March 2024</p>
        </div>

        <div class="privacy-content">
          <section class="privacy-section">
            <h2>Introduction</h2>
            <p>At SendAThankYouCard, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our service.</p>
          </section>

          <section class="privacy-section">
            <h2>Information We Collect</h2>
            <h3>Personal Information</h3>
            <p>We collect information that you provide directly to us, including:</p>
            <ul>
              <li>Name and contact information</li>
              <li>Account credentials</li>
              <li>Payment information</li>
              <li>Communication preferences</li>
            </ul>

            <h3>Usage Information</h3>
            <p>We automatically collect certain information about your device and how you interact with our services, including:</p>
            <ul>
              <li>Device information</li>
              <li>Log data</li>
              <li>Usage patterns</li>
              <li>Performance data</li>
            </ul>
          </section>

          <section class="privacy-section">
            <h2>How We Use Your Information</h2>
            <p>We use the information we collect to:</p>
            <ul>
              <li>Provide and maintain our services</li>
              <li>Process your transactions</li>
              <li>Send you important updates</li>
              <li>Improve our services</li>
              <li>Protect against fraud</li>
            </ul>
          </section>

          <section class="privacy-section">
            <h2>Information Sharing</h2>
            <p>We do not sell your personal information. We may share your information with:</p>
            <ul>
              <li>Service providers who assist in our operations</li>
              <li>Professional advisors</li>
              <li>Law enforcement when required by law</li>
            </ul>
          </section>

          <section class="privacy-section">
            <h2>Your Rights</h2>
            <p>You have the right to:</p>
            <ul>
              <li>Access your personal information</li>
              <li>Correct inaccurate data</li>
              <li>Request deletion of your data</li>
              <li>Opt-out of marketing communications</li>
            </ul>
          </section>

          <section class="privacy-section">
            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at:</p>
            <a href="mailto:privacy@sendathankyoucard.com" class="contact-link">
              privacy@sendathankyoucard.com
            </a>
          </section>
        </div>
      </div>
    </app-layout>
  `;

  // Add styles
  const style = document.createElement('style');
  style.textContent = `
    .privacy-container {
      padding: var(--spacing-3xl) var(--spacing-md);
      max-width: var(--content-width-md);
      margin: 0 auto;
    }

    .privacy-header {
      text-align: center;
      margin-bottom: var(--spacing-3xl);
    }

    .privacy-header h1 {
      font-family: var(--font-display);
      font-size: var(--text-4xl);
      color: var(--color-gray-900);
      margin-bottom: var(--spacing-sm);
    }

    .subtitle {
      font-size: var(--text-lg);
      color: var(--color-gray-500);
    }

    .privacy-content {
      background: var(--color-white);
      border-radius: var(--radius-lg);
      padding: var(--spacing-2xl);
      box-shadow: var(--shadow-md);
    }

    .privacy-section {
      margin-bottom: var(--spacing-2xl);
    }

    .privacy-section:last-child {
      margin-bottom: 0;
    }

    .privacy-section h2 {
      font-family: var(--font-display);
      font-size: var(--text-2xl);
      color: var(--color-gray-900);
      margin-bottom: var(--spacing-lg);
    }

    .privacy-section h3 {
      font-size: var(--text-xl);
      color: var(--color-gray-800);
      margin: var(--spacing-xl) 0 var(--spacing-md);
    }

    .privacy-section p {
      color: var(--color-gray-700);
      line-height: 1.6;
      margin-bottom: var(--spacing-md);
    }

    .privacy-section ul {
      list-style-type: disc;
      padding-left: var(--spacing-xl);
      margin-bottom: var(--spacing-lg);
    }

    .privacy-section li {
      color: var(--color-gray-700);
      line-height: 1.6;
      margin-bottom: var(--spacing-xs);
    }

    .contact-link {
      color: var(--color-primary);
      text-decoration: none;
      font-weight: var(--font-medium);
    }

    .contact-link:hover {
      text-decoration: underline;
    }

    @media (max-width: 768px) {
      .privacy-header h1 {
        font-size: var(--text-3xl);
      }

      .privacy-content {
        padding: var(--spacing-xl);
      }

      .privacy-section h2 {
        font-size: var(--text-xl);
      }

      .privacy-section h3 {
        font-size: var(--text-lg);
      }
    }
  `;
  document.head.appendChild(style);
}
