import { LitElement, html, css } from 'lit';
import { publishCard } from '../../services/PocketBaseClient.js';
import { isLoggedIn } from '../../services/auth.js';
import { navigateTo } from '../../router/router.js';

export class ShareCardModal extends LitElement {
  static get properties() {
    return {
      card: { type: Object },
      open: { type: Boolean },
      error: { type: String },
      success: { type: Boolean },
      loading: { type: Boolean },
      accessCodeEnabled: { type: Boolean },
      expirationEnabled: { type: Boolean },
      accessCode: { type: String },
      expirationDate: { type: String },
    };
  }

  constructor() {
    super();
    this.card = null;
    this.open = false;
    this.error = '';
    this.success = false;
    this.loading = false;
    this.accessCodeEnabled = false;
    this.expirationEnabled = false;
    this.accessCode = '';
    this.expirationDate = '';
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }

      .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        opacity: 0;
        visibility: hidden;
        transition:
          opacity 0.3s,
          visibility 0.3s;
      }

      .modal-overlay.open {
        opacity: 1;
        visibility: visible;
      }

      .modal {
        background-color: white;
        border-radius: 8px;
        padding: 24px;
        width: 90%;
        max-width: 500px;
        max-height: 90vh;
        overflow-y: auto;
        box-shadow:
          0 4px 6px rgba(0, 0, 0, 0.1),
          0 1px 3px rgba(0, 0, 0, 0.08);
        transform: translateY(20px);
        transition: transform 0.3s;
      }

      .modal-overlay.open .modal {
        transform: translateY(0);
      }

      .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
      }

      h2 {
        margin: 0;
        font-size: 1.5rem;
        color: var(--color-text, #1f2937);
      }

      .close-button {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 1.5rem;
        padding: 0;
        color: var(--color-text-secondary, #6b7280);
      }

      .form-group {
        margin-bottom: 16px;
      }

      label {
        display: block;
        margin-bottom: 8px;
        font-weight: 500;
        color: var(--color-text, #1f2937);
      }

      input,
      textarea {
        width: 100%;
        padding: 8px 12px;
        border: 1px solid var(--color-border, #d1d5db);
        border-radius: 4px;
        font-size: 1rem;
      }

      .checkbox-group {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
      }

      .checkbox-group input {
        width: auto;
        margin-right: 8px;
      }

      .checkbox-group label {
        margin-bottom: 0;
      }

      .button-group {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
        margin-top: 24px;
      }

      button {
        padding: 8px 16px;
        border-radius: 4px;
        font-weight: 500;
        cursor: pointer;
        transition: background-color 0.2s;
      }

      .cancel-button {
        background-color: transparent;
        border: 1px solid var(--color-border, #d1d5db);
        color: var(--color-text, #1f2937);
      }

      .share-button {
        background-color: var(--color-primary, #4f46e5);
        border: none;
        color: white;
      }

      .share-button:hover {
        background-color: var(--color-primary-dark, #4338ca);
      }

      .cancel-button:hover {
        background-color: var(--color-gray-100, #f3f4f6);
      }

      .error-message {
        color: var(--color-error, #ef4444);
        margin-top: 8px;
        font-size: 0.875rem;
      }

      .success-message {
        color: var(--color-success, #10b981);
        margin-top: 8px;
        font-size: 0.875rem;
      }

      .shared-url {
        display: flex;
        margin-top: 16px;
        margin-bottom: 16px;
      }

      .shared-url input {
        flex-grow: 1;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
      }

      .copy-button {
        background-color: var(--color-gray-100, #f3f4f6);
        border: 1px solid var(--color-border, #d1d5db);
        border-left: none;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        padding: 8px 12px;
        color: var(--color-text, #1f2937);
      }

      .copy-button:hover {
        background-color: var(--color-gray-200, #e5e7eb);
      }

      .loading {
        opacity: 0.7;
        pointer-events: none;
      }

      .social-share {
        display: flex;
        gap: 12px;
        margin-top: 16px;
      }

      .social-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        transition: all 0.2s ease;
      }

      .social-button svg {
        width: 20px;
        height: 20px;
      }

      .facebook {
        background-color: #3b5998;
        color: white;
      }

      .twitter {
        background-color: #1da1f2;
        color: white;
      }

      .email {
        background-color: #6b7280;
        color: white;
      }

      .social-button:hover {
        opacity: 0.9;
        transform: translateY(-2px);
      }
    `;
  }

  render() {
    return html`
      <div class="modal-overlay ${this.open ? 'open' : ''}" @click="${this._handleOverlayClick}">
        <div class="modal ${this.loading ? 'loading' : ''}" @click="${this._stopPropagation}">
          <div class="modal-header">
            <h2>Share Your Thank You Card</h2>
            <button class="close-button" @click="${this._close}">&times;</button>
          </div>

          ${this.success ? this._renderSuccessView() : this._renderForm()}
        </div>
      </div>
    `;
  }

  _renderForm() {
    return html`
      <div>
        <p>Customize how you want to share your thank you card.</p>

        <div class="form-group">
          <div class="checkbox-group">
            <input
              type="checkbox"
              id="expiration"
              ?checked="${this.expirationEnabled}"
              @change="${this._toggleExpiration}"
            />
            <label for="expiration">Set an expiration date</label>
          </div>

          ${this.expirationEnabled
            ? html`
                <input
                  type="date"
                  id="expirationDate"
                  .value="${this.expirationDate}"
                  @input="${this._updateExpirationDate}"
                  min="${this._getTomorrowDate()}"
                />
              `
            : ''}
        </div>

        <div class="form-group">
          <div class="checkbox-group">
            <input
              type="checkbox"
              id="accessCode"
              ?checked="${this.accessCodeEnabled}"
              @change="${this._toggleAccessCode}"
            />
            <label for="accessCode">Require an access code</label>
          </div>

          ${this.accessCodeEnabled
            ? html`
                <input
                  type="text"
                  id="accessCodeValue"
                  placeholder="Enter an access code"
                  .value="${this.accessCode}"
                  @input="${this._updateAccessCode}"
                />
                <small>Share this code with your recipient so they can view the card.</small>
              `
            : ''}
        </div>

        ${this.error ? html`<div class="error-message">${this.error}</div>` : ''}

        <div class="button-group">
          <button class="cancel-button" @click="${this._close}">Cancel</button>
          <button class="share-button" @click="${this._shareCard}">Share Card</button>
        </div>
      </div>
    `;
  }

  _renderSuccessView() {
    const fullUrl = this._getFullCardUrl();

    return html`
      <div>
        <p class="success-message">✓ Your card is ready to share!</p>

        <label>Shareable Link</label>
        <div class="shared-url">
          <input type="text" readonly .value="${fullUrl}" />
          <button class="copy-button" @click="${this._copyUrl}">Copy</button>
        </div>

        <div>
          <label>Share On</label>
          <div class="social-share">
            <button
              class="social-button facebook"
              @click="${() => this._shareToSocial('facebook')}"
            >
              <svg viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"
                />
              </svg>
            </button>
            <button class="social-button twitter" @click="${() => this._shareToSocial('twitter')}">
              <svg viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723 9.99 9.99 0 01-3.127 1.195 4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"
                />
              </svg>
            </button>
            <button class="social-button email" @click="${this._shareByEmail}">
              <svg viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
                />
              </svg>
            </button>
          </div>
        </div>

        <div class="button-group">
          <button class="share-button" @click="${this._close}">Done</button>
        </div>
      </div>
    `;
  }

  _handleOverlayClick(e) {
    if (e.target === e.currentTarget) {
      this._close();
    }
  }

  _stopPropagation(e) {
    e.stopPropagation();
  }

  _close() {
    this.open = false;
    // Reset form
    setTimeout(() => {
      this.error = '';
      this.success = false;
      this.accessCodeEnabled = false;
      this.expirationEnabled = false;
      this.accessCode = '';
      this.expirationDate = '';
    }, 300);
  }

  _toggleAccessCode(e) {
    this.accessCodeEnabled = e.target.checked;
  }

  _toggleExpiration(e) {
    this.expirationEnabled = e.target.checked;
    if (this.expirationEnabled && !this.expirationDate) {
      // Set default expiration to 30 days from now
      const date = new Date();
      date.setDate(date.getDate() + 30);
      // Format date as YYYY-MM-DD for the date input
      this.expirationDate = date.toISOString().split('T')[0];
      console.log(`Set default expiration date to: ${this.expirationDate}`);
    }
  }

  _updateAccessCode(e) {
    this.accessCode = e.target.value;
  }

  _updateExpirationDate(e) {
    this.expirationDate = e.target.value;
  }

  _getTomorrowDate() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toISOString().split('T')[0];
  }

  async _shareCard() {
    if (!isLoggedIn()) {
      // User is not logged in, redirect to login page
      this._close();
      navigateTo('/login');
      return;
    }

    if (this.accessCodeEnabled && !this.accessCode.trim()) {
      this.error = 'Please enter an access code';
      return;
    }

    try {
      this.loading = true;
      this.error = '';

      // Parse the expiration date string into a proper Date object if enabled
      let expirationDate = null;
      if (this.expirationEnabled && this.expirationDate) {
        expirationDate = new Date(this.expirationDate);
        // Set the time to end of day (23:59:59)
        expirationDate.setHours(23, 59, 59, 999);
      }

      const accessCode = this.accessCodeEnabled ? this.accessCode.trim() : null;

      console.log(`Sharing card with ID: ${this.card.id}`);
      console.log(`Expiration enabled: ${this.expirationEnabled}, Date: ${expirationDate}`);
      console.log(
        `Access code enabled: ${this.accessCodeEnabled}, Code: ${accessCode ? 'PROVIDED' : 'NONE'}`,
      );

      await publishCard(this.card.id, expirationDate, accessCode);

      this.success = true;
    } catch (error) {
      console.error('Error sharing card:', error);
      this.error = 'Failed to share the card. Please try again.';
    } finally {
      this.loading = false;
    }
  }

  _getFullCardUrl() {
    const baseUrl = window.location.origin;
    return `${baseUrl}/#/view/${this.card.id}`;
  }

  async _copyUrl() {
    const url = this._getFullCardUrl();
    try {
      await navigator.clipboard.writeText(url);
      alert('Link copied to clipboard!');
    } catch (err) {
      console.error('Could not copy text: ', err);
    }
  }

  _shareToSocial(platform) {
    const url = encodeURIComponent(this._getFullCardUrl());
    const text = encodeURIComponent('I sent you a thank you card! Check it out:');

    let shareUrl = '';

    if (platform === 'facebook') {
      shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    } else if (platform === 'twitter') {
      shareUrl = `https://twitter.com/intent/tweet?text=${text}&url=${url}`;
    }

    if (shareUrl) {
      window.open(shareUrl, '_blank');
    }
  }

  _shareByEmail() {
    const url = this._getFullCardUrl();
    const subject = encodeURIComponent('I sent you a thank you card');
    const body = encodeURIComponent(`I sent you a thank you card! View it here: ${url}`);

    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  }

  // Public method to open the modal with a card
  openWithCard(card) {
    this.card = card;
    this.open = true;
  }
}

// Define the custom element
customElements.define('share-card-modal', ShareCardModal);
