import { html, css, LitElement } from 'lit';
import { login, isLoggedIn, getCurrentUser, logout } from '../services/auth.js';

export class LoginForm extends LitElement {
  static styles = css`
    .form-group {
      margin-bottom: var(--spacing-md);
    }

    label {
      display: block;
      margin-bottom: var(--spacing-xs);
      color: var(--color-gray-700);
      font-weight: var(--font-medium);
    }

    input {
      width: 100%;
      padding: var(--spacing-md);
      border: 1px solid var(--color-gray-200);
      border-radius: var(--radius-md);
      font-size: var(--text-base);
      transition: all var(--transition-normal);
    }

    input:focus {
      outline: none;
      border-color: var(--color-primary);
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    }

    .form-options {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: var(--spacing-lg);
    }

    .remember-me {
      display: flex;
      align-items: center;
      gap: var(--spacing-xs);
      color: var(--color-gray-700);
      cursor: pointer;
    }

    .forgot-password {
      color: var(--color-primary);
      text-decoration: none;
      font-size: var(--text-sm);
    }

    .forgot-password:hover {
      text-decoration: underline;
    }

    .btn-primary {
      width: 100%;
      padding: var(--spacing-md);
      background: var(--color-primary);
      color: var(--color-white);
      border: none;
      border-radius: var(--radius-md);
      font-weight: var(--font-medium);
      cursor: pointer;
      transition: all var(--transition-normal);
    }

    .btn-primary:hover {
      transform: translateY(-2px);
      box-shadow: var(--shadow-lg);
    }

    .btn-primary:disabled {
      background: var(--color-gray-400);
      cursor: not-allowed;
      transform: none;
      box-shadow: none;
    }

    .btn-secondary {
      width: 100%;
      padding: var(--spacing-md);
      background: var(--color-gray-300);
      color: var(--color-gray-900);
      border: none;
      border-radius: var(--radius-md);
      font-weight: var(--font-medium);
      cursor: pointer;
      transition: all var(--transition-normal);
      margin-top: var(--spacing-md);
    }

    .btn-secondary:hover {
      background: var(--color-gray-400);
    }

    .error {
      color: var(--color-error, #ff0000);
      margin-top: var(--spacing-sm);
      font-size: var(--text-sm);
    }

    .success {
      color: var(--color-success, #00aa00);
      margin-top: var(--spacing-sm);
      font-size: var(--text-sm);
    }

    .user-info {
      margin-top: var(--spacing-lg);
      padding: var(--spacing-md);
      background-color: var(--color-gray-100);
      border-radius: var(--radius-md);
    }

    .user-profile {
      text-align: center;
    }

    .user-avatar {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: var(--color-primary);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      margin: 0 auto var(--spacing-md);
    }

    .user-email {
      font-weight: var(--font-medium);
      margin-bottom: var(--spacing-xs);
    }

    .user-id {
      font-size: var(--text-sm);
      color: var(--color-gray-500);
      margin-bottom: var(--spacing-md);
    }
  `;

  static properties = {
    error: { type: String },
    success: { type: String },
    loading: { type: Boolean },
    loggedIn: { type: Boolean },
    returnTo: { type: String, attribute: 'data-return-to' },
  };

  constructor() {
    super();
    this.error = '';
    this.success = '';
    this.loading = false;
    this.loggedIn = isLoggedIn();
    this.returnTo = '';

    // Update logged in state if auth changes
    this._unsubscribe = null;
  }

  connectedCallback() {
    super.connectedCallback();

    // Check URL for returnTo parameter
    const hash = window.location.hash.slice(1);
    if (hash.includes('?')) {
      const searchParams = new URLSearchParams(hash.substring(hash.indexOf('?')));
      const returnTo = searchParams.get('returnTo');
      if (returnTo) {
        console.log('LoginForm: Found returnTo parameter:', returnTo);
        this.returnTo = returnTo;
      }
    }

    // Listen for auth changes to update UI
    this._onAuthChange = () => {
      this.loggedIn = isLoggedIn();
    };
    document.addEventListener('authchange', this._onAuthChange);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    // Clean up listener
    document.removeEventListener('authchange', this._onAuthChange);
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.error = '';
    this.success = '';
    this.loading = true;

    const form = e.target;
    const username = form.email.value;
    const password = form.password.value;

    if (!username || !password) {
      this.error = 'Please enter both email and password';
      this.loading = false;
      return;
    }

    try {
      // This will throw an error if login fails
      await login(username, password);

      // Clear form fields
      form.email.value = '';
      form.password.value = '';

      this.loggedIn = true;
      this.success = 'Login successful!';

      // Navigate to the returnTo page if one exists, otherwise go to home page
      setTimeout(() => {
        if (this.returnTo) {
          window.location.hash = `#/${this.returnTo}`;
        } else {
          window.location.hash = '/';
        }
      }, 500);
    } catch (error) {
      console.error('Login error:', error);
      this.error = error.message || 'Invalid username or password';
    } finally {
      this.loading = false;
    }
  }

  handleLogout() {
    logout();
    this.loggedIn = false;
    this.success = 'Logged out successfully';
  }

  render() {
    if (this.loggedIn) {
      const user = getCurrentUser();
      const initials = user?.username?.substring(0, 2).toUpperCase() || '??';

      return html`
        <div class="user-profile">
          <div class="user-avatar">${initials}</div>
          <p class="user-email">${user?.email || user?.username || 'User'}</p>
          <p class="user-id">Account ID: ${user?.id || 'Unknown'}</p>

          <button @click=${this.handleLogout} class="btn-secondary">Sign Out</button>
        </div>
      `;
    }

    return html`
      <form @submit=${this.handleSubmit}>
        <div class="form-group">
          <label for="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email"
            autocomplete="email"
            required
          />
        </div>

        <div class="form-group">
          <label for="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            placeholder="Enter your password"
            autocomplete="current-password"
            required
          />
        </div>

        <div class="form-options">
          <label class="remember-me">
            <input type="checkbox" id="remember" />
            <span>Remember me</span>
          </label>
          <a href="#/reset-password" class="forgot-password">Forgot password?</a>
        </div>

        <button type="submit" class="btn-primary" ?disabled=${this.loading}>
          ${this.loading ? 'Signing in...' : 'Sign In'}
        </button>

        ${this.error ? html`<p class="error">${this.error}</p>` : ''}
        ${this.success ? html`<p class="success">${this.success}</p>` : ''}
      </form>
    `;
  }
}

customElements.define('login-form', LoginForm);
