// PocketBase client service for managing card data
import PocketBase from 'pocketbase';
import { getConfig } from '../config.js';
import { getAuthHeaders, isLoggedIn, getCurrentUser } from './auth.js';

let client = null;
const ADMIN_OPERATIONS = ['createCard', 'publishCard']; // Operations that require admin auth

// Initialize the PocketBase client
export function initPocketBase() {
  const config = getConfig();
  const pocketbaseUrl = config.POCKETBASE_URL || 'https://db.stevebullis.com';

  client = new PocketBase(pocketbaseUrl);
  return client;
}

// Get the current PocketBase client instance
export function getPocketBase() {
  if (!client) {
    return initPocketBase();
  }
  return client;
}

// Use proxy with admin auth for operations that need elevated privileges
async function useAdmin(operation, callback) {
  console.log(`Using admin auth for operation: ${operation}`);

  try {
    // Call the server-side proxy endpoint that uses admin auth
    const response = await fetch(`/api/pocketbase/admin/${operation}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getAuthHeaders(), // Still include user auth headers for logging/tracking
      },
      body: JSON.stringify({
        args: Array.from(arguments).slice(1), // Pass all arguments except operation
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error(`Admin operation ${operation} failed:`, errorData);
      throw new Error(errorData.message || `Failed to execute ${operation} operation`);
    }

    return await response.json();
  } catch (error) {
    console.error(`Error during admin operation ${operation}:`, error);
    throw error;
  }
}

// Card-related functions
export async function createCard(templateId, cardData, recipientEmail = null) {
  console.log('Creating card with template ID:', templateId);

  // Prepare the card data
  const data = {
    template: templateId,
    data: cardData,
    published: false,
    recipient_email: recipientEmail,
    access_count: 0,
  };

  // Check if user is logged in
  if (isLoggedIn()) {
    const user = getCurrentUser();
    data.creator = user.id; // Set the actual user ID
    console.log('User is logged in, setting creator to:', user.id);
  } else {
    console.log('User is not logged in, card will be created anonymously');
  }

  try {
    // First try directly using the client with user auth
    try {
      const pb = getPocketBase();
      console.log('Attempting to create card with user authentication');

      // Get auth headers
      const headers = getAuthHeaders();
      console.log('Auth headers for card creation:', headers);

      // Create the card in PocketBase
      const record = await pb.collection('thankyoucard_cards').create(data, {
        headers,
      });

      console.log('Card created successfully with user auth:', record.id);

      // Generate a unique URL for the card
      const cardUrl = `/view/${record.id}`;

      // Try to update the URL but don't let it block success
      try {
        // Update the card with the URL
        await pb.collection('thankyoucard_cards').update(
          record.id,
          {
            url: cardUrl,
          },
          {
            headers,
          },
        );
        console.log('Card URL updated successfully');
      } catch (updateError) {
        // Log the error but continue with the operation
        console.warn('Could not update card URL, but card was created:', updateError.message);
      }

      // Return success with URL even if update failed
      return {
        id: record.id,
        url: cardUrl,
        ...record,
      };
    } catch (userAuthError) {
      // If that fails, try using the server-side proxy with admin auth
      console.error(
        'Failed to create card with user auth, trying admin auth:',
        userAuthError.message,
      );

      // Use server-side proxy with admin auth
      return await fetch('/.netlify/functions/card-create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders(), // Include user headers for tracking
        },
        body: JSON.stringify({
          templateId,
          cardData,
          recipientEmail,
          userId: isLoggedIn() ? getCurrentUser().id : null,
        }),
      }).then(async (response) => {
        const responseData = await response.json();

        if (!response.ok) {
          // Check if we got a partial success (card created but URL update failed)
          if (responseData.id) {
            console.log('Card was created but with errors:', responseData.message);
            // Return the card anyway since we have the ID
            return {
              id: responseData.id,
              url: `/view/${responseData.id}`,
              ...responseData,
            };
          }

          throw new Error(responseData.message || 'Failed to create card');
        }

        return responseData;
      });
    }
  } catch (error) {
    console.error('Error creating card:', error);
    throw error;
  }
}

// Get a card by ID
export async function getCardById(cardId) {
  const pb = getPocketBase();

  try {
    console.log(`Fetching card with ID: ${cardId}`);

    // Add auth headers if available
    const headers = getAuthHeaders();

    try {
      // First try to get the card using user authentication if available
      const record = await pb.collection('thankyoucard_cards').getOne(cardId, {
        headers,
      });

      console.log('Card fetched successfully with user auth');

      // Increment the access count
      await pb.collection('thankyoucard_cards').update(
        cardId,
        {
          access_count: (record.access_count || 0) + 1,
        },
        {
          headers,
        },
      );

      return record;
    } catch (authError) {
      // If authentication failed, the card might be public
      // or we might need to use admin auth
      console.log('Failed to fetch card with user auth, trying as public:', authError.message);

      // Try to fetch it as a public resource (no auth)
      try {
        const record = await pb.collection('thankyoucard_cards').getOne(cardId);

        console.log('Card fetched successfully as public resource');

        // Increment the access count
        try {
          await pb.collection('thankyoucard_cards').update(cardId, {
            access_count: (record.access_count || 0) + 1,
          });
        } catch (updateError) {
          // We can still return the card even if updating access count fails
          console.warn('Could not update access count:', updateError.message);
        }

        return record;
      } catch (publicError) {
        console.error(
          'Card not accessible via public or user auth, trying admin auth:',
          publicError.message,
        );

        // If that fails, try using admin auth via a server function
        try {
          const response = await fetch(`/.netlify/functions/card-view/${cardId}`, {
            method: 'GET',
            headers: getAuthHeaders(),
          });

          if (!response.ok) {
            const errorData = await response.json();
            console.error('Admin auth failed too:', errorData);
            return { valid: false, reason: 'This card could not be found.' };
          }

          const data = await response.json();
          if (!data.success || !data.card) {
            return { valid: false, reason: 'This card could not be found.' };
          }

          const card = data.card;
          console.log('Card fetched with admin authentication');
        } catch (adminError) {
          console.error('All access methods failed:', adminError);
          return { valid: false, reason: 'This card could not be found.' };
        }
      }
    }
  } catch (error) {
    console.error('Error fetching card:', error);
    throw error;
  }
}

// Publish a card (make it viewable via shared link)
export async function publishCard(cardId, expiresAt = null, accessCode = null) {
  const pb = getPocketBase();

  try {
    console.log(`Publishing card with ID: ${cardId}`);

    const data = {
      published: true,
    };

    // Handle expiresAt properly
    if (expiresAt) {
      // Ensure it's a valid Date object
      if (expiresAt instanceof Date && !isNaN(expiresAt.getTime())) {
        data.expires_at = expiresAt.toISOString();
        console.log(`Setting card expiration to: ${data.expires_at}`);
      } else {
        console.warn('Invalid expiration date provided, ignoring:', expiresAt);
      }
    }

    // Handle accessCode properly
    if (accessCode) {
      const trimmedCode = accessCode.trim();
      if (trimmedCode) {
        data.access_code = trimmedCode;
        console.log('Setting card access code');
      } else {
        console.warn('Empty access code provided, ignoring');
      }
    }

    // First try with user authentication
    try {
      console.log('Attempting to publish card with user authentication');
      // Add auth headers if available
      const headers = getAuthHeaders();

      // Update the card with publish flag
      const record = await pb.collection('thankyoucard_cards').update(cardId, data, {
        headers,
      });

      console.log('Card published successfully with user auth');
      return record;
    } catch (userAuthError) {
      // If that fails, try using admin auth via a server function
      console.error(
        'Failed to publish card with user auth, trying admin auth:',
        userAuthError.message,
      );

      // Create an admin function for publishCard similar to card-create
      return await fetch('/.netlify/functions/card-publish', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders(), // Include user headers for tracking
        },
        body: JSON.stringify({
          cardId,
          expiresAt:
            expiresAt instanceof Date && !isNaN(expiresAt.getTime())
              ? expiresAt.toISOString()
              : null,
          accessCode: accessCode ? accessCode.trim() : null,
        }),
      }).then(async (response) => {
        const responseData = await response.json();

        if (!response.ok) {
          throw new Error(responseData.message || 'Failed to publish card');
        }

        return responseData;
      });
    }
  } catch (error) {
    console.error('Error publishing card:', error);
    throw error;
  }
}

// Validate card access (check expiration and access code)
export async function validateCardAccess(cardId, accessCode = null) {
  try {
    console.log(
      `PocketBaseClient: Validating access to card ID: ${cardId}, accessCode provided: ${accessCode ? 'Yes' : 'No'}`,
    );

    // Add auth headers if available
    const headers = getAuthHeaders();

    let card;
    let accessMethod = '';

    // Try all available methods to fetch the card and handle errors appropriately
    const errors = {};

    // Use only Netlify functions for card access - avoid direct PocketBase calls
    console.log('PocketBaseClient: Using Netlify functions to securely access the card');

    // Try multiple function endpoints
    const endpoints = [
      `/api/card-view/${cardId}`,
      `/.netlify/functions/card-view/${cardId}`,
      `/api/debug/card-view/${cardId}`,
    ];

    let success = false;

    for (const endpoint of endpoints) {
      try {
        console.log(`PocketBaseClient: Trying endpoint: ${endpoint}`);

        const response = await fetch(endpoint, {
          method: 'GET',
          headers,
        });

        if (response.ok) {
          const data = await response.json();

          if (data.success && data.card) {
            card = data.card;
            console.log(`PocketBaseClient: Card fetched successfully via ${endpoint}`);
            accessMethod = 'netlify_function';
            success = true;
            break;
          } else {
            console.warn(
              `PocketBaseClient: Endpoint ${endpoint} returned success=false or missing card data`,
            );
            errors[endpoint] = 'Invalid response format';
          }
        } else {
          const errorText = await response.text();
          console.error(
            `PocketBaseClient: Endpoint ${endpoint} failed:`,
            response.status,
            errorText,
          );
          errors[endpoint] = `HTTP ${response.status}: ${errorText}`;
        }
      } catch (endpointError) {
        console.error(`PocketBaseClient: Error with endpoint ${endpoint}:`, endpointError);
        errors[endpoint] = endpointError.message;
      }
    }

    // If we couldn't get the card from any endpoint
    if (!card) {
      console.error('PocketBaseClient: All endpoints failed to retrieve the card');
      return {
        valid: false,
        reason: 'This card could not be found.',
        errors,
      };
    }

    console.log(`PocketBaseClient: Successfully retrieved card using method: ${accessMethod}`);

    // Check if the card is published
    if (!card.published) {
      return { valid: false, reason: 'This card is not available for viewing.' };
    }

    // Check if the card is expired
    if (card.expires_at && new Date(card.expires_at) < new Date()) {
      return { valid: false, reason: 'This card has expired.' };
    }

    // Check if the card requires an access code
    if (card.access_code && card.access_code.trim() !== '') {
      const cardAccessCode = card.access_code.trim();
      const providedAccessCode = accessCode ? accessCode.trim() : '';

      console.log(`PocketBaseClient: Card has access code: ${cardAccessCode ? 'YES' : 'NO'}`);
      console.log(`PocketBaseClient: Access code provided: ${providedAccessCode ? 'YES' : 'NO'}`);

      // If no access code was provided but one is required
      if (!providedAccessCode) {
        return { valid: false, requireCode: true, reason: 'This card requires an access code.' };
      }

      // If an access code was provided but it's incorrect
      if (providedAccessCode !== cardAccessCode) {
        return {
          valid: false,
          requireCode: true,
          reason: 'Incorrect access code. Please try again.',
        };
      }

      console.log('PocketBaseClient: Access code validated successfully');
    }

    // Card is valid and accessible
    return {
      valid: true,
      card,
      accessMethod,
    };
  } catch (error) {
    console.error('PocketBaseClient: Error validating card access:', error);
    throw error;
  }
}
