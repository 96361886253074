// 404 Not Found page
import '../components/AppLayout/AppLayout.js';

export function loadNotFoundPage() {
  const app = document.getElementById('app');
  if (!app) {
    return;
  }

  app.innerHTML = `
    <app-layout>
      <div class="not-found-container">
        <div class="not-found-content">
          <div class="error-code">404</div>
          <h1>Page Not Found</h1>
          <p class="message">The page you're looking for doesn't exist or has been moved.</p>
          
          <div class="actions">
            <a href="#/" class="btn-primary">Go to Homepage</a>
            <a href="javascript:history.back()" class="btn-secondary">Go Back</a>
          </div>
        </div>
        
        <!-- Background decoration -->
        <div class="bg-circle bg-circle-1"></div>
        <div class="bg-circle bg-circle-2"></div>
        <div class="decoration-line line-1"></div>
        <div class="decoration-line line-2"></div>
        <div class="decoration-line line-3"></div>
      </div>
    </app-layout>
  `;

  // Add some page-specific styles
  const style = document.createElement('style');
  style.textContent = `
    .not-found-container {
      min-height: calc(100vh - var(--header-height) - 100px);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--spacing-3xl) var(--spacing-md);
      text-align: center;
      position: relative;
      overflow: hidden;
    }
    
    .bg-circle {
      position: absolute;
      border-radius: 50%;
      z-index: 0;
      opacity: 0.6;
    }
    
    .bg-circle-1 {
      width: 600px;
      height: 600px;
      top: -150px;
      right: -150px;
      background: radial-gradient(circle, rgba(79, 70, 229, 0.08) 0%, rgba(79, 70, 229, 0) 70%);
      animation: float 15s ease-in-out infinite;
    }
    
    .bg-circle-2 {
      width: 400px;
      height: 400px;
      bottom: -100px;
      left: -100px;
      background: radial-gradient(circle, rgba(16, 185, 129, 0.08) 0%, rgba(16, 185, 129, 0) 70%);
      animation: float 20s ease-in-out infinite reverse;
    }
    
    .decoration-line {
      position: absolute;
      background: linear-gradient(90deg, rgba(79, 70, 229, 0.1), rgba(79, 70, 229, 0));
      height: 2px;
      border-radius: var(--radius-full);
      z-index: 0;
    }
    
    .line-1 {
      width: 300px;
      top: 20%;
      right: 10%;
      transform: rotate(-30deg);
    }
    
    .line-2 {
      width: 200px;
      bottom: 30%;
      left: 5%;
      transform: rotate(20deg);
    }
    
    .line-3 {
      width: 150px;
      bottom: 20%;
      right: 15%;
      transform: rotate(-10deg);
    }
    
    .not-found-content {
      max-width: 600px;
      position: relative;
      z-index: 1;
      animation: fadeIn 0.8s ease-out;
    }
    
    .error-code {
      font-size: 10rem;
      font-weight: var(--font-bold);
      line-height: 1;
      margin-bottom: var(--spacing-md);
      background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-dark) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      opacity: 0.8;
      text-shadow: 0 10px 20px rgba(79, 70, 229, 0.1);
      animation: fadeIn 0.8s ease-out, float 6s ease-in-out infinite;
    }
    
    .not-found-content h1 {
      font-family: var(--font-display);
      font-size: var(--text-4xl);
      font-weight: var(--font-bold);
      margin-bottom: var(--spacing-md);
      color: var(--color-gray-900);
      animation: slideUp 0.8s ease-out;
    }
    
    .message {
      font-size: var(--text-xl);
      color: var(--color-gray-500);
      margin-bottom: var(--spacing-2xl);
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
      animation: slideUp 0.8s ease-out 0.1s backwards;
    }
    
    .actions {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-md);
      max-width: 300px;
      margin: 0 auto;
      animation: slideUp 0.8s ease-out 0.2s backwards;
    }
    
    .btn-primary {
      display: block;
      padding: var(--spacing-md) var(--spacing-xl);
      background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-dark) 100%);
      color: white;
      border-radius: var(--radius-full);
      text-decoration: none;
      font-weight: var(--font-medium);
      transition: all var(--transition-normal);
      box-shadow: var(--shadow-md);
      position: relative;
      overflow: hidden;
    }
    
    .btn-primary::after {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,0.2), rgba(255,255,255,0));
      transition: all 0.8s ease;
      z-index: 1;
    }
    
    .btn-primary:hover {
      background: linear-gradient(135deg, var(--color-primary-dark) 0%, var(--color-primary) 100%);
      transform: translateY(-3px);
      box-shadow: var(--shadow-lg);
      color: white;
    }
    
    .btn-primary:hover::after {
      left: 100%;
    }
    
    .btn-secondary {
      display: block;
      padding: var(--spacing-md) var(--spacing-xl);
      background: var(--color-gray-100);
      color: var(--color-gray-700);
      border-radius: var(--radius-full);
      text-decoration: none;
      font-weight: var(--font-medium);
      transition: all var(--transition-normal);
      border: 1px solid var(--color-gray-200);
    }
    
    .btn-secondary:hover {
      background: var(--color-gray-200);
      transform: translateY(-3px);
      box-shadow: var(--shadow-md);
    }
    
    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }
    
    @keyframes slideUp {
      from { transform: translateY(20px); opacity: 0; }
      to { transform: translateY(0); opacity: 1; }
    }
    
    @keyframes float {
      0%, 100% { transform: translateY(0); }
      50% { transform: translateY(-10px); }
    }
    
    @media (max-width: 768px) {
      .error-code {
        font-size: 8rem;
      }
      
      .not-found-content h1 {
        font-size: var(--text-3xl);
      }
      
      .message {
        font-size: var(--text-lg);
      }
      
      .decoration-line {
        display: none;
      }
    }
  `;
  document.head.appendChild(style);
}
