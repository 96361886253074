// Example web component: a simple custom button

import './CustomButton.css';

class CustomButton extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
    const label = this.getAttribute('label') || 'Default Label';
    this.shadowRoot.innerHTML = `
      <button class="custom-button">${label}</button>
    `;
  }
}

customElements.define('custom-button', CustomButton);
