/**
 * Helper to force browsers to refresh their cache of meta tags
 */

export function setupForceRefresh() {
  // Only run on page load
  if (typeof window !== 'undefined' && window.document) {
    console.log('Setting up force refresh for meta tags');

    // Add a timestamp to force meta tag refresh
    const timestamp = new Date().getTime();

    // Find all meta tags with og: or twitter: properties
    const socialTags = document.querySelectorAll(
      'meta[property^="og:"], meta[property^="twitter:"]',
    );

    // Update each tag with a unique timestamp if it refers to an image
    socialTags.forEach((tag) => {
      const property = tag.getAttribute('property');
      if (property && (property.includes('image') || property.includes('url'))) {
        const originalContent = tag.getAttribute('content');

        // Only add timestamp to absolute URLs to our domain
        if (originalContent && originalContent.includes('sendathankyoucard.com')) {
          const separator = originalContent.includes('?') ? '&' : '?';
          const newContent = `${originalContent}${separator}_t=${timestamp}`;
          tag.setAttribute('content', newContent);
          console.log(`Updated ${property} with timestamp:`, newContent);
        }
      }
    });

    // Also force-refresh the page title with a unique ID
    const pageTitle = document.title;
    document.title = pageTitle;

    console.log('Meta tag refresh complete');
  }
}
