import { html, css, LitElement } from 'lit';
import { isLoggedIn, getCurrentUser, login } from '../services/auth.js';

export class SignupForm extends LitElement {
  static styles = css`
    .form-group {
      margin-bottom: var(--spacing-md);
    }

    label {
      display: block;
      margin-bottom: var(--spacing-xs);
      color: var(--color-gray-700);
      font-weight: var(--font-medium);
    }

    input {
      width: 100%;
      padding: var(--spacing-md);
      border: 1px solid var(--color-gray-200);
      border-radius: var(--radius-md);
      font-size: var(--text-base);
      transition: all var(--transition-normal);
    }

    input:focus {
      outline: none;
      border-color: var(--color-primary);
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    }

    .btn-primary {
      width: 100%;
      padding: var(--spacing-md);
      background: var(--color-primary);
      color: var(--color-white);
      border: none;
      border-radius: var(--radius-md);
      font-weight: var(--font-medium);
      cursor: pointer;
      transition: all var(--transition-normal);
    }

    .btn-primary:hover {
      transform: translateY(-2px);
      box-shadow: var(--shadow-lg);
    }

    .btn-primary:disabled {
      background: var(--color-gray-400);
      cursor: not-allowed;
      transform: none;
      box-shadow: none;
    }

    .btn-secondary {
      width: 100%;
      padding: var(--spacing-md);
      background: var(--color-gray-300);
      color: var(--color-gray-900);
      border: none;
      border-radius: var(--radius-md);
      font-weight: var(--font-medium);
      cursor: pointer;
      transition: all var(--transition-normal);
      margin-top: var(--spacing-md);
    }

    .btn-secondary:hover {
      background: var(--color-gray-400);
    }

    .error {
      color: var(--color-error, #ff0000);
      margin-top: var(--spacing-sm);
      font-size: var(--text-sm);
    }

    .success {
      color: var(--color-success, #00aa00);
      margin-top: var(--spacing-sm);
      font-size: var(--text-sm);
    }

    .user-info {
      text-align: center;
      padding: var(--spacing-lg);
      background-color: var(--color-gray-100);
      border-radius: var(--radius-md);
    }

    .user-email {
      font-weight: var(--font-bold);
      margin-bottom: var(--spacing-sm);
    }
  `;

  static properties = {
    error: { type: String },
    success: { type: String },
    loading: { type: Boolean },
    loggedIn: { type: Boolean },
    returnTo: { type: String, attribute: 'data-return-to' },
  };

  constructor() {
    super();
    this.error = '';
    this.success = '';
    this.loading = false;
    this.loggedIn = isLoggedIn();
    this.returnTo = '';

    // Update logged in state if auth changes
    this._unsubscribe = null;
  }

  connectedCallback() {
    super.connectedCallback();

    // Listen for auth changes to update UI
    this._onAuthChange = () => {
      this.loggedIn = isLoggedIn();
    };
    document.addEventListener('authchange', this._onAuthChange);

    // Check if a returnTo parameter was provided from the parent
    if (this.hasAttribute('data-return-to') && this.getAttribute('data-return-to')) {
      console.log(
        'SignupForm: Using returnTo from attribute:',
        this.getAttribute('data-return-to'),
      );
      this.returnTo = this.getAttribute('data-return-to');
    } else {
      // Check URL for returnTo parameter as a fallback
      const hash = window.location.hash.slice(1);
      if (hash.includes('?')) {
        const searchParams = new URLSearchParams(hash.substring(hash.indexOf('?')));
        const returnTo = searchParams.get('returnTo');
        if (returnTo) {
          console.log('SignupForm: Found returnTo parameter in URL:', returnTo);
          this.returnTo = returnTo;
        }
      }
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    // Clean up listener
    document.removeEventListener('authchange', this._onAuthChange);
  }

  async handleSubmit(e) {
    e.preventDefault();
    // If already logged in, don't allow signup
    if (this.loggedIn) {
      this.error = 'You are already logged in. Please log out first to create a new account.';
      return;
    }

    this.error = '';
    this.success = '';
    this.loading = true;

    const form = e.target;
    const email = form.email.value;
    const name = form.name.value;
    const password = form.password.value;
    const passwordConfirm = form.passwordConfirm.value;

    // Client-side validation
    if (!email || !name || !password || !passwordConfirm) {
      this.error = 'All fields are required';
      this.loading = false;
      return;
    }

    if (password !== passwordConfirm) {
      this.error = 'Passwords do not match';
      this.loading = false;
      return;
    }

    if (password.length < 8) {
      this.error = 'Password must be at least 8 characters long';
      this.loading = false;
      return;
    }

    try {
      console.log('Submitting signup form...');

      // Create the request body
      const requestData = {
        email,
        name,
        password,
        passwordConfirm,
      };

      console.log('Request data:', JSON.stringify(requestData));

      // Call the Netlify function to create a new user
      // Use direct path to netlify function to avoid routing conflicts
      const response = await fetch('/.netlify/functions/user-create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      console.log('Signup response status:', response.status);

      let responseText = '';
      try {
        // Get response as text first
        responseText = await response.text();
        console.log('Response text:', responseText);

        // Try to parse as JSON
        let data = {};
        try {
          if (responseText && responseText.trim()) {
            data = JSON.parse(responseText);
          } else {
            throw new Error('Empty response');
          }
        } catch (parseError) {
          console.error('Error parsing JSON response:', parseError);
          throw new Error(`Server returned invalid response: ${responseText.substring(0, 100)}`);
        }

        if (!response.ok || !data.success) {
          throw new Error(data.message || 'Failed to create account');
        }

        // Success - account created
        this.success = 'Account created successfully! Logging you in...';

        // Handle auto-login
        try {
          if (data.token) {
            // If the signup response includes a token, we can use it directly without a separate login call
            console.log('Using token from signup response for auto-login');

            // Create the user object
            const user = {
              id: data.userId,
              username: email,
              email,
              token: data.token,
              tokenExpiry: data.tokenExpiry || Date.now() + 30 * 24 * 60 * 60 * 1000,
            };

            // Save to localStorage
            localStorage.setItem('auth_user', JSON.stringify(user));

            // Dispatch auth change event
            document.dispatchEvent(
              new CustomEvent('authchange', {
                bubbles: true,
                composed: true,
                detail: { isLoggedIn: true, user },
              }),
            );

            this.loggedIn = true;

            // Clear the form
            form.reset();

            // Set a short delay for user to see success message, then redirect
            setTimeout(() => {
              // Navigate to the returnTo page if one was provided, otherwise go to home
              if (this.returnTo) {
                window.location.hash = `#/${this.returnTo}`;
              } else {
                // Navigate to home page after successful login
                window.location.hash = '/';
              }
            }, 1000);
          } else {
            // If no token was provided, fall back to manual login
            console.log('No token in signup response, using manual login');
            await login(email, password);
            console.log('Auto-login successful after signup');

            // Clear the form
            form.reset();

            // Set a short delay for user to see success message, then redirect
            setTimeout(() => {
              // Navigate to the returnTo page if one was provided, otherwise go to home
              if (this.returnTo) {
                window.location.hash = `#/${this.returnTo}`;
              } else {
                // Navigate to home page after successful login
                window.location.hash = '/';
              }
            }, 1000);
          }
        } catch (loginError) {
          console.error('Auto-login failed:', loginError);
          this.success =
            'Account created successfully, but auto-login failed. Please login manually.';

          // Clear the form
          form.reset();

          // Redirect to login page after 2 seconds, preserving the returnTo if it exists
          setTimeout(() => {
            if (this.returnTo) {
              window.location.hash = `/login?returnTo=${this.returnTo}`;
            } else {
              window.location.hash = '/login';
            }
          }, 2000);
        }
      } catch (responseError) {
        console.error('Error processing response:', responseError);
        this.error = responseError.message || 'Error creating account';
      }
    } catch (error) {
      console.error('Error in form submission:', error);
      this.error = error.message || 'An unexpected error occurred';
    } finally {
      this.loading = false;
    }
  }

  goToLoginPage() {
    window.location.hash = '/login';
  }

  render() {
    if (this.loggedIn) {
      const user = getCurrentUser();
      return html`
        <div class="user-info">
          <p>You are already logged in as <strong>${user?.email || user?.username}</strong>.</p>
          <p>Please log out first if you want to create a new account.</p>
          <button @click=${this.goToLoginPage} class="btn-secondary">Go to Login Page</button>
        </div>
      `;
    }

    return html`
      <form @submit=${this.handleSubmit}>
        <div class="form-group">
          <label for="email">Email *</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Your email address"
            autocomplete="email"
            required
          />
        </div>

        <div class="form-group">
          <label for="name">Full Name *</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Your full name"
            autocomplete="name"
            required
          />
        </div>

        <div class="form-group">
          <label for="password">Password *</label>
          <input
            type="password"
            id="password"
            name="password"
            placeholder="Create a password (min. 8 characters)"
            autocomplete="new-password"
            required
            minlength="8"
          />
        </div>

        <div class="form-group">
          <label for="passwordConfirm">Confirm Password *</label>
          <input
            type="password"
            id="passwordConfirm"
            name="passwordConfirm"
            placeholder="Confirm your password"
            autocomplete="new-password"
            required
            minlength="8"
          />
        </div>

        <button type="submit" class="btn-primary" ?disabled=${this.loading}>
          ${this.loading ? 'Creating Account...' : 'Create Account'}
        </button>

        ${this.error ? html`<p class="error">${this.error}</p>` : ''}
        ${this.success ? html`<p class="success">${this.success}</p>` : ''}
      </form>
    `;
  }
}

customElements.define('signup-form', SignupForm);
