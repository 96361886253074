import '../components/AppLayout/AppLayout.js';

export function loadFeaturesPage() {
  const app = document.getElementById('app');
  if (!app) {
    return;
  }

  app.innerHTML = `
    <app-layout>
      <div class="features-container">
        <div class="features-header">
          <h1>Features</h1>
          <p class="subtitle">Everything you need to create meaningful thank you cards</p>
          
          <!-- Background decorative elements -->
          <div class="header-bg-circle header-bg-circle-1"></div>
          <div class="header-bg-circle header-bg-circle-2"></div>
        </div>

        <div class="feature-categories">
          <div class="category-section">
            <h2>Card Creation & Templates</h2>
            
            <div class="features-grid">
              <div class="feature-card">
                <div class="feature-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                </div>
                <h3>Beautiful Templates</h3>
                <p>Choose from our collection of professionally designed templates, organized by category: Formal & Professional, Casual & Personal, Modern & Minimal, and Decorative & Artistic.</p>
              </div>

              <div class="feature-card">
                <div class="feature-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                  </svg>
                </div>
                <h3>Rich Customization</h3>
                <p>Personalize your cards with custom messages, fonts, and colors. Adjust text placement, choose from different font families, and select color schemes that match your style.</p>
              </div>

              <div class="feature-card">
                <div class="feature-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
                  </svg>
                </div>
                <h3>Flexible Layouts</h3>
                <p>Choose from various card layouts including landscape and portrait orientations. Each template comes with optimized safe areas to ensure your content looks perfect.</p>
              </div>
            </div>
          </div>

          <div class="category-section">
            <h2>Delivery & Sharing</h2>
            
            <div class="features-grid">
              <div class="feature-card">
                <div class="feature-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                </div>
                <h3>Digital Delivery</h3>
                <p>Send your thank you cards instantly via email. Schedule deliveries for future dates to ensure your gratitude arrives at the perfect time.</p>
              </div>

              <div class="feature-card">
                <div class="feature-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                  </svg>
                </div>
                <h3>Physical Printing</h3>
                <p>Premium and Business plan subscribers can have their cards professionally printed on high-quality paper stock and shipped worldwide in elegant envelopes.</p>
              </div>

              <div class="feature-card">
                <div class="feature-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                  </svg>
                </div>
                <h3>Social Sharing</h3>
                <p>Share your digital thank you cards on social media platforms with easy sharing options. Include personalized links that recipients can access from any device.</p>
              </div>
            </div>
          </div>

          <div class="feature-highlights-section">
            <div class="highlights-content">
              <h2>Designed for Impact</h2>
              <p>Our platform is built to help you create thank you cards that leave a lasting impression.</p>
              
              <ul class="highlights-list">
                <li>
                  <span class="highlight-icon">✓</span>
                  <span class="highlight-text">Multiple template categories to suit every occasion</span>
                </li>
                <li>
                  <span class="highlight-icon">✓</span>
                  <span class="highlight-text">Customizable fonts, colors, and layouts</span>
                </li>
                <li>
                  <span class="highlight-icon">✓</span>
                  <span class="highlight-text">Digital and physical delivery options</span>
                </li>
                <li>
                  <span class="highlight-icon">✓</span>
                  <span class="highlight-text">Easy-to-use design interface</span>
                </li>
                <li>
                  <span class="highlight-icon">✓</span>
                  <span class="highlight-text">Professional-quality templates</span>
                </li>
                <li>
                  <span class="highlight-icon">✓</span>
                  <span class="highlight-text">Tiered plans to match your needs and budget</span>
                </li>
              </ul>
            </div>
            
            <div class="highlights-image">
              <div class="image-placeholder">
                <div class="card-mock card-mock-1">
                  <div class="card-stripe"></div>
                  <div class="card-content-preview">
                    <div class="card-preview-header"></div>
                    <div class="card-preview-body">
                      <div class="preview-line"></div>
                      <div class="preview-line"></div>
                    </div>
                  </div>
                </div>
                <div class="card-mock card-mock-2">
                  <div class="card-stripe"></div>
                  <div class="card-content-preview">
                    <div class="card-preview-header"></div>
                    <div class="card-preview-body">
                      <div class="preview-line"></div>
                      <div class="preview-line"></div>
                    </div>
                  </div>
                </div>
                <div class="card-mock card-mock-3">
                  <div class="card-stripe"></div>
                  <div class="card-content-preview">
                    <div class="card-preview-header"></div>
                    <div class="card-preview-body">
                      <div class="preview-line"></div>
                      <div class="preview-line"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="cta-section">
            <h2>Ready to Express Your Gratitude?</h2>
            <p>Create your first thank you card today</p>
            <div class="cta-buttons">
              <a href="#/create" class="btn-primary">Start Creating</a>
              <a href="#/pricing" class="btn-secondary">View Pricing</a>
            </div>
          </div>
        </div>
      </div>
    </app-layout>
  `;

  // Add styles
  const style = document.createElement('style');
  style.textContent = `
    .features-container {
      padding: var(--spacing-2xl) var(--spacing-md);
      max-width: var(--content-width-lg);
      margin: 0 auto;
    }

    .features-header {
      text-align: center;
      margin-bottom: var(--spacing-4xl);
      position: relative;
      padding: var(--spacing-3xl) var(--spacing-md) var(--spacing-xl);
      overflow: hidden;
    }

    .header-bg-circle {
      position: absolute;
      border-radius: 50%;
      z-index: -1;
    }

    .header-bg-circle-1 {
      width: 400px;
      height: 400px;
      top: -180px;
      right: -100px;
      background: radial-gradient(circle, rgba(79, 70, 229, 0.08) 0%, rgba(79, 70, 229, 0) 70%);
    }

    .header-bg-circle-2 {
      width: 300px;
      height: 300px;
      bottom: -100px;
      left: -80px;
      background: radial-gradient(circle, rgba(16, 185, 129, 0.08) 0%, rgba(16, 185, 129, 0) 70%);
    }

    .features-container h1 {
      font-family: var(--font-display);
      font-size: var(--text-5xl);
      font-weight: var(--font-bold);
      color: var(--color-gray-900);
      margin-bottom: var(--spacing-md);
      position: relative;
      display: inline-block;
      animation: fadeIn 0.8s ease-out, float 6s ease-in-out infinite;
    }

    .features-container h1::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 80px;
      height: 4px;
      background: linear-gradient(90deg, var(--color-primary-light), var(--color-primary));
      border-radius: var(--radius-full);
    }

    .subtitle {
      font-size: var(--text-xl);
      color: var(--color-gray-500);
      max-width: 600px;
      margin: 0 auto var(--spacing-xl);
      line-height: 1.6;
      animation: slideUp 0.8s ease-out;
    }

    .category-section {
      margin-bottom: var(--spacing-4xl);
      animation: fadeIn 0.8s ease-out;
    }

    .category-section h2 {
      font-family: var(--font-display);
      font-size: var(--text-3xl);
      font-weight: var(--font-semibold);
      color: var(--color-gray-900);
      margin-bottom: var(--spacing-xl);
      position: relative;
      display: inline-block;
    }

    .category-section h2::after {
      content: '';
      display: block;
      width: 60px;
      height: 3px;
      background: linear-gradient(90deg, var(--color-primary-light), var(--color-primary));
      margin-top: var(--spacing-xs);
      border-radius: var(--radius-full);
    }

    .features-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: var(--spacing-xl);
      margin-bottom: var(--spacing-2xl);
    }

    .feature-card {
      background: var(--color-white);
      border-radius: var(--radius-xl);
      padding: var(--spacing-xl);
      box-shadow: var(--shadow-md);
      transition: all var(--transition-normal);
      border: 1px solid var(--color-gray-100);
      height: 100%;
      display: flex;
      flex-direction: column;
      animation: fadeIn 0.6s ease-out backwards;
    }

    .feature-card:hover {
      transform: translateY(-8px);
      box-shadow: var(--shadow-lg);
      border-color: var(--color-gray-200);
    }

    .feature-icon {
      height: 48px;
      width: 48px;
      margin-bottom: var(--spacing-lg);
      color: var(--color-primary);
      transition: all var(--transition-normal);
    }

    .feature-icon svg {
      height: 100%;
      width: 100%;
    }

    .feature-card:hover .feature-icon {
      transform: scale(1.1);
      color: var(--color-primary-dark);
    }

    .feature-card h3 {
      font-size: var(--text-xl);
      font-weight: var(--font-semibold);
      color: var(--color-gray-900);
      margin-bottom: var(--spacing-sm);
      position: relative;
    }

    .feature-card h3::after {
      content: '';
      display: block;
      width: 40px;
      height: 2px;
      background: linear-gradient(90deg, var(--color-primary-light), var(--color-primary));
      margin-top: var(--spacing-xs);
      border-radius: var(--radius-full);
      transition: width var(--transition-normal);
    }

    .feature-card:hover h3::after {
      width: 60px;
    }

    .feature-card p {
      color: var(--color-gray-500);
      line-height: 1.6;
      font-size: var(--text-base);
    }

    .feature-highlights-section {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--spacing-2xl);
      margin: var(--spacing-4xl) 0;
      background: linear-gradient(to right, rgba(79, 70, 229, 0.03), rgba(79, 70, 229, 0.08));
      border-radius: var(--radius-xl);
      padding: var(--spacing-2xl);
      position: relative;
      overflow: hidden;
    }

    .highlights-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .highlights-content h2 {
      font-family: var(--font-display);
      font-size: var(--text-3xl);
      font-weight: var(--font-semibold);
      color: var(--color-gray-900);
      margin-bottom: var(--spacing-md);
    }

    .highlights-content p {
      color: var(--color-gray-500);
      margin-bottom: var(--spacing-xl);
      font-size: var(--text-lg);
      line-height: 1.6;
    }

    .highlights-list {
      list-style: none;
      padding: 0;
    }

    .highlights-list li {
      display: flex;
      align-items: center;
      margin-bottom: var(--spacing-md);
      font-size: var(--text-lg);
      color: var(--color-gray-700);
    }

    .highlight-icon {
      color: var(--color-primary);
      font-weight: var(--font-bold);
      margin-right: var(--spacing-md);
      background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-dark) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: var(--text-xl);
    }

    .highlights-image {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .image-placeholder {
      width: 100%;
      height: 400px;
      position: relative;
    }

    .card-mock {
      position: absolute;
      border-radius: var(--radius-lg);
      background: white;
      box-shadow: var(--shadow-lg);
      transition: all 0.4s ease;
      overflow: hidden;
      border: 1px solid rgba(var(--color-primary-rgb), 0.1);
    }
    
    .card-stripe {
      height: 6px;
      width: 100%;
      background: linear-gradient(90deg, rgba(79, 70, 229, 0.9) 0%, rgba(124, 58, 237, 0.9) 100%);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
    
    .card-content-preview {
      width: 100%;
      height: 100%;
      padding: 16px;
      padding-top: 22px; /* Additional space to account for the stripe */
    }
    
    .card-preview-header {
      height: 28px;
      background: rgba(var(--color-primary-rgb), 0.1);
      border-radius: var(--radius-sm);
      margin-bottom: 16px;
    }
    
    .card-preview-body {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    
    .preview-line {
      height: 10px;
      background: rgba(var(--color-gray-rgb), 0.15);
      border-radius: var(--radius-sm);
    }
    
    .preview-line:nth-child(2) {
      width: 70%;
    }

    .card-mock-1 {
      width: 250px;
      height: 180px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-5deg);
      z-index: 1;
      background: linear-gradient(135deg, #ffffff 0%, #f9fafb 100%);
    }

    .card-mock-2 {
      width: 250px;
      height: 180px;
      top: 40%;
      left: 40%;
      transform: translate(-50%, -50%) rotate(5deg);
      z-index: 2;
      background: linear-gradient(135deg, #ffffff 0%, #f3f4f6 100%);
    }

    .card-mock-3 {
      width: 250px;
      height: 180px;
      top: 60%;
      left: 60%;
      transform: translate(-50%, -50%) rotate(-8deg);
      z-index: 3;
      background: linear-gradient(135deg, #ffffff 0%, #f5f5f5 100%);
    }
    
    .card-mock:hover {
      transform: translate(-50%, -50%) rotate(0deg) !important;
      box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 10px 10px -5px rgba(0, 0, 0, 0.1);
      z-index: 10 !important;
    }

    .cta-section {
      text-align: center;
      background: linear-gradient(135deg, rgba(79, 70, 229, 0.05) 0%, rgba(16, 185, 129, 0.05) 100%);
      border-radius: var(--radius-xl);
      padding: var(--spacing-3xl) var(--spacing-2xl);
      margin-top: var(--spacing-4xl);
      position: relative;
      overflow: hidden;
    }

    .cta-section::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(circle at top right, rgba(79, 70, 229, 0.1), transparent 400px);
      z-index: 0;
    }

    .cta-section::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(circle at bottom left, rgba(16, 185, 129, 0.1), transparent 400px);
      z-index: 0;
    }

    .cta-section h2 {
      font-family: var(--font-display);
      font-size: var(--text-3xl);
      font-weight: var(--font-semibold);
      color: var(--color-gray-900);
      margin-bottom: var(--spacing-sm);
      position: relative;
      z-index: 1;
    }

    .cta-section p {
      color: var(--color-gray-500);
      margin-bottom: var(--spacing-xl);
      font-size: var(--text-lg);
      position: relative;
      z-index: 1;
    }

    .cta-buttons {
      display: flex;
      gap: var(--spacing-md);
      justify-content: center;
      position: relative;
      z-index: 1;
    }

    .btn-primary,
    .btn-secondary {
      padding: var(--spacing-md) var(--spacing-2xl);
      border-radius: var(--radius-full);
      font-weight: var(--font-medium);
      text-decoration: none;
      transition: all var(--transition-normal);
    }

    .btn-primary {
      background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-dark) 100%);
      color: var(--color-white);
      position: relative;
      overflow: hidden;
      box-shadow: var(--shadow-md);
    }

    .btn-primary::after {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,0.2), rgba(255,255,255,0));
      transition: all 0.8s ease;
      z-index: 1;
    }

    .btn-primary:hover {
      transform: translateY(-3px);
      box-shadow: var(--shadow-lg);
      background: linear-gradient(135deg, var(--color-primary-dark) 0%, var(--color-primary) 100%);
      color: white;
    }

    .btn-primary:hover::after {
      left: 100%;
    }

    .btn-secondary {
      background: var(--color-white);
      color: var(--color-gray-700);
      border: 1px solid var(--color-gray-200);
    }

    .btn-secondary:hover {
      background: var(--color-gray-50);
      transform: translateY(-3px);
      box-shadow: var(--shadow-md);
    }

    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }

    @keyframes slideUp {
      from { transform: translateY(20px); opacity: 0; }
      to { transform: translateY(0); opacity: 1; }
    }

    @keyframes float {
      0%, 100% { transform: translateY(0); }
      50% { transform: translateY(-10px); }
    }

    @media (max-width: 768px) {
      .features-container h1 {
        font-size: var(--text-4xl);
      }

      .features-grid {
        grid-template-columns: 1fr;
      }

      .cta-buttons {
        flex-direction: column;
      }

      .feature-highlights-section {
        grid-template-columns: 1fr;
      }

      .highlights-image {
        display: none;
      }

      .category-section h2 {
        font-size: var(--text-2xl);
      }

      .btn-primary, .btn-secondary {
        width: 100%;
      }
    }
  `;
  document.head.appendChild(style);
}
