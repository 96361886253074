import '../components/AppLayout/AppLayout.js';
import { getCardById, validateCardAccess } from '../services/PocketBaseClient.js';
import { TemplateRenderer } from '../services/TemplateRenderer.js';
import templates from '../config/templates.json';
import { updateCardMetadata } from '../utils/seo.js';

let cardData = null;
let templateRenderer = null;
let cardId = null;
let accessCodeRequired = false;

export async function loadViewCardPage() {
  console.log('ViewCardPage: Loading view card page...');
  const app = document.getElementById('app');
  if (!app) {
    console.error('ViewCardPage: App element not found in DOM');
    return;
  }

  // Extract card ID from the URL hash
  const hash = window.location.hash.slice(1);
  console.log('ViewCardPage: Processing hash:', hash);

  const parts = hash.split('/');

  // URL format should be "/view/cardId" - so the cardId is in parts[2]
  // parts[0] = "" (empty string before first slash)
  // parts[1] = "view"
  // parts[2] = actual card ID

  cardId = parts.length > 2 ? parts[2] : null;

  console.log('ViewCardPage: Extracted cardId:', cardId);

  if (!cardId) {
    console.error('ViewCardPage: Invalid card URL, missing card ID');
    renderErrorState(app, 'Invalid card URL. Please check the link and try again.');
    return;
  }

  console.log(`ViewCardPage: Setting up layout for card ID: ${cardId}`);

  // Set up the app layout
  app.innerHTML = `
    <app-layout>
      <div id="viewCardContainer" class="view-card-container">
        <div id="loadingCard" class="loading-card">
          <div class="loading-spinner"></div>
          <p>Loading your thank you card...</p>
        </div>
      </div>
    </app-layout>
  `;

  try {
    console.log('ViewCardPage: Initializing template renderer');
    // Initialize template renderer with config but don't try to load a specific template yet
    templateRenderer = new TemplateRenderer(templates);

    // No need to call initialize() here; wait until we have the card data and know which template to use

    // Add some basic styles
    addViewCardStyles();

    // Try to load the card
    console.log('ViewCardPage: Loading card data...');
    await loadCard();
  } catch (error) {
    console.error('ViewCardPage: Error initializing page:', error);
    const container = document.getElementById('viewCardContainer');
    if (container) {
      renderErrorState(container, 'An error occurred while setting up the page.');
    }
  }
}

async function loadCard(accessCode = null) {
  console.log(`ViewCardPage: In loadCard() for card ID: ${cardId}`);
  const container = document.getElementById('viewCardContainer');
  if (!container) {
    console.error('ViewCardPage: Container element not found');
    return;
  }

  try {
    // Log attempt details for debugging
    console.log(
      `ViewCardPage: Loading card with ID: ${cardId}${accessCode ? ', with access code' : ''}`,
    );
    console.log('ViewCardPage: Current location:', window.location.href);
    console.log('ViewCardPage: Protocol:', window.location.protocol);
    console.log('ViewCardPage: Hostname:', window.location.hostname);

    // Check if the card can be accessed
    console.log('ViewCardPage: Attempting card validation...');

    try {
      // Trim the access code if provided
      const trimmedAccessCode = accessCode ? accessCode.trim() : null;

      // First try the standard validateCardAccess method
      const validation = await validateCardAccess(cardId, trimmedAccessCode);
      console.log(
        'ViewCardPage: Card validation result via standard method:',
        validation.valid ? 'Valid' : 'Invalid',
        validation,
      );

      // Handle access validation
      if (!validation.valid) {
        if (validation.requireCode) {
          console.log('ViewCardPage: Access code required for card');
          renderAccessCodeForm(container, validation.reason || 'This card requires an access code');
          accessCodeRequired = true;
          return;
        } else {
          console.log('ViewCardPage: Card access denied:', validation.reason);

          // Try direct API call as a fallback
          console.log('ViewCardPage: Attempting direct API fallback...');
          await tryDirectApiAccess(container);
        }
      } else {
        // Card is valid, get full card data
        cardData = validation.card;
        console.log('ViewCardPage: Card data retrieved successfully through standard method');

        // Render the card
        renderCard(container);
      }
    } catch (validationError) {
      console.error('ViewCardPage: Error during card validation:', validationError);

      // Try direct API as fallback
      console.log('ViewCardPage: Attempting direct API fallback after validation error...');
      await tryDirectApiAccess(container);
    }
  } catch (error) {
    console.error('ViewCardPage: Error loading card:', error);
    renderErrorState(container, 'Unable to load the card. Please try again later.');
  }
}

async function tryDirectApiAccess(container) {
  console.log('ViewCardPage: Trying to access card via Netlify function:', cardId);

  try {
    // Try multiple Netlify function endpoints without attempting direct PocketBase access
    const endpoints = [
      `/.netlify/functions/card-view/${cardId}`,
      `/api/card-view/${cardId}`,
      `/api/debug/card-view/${cardId}`,
    ];

    let data = null;
    let success = false;

    // Try each endpoint in sequence until one works
    for (const endpoint of endpoints) {
      try {
        console.log(`ViewCardPage: Trying endpoint: ${endpoint}`);
        const response = await fetch(endpoint);

        if (response.ok) {
          data = await response.json();
          console.log(`ViewCardPage: Successfully retrieved data from ${endpoint}:`, data);
          success = true;
          break;
        } else {
          console.error(
            `ViewCardPage: Endpoint ${endpoint} error:`,
            response.status,
            response.statusText,
          );
        }
      } catch (endpointError) {
        console.error(`ViewCardPage: Error with endpoint ${endpoint}:`, endpointError);
      }
    }

    if (success && data && data.card) {
      // Use the card data from the function endpoint
      cardData = data.card;
      console.log('ViewCardPage: Card data retrieved successfully through Netlify function');
      renderCard(container);
      return;
    }

    // If all endpoints fail, show error
    console.error('ViewCardPage: All Netlify function endpoints failed');
    renderErrorState(container, 'Card not found or access denied.');
  } catch (error) {
    console.error('ViewCardPage: Error accessing card via Netlify functions:', error);
    renderErrorState(container, 'Unable to load the card. Please try again later.');
  }
}

function renderCard(container) {
  console.log('ViewCardPage: Rendering card with data:', cardData);

  if (!cardData) {
    console.error('ViewCardPage: No card data available for rendering');
    renderErrorState(container, 'Unable to load the card data.');
    return;
  }

  // Update metadata for social sharing
  updateCardMetadataForSharing();

  // Create a clean container with better styling for visibility
  container.innerHTML = `
    <div class="card-view-wrapper">
      <div id="cardContainer" class="card-container"></div>
      <div class="card-info">
        <p>This thank you card was created for you.</p>
        <div id="shareButtons" class="share-buttons">
          ${renderSocialShareButtons()}
        </div>
      </div>
    </div>
  `;

  const cardContainer = document.getElementById('cardContainer');
  if (!cardContainer) {
    console.error('ViewCardPage: Card container not found in DOM');
    renderErrorState(container, 'Error rendering card.');
    return;
  }

  // Check if the template exists for this card
  const templateId = cardData.template;
  console.log('ViewCardPage: Template ID from card data:', templateId);

  if (!templateId) {
    console.error('ViewCardPage: No template ID found in card data');
    renderErrorState(container, 'This card has an invalid template.');
    return;
  }

  const template = templates.templates.find((t) => t.id === templateId);

  if (!template) {
    console.error(`ViewCardPage: Template not found for ID: ${templateId}`);
    renderErrorState(container, 'This card template is no longer available.');
    return;
  }

  try {
    console.log('ViewCardPage: Rendering template:', templateId);

    // Format the card data for the renderer before initializing
    const formattedData = {};
    const customStyles = {};

    // Process the card.data which could be in different formats
    let dataToProcess = cardData.data;

    // If data is a string, try to parse it
    if (typeof dataToProcess === 'string' && dataToProcess.trim() !== '') {
      try {
        dataToProcess = JSON.parse(dataToProcess);
        console.log('ViewCardPage: Successfully parsed card data string into object');
      } catch (e) {
        console.error('ViewCardPage: Error parsing card data string:', e);
        // Continue with the string version
      }
    }

    // Check if we have elements directly in the card data (API format)
    if (dataToProcess && dataToProcess.elements && Array.isArray(dataToProcess.elements)) {
      console.log('ViewCardPage: Processing card data with elements array');
      // Convert elements array to key-value format
      dataToProcess.elements.forEach((element) => {
        if (element.key && element.value !== undefined) {
          formattedData[element.key] = element.value;

          // Store styling information for this element if available
          if (element.font || element.color) {
            customStyles[element.key] = {
              fontFamily: element.font,
              color: element.color,
            };
          }
        }
      });
    }
    // Otherwise, treat the entire data object as key-value pairs
    else if (dataToProcess && typeof dataToProcess === 'object') {
      console.log('ViewCardPage: Processing card data as object');
      Object.keys(dataToProcess).forEach((key) => {
        formattedData[key] = dataToProcess[key];
      });
    }

    console.log('ViewCardPage: Formatted data for rendering:', formattedData);
    console.log('ViewCardPage: Custom styles for rendering:', customStyles);

    // Set explicit dimensions for the card container to ensure visibility
    cardContainer.style.width = '100%';
    cardContainer.style.maxWidth = '600px';
    cardContainer.style.height = '400px';
    cardContainer.style.margin = '0 auto 20px auto';
    cardContainer.style.border = '1px solid #e0e0e0';
    cardContainer.style.boxShadow = '0 2px 10px rgba(0,0,0,0.1)';
    cardContainer.style.overflow = 'hidden';
    cardContainer.style.position = 'relative';
    cardContainer.style.backgroundColor = '#ffffff';

    // Initialize renderer with the template and explicit dimensions
    templateRenderer
      .initialize(templateId, cardContainer.offsetWidth, cardContainer.offsetHeight)
      .then(() => {
        // Apply custom styles from the card data if available
        if (Object.keys(customStyles).length > 0) {
          for (const [elementKey, style] of Object.entries(customStyles)) {
            if (style.fontFamily || style.color) {
              templateRenderer.updateStyle(elementKey, style);
            }
          }
        }

        // Render the card with the formatted data
        return templateRenderer.render(formattedData);
      })
      .then((canvas) => {
        // Make sure the canvas is properly styled for visibility
        canvas.style.width = '100%';
        canvas.style.height = '100%';
        canvas.style.display = 'block';

        // Clear any existing content and append the canvas
        cardContainer.innerHTML = '';
        cardContainer.appendChild(canvas);

        console.log('ViewCardPage: Card rendered successfully');
      })
      .catch((error) => {
        console.error('ViewCardPage: Error rendering card:', error);
        renderErrorState(container, 'Error rendering this card.');
      });
  } catch (error) {
    console.error('ViewCardPage: Error initializing template renderer:', error);
    renderErrorState(container, 'Error rendering this card.');
  }
}

// Function to handle updating metadata for card sharing
function updateCardMetadataForSharing() {
  if (!cardData || !cardId) {
    console.error('ViewCardPage: Unable to update metadata, missing cardData or cardId');
    return;
  }

  console.log('ViewCardPage: Updating metadata for card sharing');

  // Check if we can extract a card thumbnail image for better sharing
  let cardImageUrl = null;

  // Try multiple possible image sources

  // Option 1: Check if the card has a background image we can use
  if (cardData.background && typeof cardData.background === 'string') {
    if (cardData.background.startsWith('http')) {
      cardImageUrl = cardData.background;
      console.log('ViewCardPage: Using background image for card sharing:', cardImageUrl);
    } else if (cardData.background.startsWith('/')) {
      // Handle relative URLs - keep as root-relative
      cardImageUrl = cardData.background;
      console.log('ViewCardPage: Using relative background image for card sharing:', cardImageUrl);
    }
  }

  // Option 2: Check if the card has a cover image
  if (!cardImageUrl && cardData.coverImage && typeof cardData.coverImage === 'string') {
    if (cardData.coverImage.startsWith('http')) {
      cardImageUrl = cardData.coverImage;
      console.log('ViewCardPage: Using cover image for card sharing:', cardImageUrl);
    } else if (cardData.coverImage.startsWith('/')) {
      // Handle relative URLs - keep as root-relative
      cardImageUrl = cardData.coverImage;
      console.log('ViewCardPage: Using relative cover image for card sharing:', cardImageUrl);
    }
  }

  // Option 3: Look for images in the card content
  if (!cardImageUrl && cardData.content) {
    if (typeof cardData.content === 'object') {
      // Deep search for image elements in the content object
      const findImages = (obj) => {
        for (const key in obj) {
          const item = obj[key];
          if (item && typeof item === 'object') {
            // Check if this object has image properties
            if ((item.type === 'image' || item.type === 'photo') && item.src) {
              if (item.src.startsWith('http')) {
                return item.src;
              } else if (item.src.startsWith('/')) {
                // Keep root-relative URLs as they are
                return item.src;
              }
            }
            // Recursively search nested objects
            const nestedImage = findImages(item);
            if (nestedImage) {
              return nestedImage;
            }
          }
        }
        return null;
      };

      const contentImage = findImages(cardData.content);
      if (contentImage) {
        cardImageUrl = contentImage;
        console.log('ViewCardPage: Using content image for card sharing:', cardImageUrl);
      }
    }
  }

  // Option 4: Check if the card has a templateImage property
  if (!cardImageUrl && cardData.templateImage && typeof cardData.templateImage === 'string') {
    if (cardData.templateImage.startsWith('http')) {
      cardImageUrl = cardData.templateImage;
      console.log('ViewCardPage: Using template image for card sharing:', cardImageUrl);
    } else if (cardData.templateImage.startsWith('/')) {
      // Keep root-relative URLs as they are
      cardImageUrl = cardData.templateImage;
      console.log('ViewCardPage: Using relative template image for card sharing:', cardImageUrl);
    }
  }

  // Option 5: Check if there's a template ID and use a template preview
  if (!cardImageUrl && cardData.template) {
    const templateId = cardData.template;
    // Look for a template preview image
    const template = templates.templates.find((t) => t.id === templateId);
    if (template && template.previewImage) {
      if (template.previewImage.startsWith('http')) {
        cardImageUrl = template.previewImage;
      } else if (template.previewImage.startsWith('/')) {
        // Keep root-relative URLs as they are
        cardImageUrl = template.previewImage;
      } else {
        // Add / prefix to make it root-relative
        cardImageUrl = `/${template.previewImage}`;
      }
      console.log('ViewCardPage: Using template preview image for card sharing:', cardImageUrl);
    }
  }

  // Call the SEO utility function to update card metadata
  updateCardMetadata(cardData, cardId, cardImageUrl);
}

function renderAccessCodeForm(
  container,
  message = 'Please enter the access code to view this thank you card:',
) {
  container.innerHTML = `
    <div class="access-code-container">
      <h2>This card requires an access code</h2>
      <p>${message}</p>
      
      <div class="access-code-form">
        <input 
          type="text" 
          id="accessCodeInput" 
          placeholder="Enter access code"
          class="access-code-input"
        />
        <button id="submitAccessCode" class="access-code-button">View Card</button>
      </div>
      <div id="accessCodeError" class="access-code-error"></div>
    </div>
  `;

  // Add event listeners
  document.getElementById('submitAccessCode').addEventListener('click', submitAccessCode);

  // Also submit on Enter key press
  document.getElementById('accessCodeInput').addEventListener('keypress', (e) => {
    if (e.key === 'Enter') {
      submitAccessCode();
    }
  });
}

async function submitAccessCode() {
  const accessCode = document.getElementById('accessCodeInput').value;
  const errorElement = document.getElementById('accessCodeError');

  if (!accessCode.trim()) {
    errorElement.textContent = 'Please enter an access code';
    return;
  }

  errorElement.textContent = '';
  const container = document.getElementById('viewCardContainer');

  // Show loading state
  container.innerHTML = `
    <div class="loading-card">
      <div class="loading-spinner"></div>
      <p>Verifying access code...</p>
    </div>
  `;

  // Try to load the card with the access code
  await loadCard(accessCode);
}

function renderErrorState(container, message) {
  container.innerHTML = `
    <div class="error-container">
      <svg class="error-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
        <circle cx="12" cy="12" r="10"></circle>
        <path d="M12 8v4m0 4h.01"></path>
      </svg>
      <h2>Unable to view card</h2>
      <p>${message}</p>
      <a href="#/" class="primary-button">Return to Home</a>
    </div>
  `;
}

function renderSocialShareButtons() {
  const shareUrl = window.location.href;
  const shareText = 'I received a thank you card! Check it out:';

  return `
    <div class="social-share">
      <p>Share this card:</p>
      <div class="share-buttons">
        <button onclick="window.open('https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}', '_blank')" class="share-button facebook">
          <svg viewBox="0 0 24 24" width="24" height="24">
            <path fill="currentColor" d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
          </svg>
        </button>
        <button onclick="window.open('https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareUrl)}', '_blank')" class="share-button twitter">
          <svg viewBox="0 0 24 24" width="24" height="24">
            <path fill="currentColor" d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723 9.99 9.99 0 01-3.127 1.195 4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/>
          </svg>
        </button>
        <button id="copyLink" class="share-button copy">
          <svg viewBox="0 0 24 24" width="24" height="24">
            <path fill="currentColor" d="M16 1H4a2 2 0 00-2 2v14h2V3h12V1zm3 4H8a2 2 0 00-2 2v14a2 2 0 002 2h11a2 2 0 002-2V7a2 2 0 00-2-2zm0 16H8V7h11v14z"/>
          </svg>
        </button>
      </div>
    </div>
  `;
}

function addViewCardStyles() {
  // Add styles for the card view page
  const styleEl = document.createElement('style');
  styleEl.textContent = `
    .view-card-container {
      max-width: 800px;
      margin: 20px auto;
      padding: 20px;
      background-color: #f8f9fa;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    
    .card-view-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .card-container {
      width: 100%;
      max-width: 600px;
      height: 400px;
      margin: 0 auto 20px auto;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      box-shadow: 0 2px 10px rgba(0,0,0,0.1);
      overflow: hidden;
      position: relative;
      background-color: #ffffff;
    }
    
    .card-container canvas {
      width: 100%;
      height: 100%;
      display: block;
    }
    
    .card-info {
      text-align: center;
      margin-top: 20px;
      width: 100%;
    }
    
    .card-info p {
      margin-bottom: 15px;
      color: #333;
    }
    
    .share-buttons {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 15px;
    }
    
    .share-button {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      cursor: pointer;
      transition: background-color 0.3s;
    }
    
    .share-button.facebook {
      background-color: #3b5998;
    }
    
    .share-button.twitter {
      background-color: #1da1f2;
    }
    
    .share-button.copy {
      background-color: #6c757d;
    }
    
    .loading-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 300px;
    }
    
    .loading-spinner {
      width: 40px;
      height: 40px;
      border: 4px solid rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      border-top-color: var(--color-primary, #4a6cf7);
      animation: spin 1s linear infinite;
      margin-bottom: 15px;
    }
    
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
    
    .error-container {
      text-align: center;
      padding: 40px 20px;
      max-width: 500px;
      margin: 0 auto;
    }
    
    .error-icon {
      width: 64px;
      height: 64px;
      color: #dc3545;
      margin-bottom: 20px;
    }
    
    .error-container h2 {
      margin-bottom: 10px;
      color: #333;
    }
    
    .error-container p {
      margin-bottom: 20px;
      color: #555;
    }
    
    .primary-button {
      display: inline-block;
      padding: 10px 20px;
      background-color: var(--color-primary, #4a6cf7);
      color: white;
      border: none;
      border-radius: 4px;
      text-decoration: none;
      font-weight: 600;
      cursor: pointer;
      transition: background-color 0.3s;
    }
    
    .primary-button:hover {
      background-color: var(--color-primary-dark, #3a57c7);
    }
    
    .access-code-container {
      max-width: 500px;
      margin: 40px auto;
      padding: 20px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      text-align: center;
    }
    
    .access-code-form {
      display: flex;
      margin: 20px 0;
    }
    
    .access-code-input {
      flex: 1;
      padding: 10px 15px;
      border: 1px solid #ddd;
      border-radius: 4px 0 0 4px;
      font-size: 16px;
    }
    
    .access-code-button {
      background-color: var(--color-primary, #4f46e5);
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 0 4px 4px 0;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
    }
    
    .access-code-button:hover {
      background-color: var(--color-primary-dark, #4338ca);
    }
    
    .access-code-error {
      color: #dc3545;
      margin-top: 10px;
      font-size: 14px;
    }
  `;
  document.head.appendChild(styleEl);
}

// Initialize copy link function after page load
document.addEventListener('click', (e) => {
  if (e.target.closest('#copyLink')) {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert('Link copied to clipboard!');
      })
      .catch((err) => {
        console.error('Could not copy text: ', err);
      });
  }
});
