// The __APP_VERSION__ variable is globally defined in vite.config.js
/* global __APP_VERSION__ */

import { isLoggedIn, getCurrentUser, logout, onAuthChange } from '../../services/auth.js';

class AppLayout extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });

    // Initialize with logged-in state
    this.isUserLoggedIn = isLoggedIn();
    this.currentUser = getCurrentUser();

    console.log(
      'AppLayout initialized with auth state:',
      this.isUserLoggedIn ? 'LOGGED IN' : 'LOGGED OUT',
      this.currentUser ? `User: ${this.currentUser.email}` : 'No user',
    );

    // Create bound event handlers to avoid memory leaks
    this._onAuthChangeHandler = this._onAuthChange.bind(this);
    this._handleNavigationBound = this.handleNavigation.bind(this);

    // Will be rendered in connectedCallback
  }

  _onAuthChange(event) {
    console.log('AppLayout: Auth change event detected');

    // Update auth state
    const wasLoggedIn = this.isUserLoggedIn;
    this.isUserLoggedIn = isLoggedIn();
    this.currentUser = getCurrentUser();

    if (wasLoggedIn !== this.isUserLoggedIn) {
      console.log('Auth state changed, re-rendering AppLayout');
      this.render();
    }
  }

  connectedCallback() {
    // HTMLElement doesn't have a connectedCallback to call with super
    this.render();
    this.updateActiveNavLink();
    window.addEventListener('hashchange', this._handleNavigationBound);

    // Listen for authentication changes and update the UI
    document.addEventListener('authchange', this._onAuthChangeHandler);

    // Listen for the custom auth-state-changed event from LoginForm
    document.addEventListener('auth-state-changed', this._onAuthChangeHandler);
  }

  disconnectedCallback() {
    // HTMLElement doesn't have a disconnectedCallback to call with super
    window.removeEventListener('hashchange', this._handleNavigationBound);
    document.removeEventListener('authchange', this._onAuthChangeHandler);
    document.removeEventListener('auth-state-changed', this._onAuthChangeHandler);
  }

  handleNavigation() {
    // Update the active state of navigation items
    this.updateActiveNavLink();
  }

  debugShowMobileMenu() {
    console.log('debugShowMobileMenu called');
    const mobileDropdown = this.shadowRoot.getElementById('mobile-user-dropdown');
    if (mobileDropdown) {
      console.log('Found mobile dropdown, toggling active class');
      mobileDropdown.classList.toggle('active');
    } else {
      console.log('Mobile dropdown not found');
    }
  }

  setupBannerScroll() {
    const alphaBanner = this.shadowRoot.querySelector('.alpha-banner');
    const header = this.shadowRoot.querySelector('header');
    if (!alphaBanner || !header) return;

    // Function to permanently hide the banner and adjust header
    const hideBanner = () => {
      alphaBanner.classList.add('hidden');

      // After animation completes, remove from DOM and adjust header position
      setTimeout(() => {
        if (alphaBanner.parentNode) {
          alphaBanner.parentNode.removeChild(alphaBanner);
        }

        // Animate header to top position
        header.style.transition = 'top 0.3s ease';
        header.style.top = '0';
      }, 500);

      // Remove the scroll listener since we only need to hide once
      window.removeEventListener('scroll', hideBanner);
      // Clear the reference
      this._handleScrollBound = null;
    };

    // Add listener
    window.addEventListener('scroll', hideBanner, { once: true });

    // Store reference for cleanup
    this._handleScrollBound = hideBanner;
  }

  render() {
    // Define CSS styles
    const style = `
      <style>
        /* Root variables */
        :host {
          --header-height: 70px;
          --footer-height: 60px;
          --color-primary: #4F46E5;
          --color-primary-dark: #4338CA;
          --color-primary-light: #818CF8;
          --color-white: #FFFFFF;
          --color-black: #000000;
          --color-gray-50: #F9FAFB;
          --color-gray-100: #F3F4F6;
          --color-gray-200: #E5E7EB;
          --color-gray-300: #D1D5DB;
          --color-gray-400: #9CA3AF;
          --color-gray-500: #6B7280;
          --color-gray-600: #4B5563;
          --color-gray-700: #374151;
          --color-gray-800: #1F2937;
          --color-gray-900: #111827;
          --color-error: #EF4444;
          --color-success: #10B981;
          --color-warning: #F59E0B;
          --color-info: #3B82F6;
          --color-secondary: #10B981;
          --color-secondary-dark: #0E9F6E;

          /* Typography */
          --font-sans: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
          --font-display: var(--font-sans);
          --font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
          --text-xs: 0.75rem;
          --text-sm: 0.875rem;
          --text-base: 1rem;
          --text-lg: 1.125rem;
          --text-xl: 1.25rem;
          --text-2xl: 1.5rem;
          --text-3xl: 1.875rem;
          --text-4xl: 2.25rem;
          --text-5xl: 3rem;
          --text-6xl: 3.75rem;
          --font-light: 300;
          --font-normal: 400;
          --font-medium: 500;
          --font-semibold: 600;
          --font-bold: 700;
          --font-extrabold: 800;

          /* Spacing */
          --spacing-px: 1px;
          --spacing-xs: 0.25rem;
          --spacing-sm: 0.5rem;
          --spacing-md: 1rem;
          --spacing-lg: 1.5rem;
          --spacing-xl: 2rem;
          --spacing-2xl: 2.5rem;
          --spacing-3xl: 3rem;
          --spacing-4xl: 4rem;

          /* Border radius */
          --radius-sm: 0.125rem;
          --radius-md: 0.375rem;
          --radius-lg: 0.5rem;
          --radius-xl: 0.75rem;
          --radius-2xl: 1rem;
          --radius-3xl: 1.5rem;
          --radius-full: 9999px;

          /* Shadows */
          --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
          --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
          --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
          --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);

          /* Transitions */
          --transition-normal: 0.3s ease;
          --transition-fast: 0.15s ease;
          --transition-slow: 0.5s ease;

          /* Z-index layers */
          --z-0: 0;
          --z-10: 10;
          --z-20: 20;
          --z-30: 30;
          --z-40: 40;
          --z-50: 50;
          --z-auto: auto;
        }

        /* Layout */
        .app-container {
          display: flex;
          flex-direction: column;
          min-height: 100vh;
          font-family: var(--font-sans);
          color: var(--color-gray-800);
          background-color: var(--color-gray-50);
        }

        header {
          height: var(--header-height);
          background: linear-gradient(to right, var(--color-white), var(--color-gray-50));
          box-shadow: var(--shadow-md);
          position: sticky;
          top: 18px; /* Space for the alpha banner */
          z-index: var(--z-30);
          transition: all var(--transition-normal);
        }

        header::before {
          content: '';
          position: absolute;
          top: -50%;
          right: -10%;
          width: 400px;
          height: 400px;
          border-radius: 50%;
          background: radial-gradient(circle, rgba(79, 70, 229, 0.03) 0%, rgba(79, 70, 229, 0) 70%);
          z-index: 0;
        }

        header::after {
          content: '';
          position: absolute;
          bottom: -80%;
          left: -5%;
          width: 300px;
          height: 300px;
          border-radius: 50%;
          background: radial-gradient(circle, rgba(16, 185, 129, 0.03) 0%, rgba(16, 185, 129, 0) 70%);
          z-index: 0;
        }

        .header-container {
          max-width: 1200px;
          margin: 0 auto;
          padding: 0 var(--spacing-md);
          height: 100%;
          display: flex;
          align-items: center;
          position: relative;
          z-index: 1;
        }

        .logo-container {
          display: flex;
          align-items: center;
          animation: fadeIn var(--transition-slow);
        }
        
        /* Logo styles */
        .app-logo {
          display: inline-flex;
          align-items: center;
          text-decoration: none;
          padding: 0.5rem 0;
          position: relative;
        }

        .app-logo .envelope {
          transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
          box-shadow: var(--shadow-sm);
        }

        .app-logo:hover .envelope {
          transform: translateY(-3px) rotate(-2deg);
          filter: drop-shadow(0 6px 12px rgba(79, 70, 229, 0.4));
          box-shadow: var(--shadow-lg);
        }

        .app-logo .greater-than {
          transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
        }

        .app-logo:hover .greater-than {
          transform: translateX(4px);
        }

        .app-logo .logo-text-primary {
          transition: all 0.4s ease;
        }

        .app-logo:hover .logo-text-primary {
          filter: brightness(1.2);
        }

        .app-logo .logo-text-top,
        .app-logo .logo-text-bottom {
          transition: all 0.4s ease;
        }

        .app-logo:hover .logo-text-top,
        .app-logo:hover .logo-text-bottom {
          fill: var(--color-gray-700);
        }

        .app-logo * {
          transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        }

        nav ul {
          display: flex;
          list-style: none;
          margin: 0;
          padding: 0;
          gap: var(--spacing-xl);
          animation: slideUp var(--transition-slow) 0.1s;
        }

        nav li {
          position: relative;
          display: flex;
          align-items: center;
        }

        nav a {
          text-decoration: none;
          color: var(--color-gray-600);
          font-weight: var(--font-medium);
          padding: var(--spacing-xs) var(--spacing-xs);
          border-radius: var(--radius-md);
          transition: color 0.3s ease;
          position: relative;
          display: block;
          white-space: nowrap;
        }

        nav a::after {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 2px;
          background: linear-gradient(90deg, var(--color-primary-light), var(--color-primary));
          border-radius: var(--radius-full);
          transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
          opacity: 0;
        }

        nav a:hover {
          color: var(--color-primary);
          background-color: transparent;
        }

        nav a:hover::after {
          width: 80%;
          opacity: 1;
        }

        nav a.active {
          color: var(--color-primary);
          background-color: transparent;
        }

        nav a.active::after {
          width: 80%;
          opacity: 1;
        }

        /* Create Card CTA styles */
        nav a.create-card-cta {
          background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-dark) 100%);
          color: var(--color-white);
          padding: var(--spacing-xs) var(--spacing-md);
          margin-left: var(--spacing-sm);
          border-radius: var(--radius-full);
          font-weight: var(--font-medium);
          transition: all var(--transition-normal);
          position: relative;
          overflow: hidden;
          box-shadow: var(--shadow-md);
        }
        
        nav a.create-card-cta::after {
          content: '';
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,0.2), rgba(255,255,255,0));
          transition: all 0.8s ease;
          z-index: 1;
        }
        
        nav a.create-card-cta:hover {
          background: linear-gradient(135deg, var(--color-primary-dark) 0%, var(--color-primary) 100%);
          color: var(--color-white);
          transform: translateY(-3px);
          box-shadow: var(--shadow-lg);
        }
        
        nav a.create-card-cta:hover::after {
          left: 100%;
        }
        
        nav a.create-card-cta.active {
          background: linear-gradient(135deg, var(--color-primary-dark) 0%, var(--color-primary) 100%);
          color: var(--color-white);
        }

        .auth-container {
          display: flex;
          align-items: center;
          gap: var(--spacing-md);
          animation: fadeIn var(--transition-slow) 0.2s;
        }

        .auth-button {
          padding: var(--spacing-xs) var(--spacing-md);
          border-radius: var(--radius-full);
          font-weight: var(--font-medium);
          transition: all var(--transition-normal);
          text-decoration: none;
        }

        .login-button {
          color: var(--color-primary);
          position: relative;
        }

        .login-button::after {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 2px;
          background: linear-gradient(90deg, var(--color-primary-light), var(--color-primary));
          border-radius: var(--radius-full);
          transition: width var(--transition-normal);
        }

        .login-button:hover {
          background-color: transparent;
        }

        .login-button:hover::after {
          width: 80%;
        }

        .signup-button {
          background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-dark) 100%);
          color: var(--color-white);
          position: relative;
          overflow: hidden;
          box-shadow: var(--shadow-md);
        }

        .signup-button::after {
          content: '';
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,0.2), rgba(255,255,255,0));
          transition: all 0.8s ease;
          z-index: 1;
        }

        .signup-button:hover {
          background: linear-gradient(135deg, var(--color-primary-dark) 0%, var(--color-primary) 100%);
          transform: translateY(-3px);
          box-shadow: var(--shadow-lg);
        }

        .signup-button:hover::after {
          left: 100%;
        }
        
        .user-profile {
          display: flex;
          align-items: center;
          gap: var(--spacing-sm);
          position: relative;
          cursor: pointer;
          animation: fadeIn var(--transition-slow);
          transition: all var(--transition-normal);
        }
        
        .user-avatar {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-dark) 100%);
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: var(--font-bold);
          font-size: var(--text-sm);
          box-shadow: var(--shadow-md);
          transition: all var(--transition-normal);
        }

        .user-profile:hover .user-avatar {
          transform: translateY(-2px);
          box-shadow: var(--shadow-lg);
        }
        
        .user-info {
          display: flex;
          flex-direction: column;
          transition: all var(--transition-normal);
        }
        
        .user-name {
          font-weight: var(--font-medium);
          color: var(--color-gray-900);
          font-size: var(--text-sm);
          transition: all var(--transition-normal);
        }

        .user-profile:hover .user-name {
          color: var(--color-primary);
        }
        
        .user-email {
          color: var(--color-gray-500);
          font-size: var(--text-xs);
          transition: all var(--transition-normal);
        }
        
        .logout-button {
          color: var(--color-gray-500);
          font-size: var(--text-sm);
          background: none;
          border: none;
          cursor: pointer;
          padding: var(--spacing-xs) var(--spacing-sm);
          border-radius: var(--radius-md);
          transition: all var(--transition-normal);
          position: relative;
        }

        .logout-button::after {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 2px;
          background: linear-gradient(90deg, var(--color-error), var(--color-primary-light));
          border-radius: var(--radius-full);
          transition: width var(--transition-normal);
        }
        
        .logout-button:hover {
          color: var(--color-error);
          background-color: transparent;
        }

        .logout-button:hover::after {
          width: 80%;
        }

        main {
          flex: 1;
        }

        /* Footer styles */
        footer {
          background: var(--color-gray-800);
          padding: 4rem 2rem 2rem;
          color: var(--color-gray-300);
          margin-top: auto;
        }

        .footer-content {
          max-width: 1200px;
          margin: 0 auto;
          display: grid;
          grid-template-columns: 2fr 1fr 1fr 1fr;
          gap: 3rem;
        }
        
        .footer-section h3 {
          color: var(--color-white);
          margin-bottom: 1.25rem;
          font-size: 1.1rem;
        }

        .footer-section p {
          margin-bottom: 1.5rem;
          line-height: 1.6;
          max-width: 300px;
          color: var(--color-gray-400);
        }
        
        .footer-section ul {
          list-style: none;
          padding: 0;
        }
        
        .footer-section li {
          margin: 0.75rem 0;
        }

        .footer-section a {
          color: var(--color-gray-400);
          text-decoration: none;
          transition: color 0.2s, transform 0.2s;
          display: inline-block;
        }
        
        .footer-section a:hover {
          color: var(--color-white);
          transform: translateX(2px);
        }

        .footer-cta {
          display: inline-flex;
          align-items: center;
          padding: 0.75rem 1.5rem;
          background: white;
          color: var(--color-primary);
          border-radius: 6px;
          font-weight: 500;
          margin-top: 1rem;
          transition: background 0.2s, color 0.2s;
        }
        
        .footer-cta:hover {
          background: var(--color-primary);
          color: white;
        }

        .footer-bottom {
          text-align: center;
          margin-top: 4rem;
          padding-top: 2rem;
          border-top: 1px solid var(--color-gray-700);
          font-size: 0.9rem;
          color: var(--color-gray-500);
        }

        @media (max-width: 900px) {
          .footer-content {
            grid-template-columns: 1fr 1fr;
            gap: 2rem;
          }
        }

        @media (max-width: 600px) {
          .footer-content {
            grid-template-columns: 1fr;
          }
        }

        /* Mobile styles */
        @media (max-width: 768px) {
          /* Header container in mobile */
          .header-container {
            justify-content: space-between;
          }
          
          /* Hamburger menu positioning */
          .hamburger-menu {
            display: flex;
            margin-left: auto; /* Push to the right */
            order: 3; /* Ensure it's the last item */
          }
          
          /* Hide regular nav in mobile until toggled */
          nav {
            position: absolute;
            top: var(--header-height);
            left: 0;
            width: 100%;
            background: var(--color-white);
            box-shadow: var(--shadow-md);
            display: none;
            flex-direction: column;
            border-top: 1px solid var(--color-gray-200);
            max-height: calc(100vh - var(--header-height));
            overflow-y: auto;
            z-index: 50;
          }
          nav.active {
            display: flex;
          }
          nav ul {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            padding: 0;
            margin: 0;
          }
          nav ul li {
            width: 100%;
            border-bottom: 1px solid var(--color-gray-100);
          }
          nav ul li a.nav-link {
            display: flex;
            align-items: center;
            padding: var(--spacing-md) var(--spacing-lg);
            width: 100%;
            font-weight: var(--font-medium);
          }
          .user-section {
            background-color: var(--color-gray-50);
            padding: 0;
            width: 100%;
            border-bottom: 1px solid var(--color-gray-200);
          }
          .user-profile-button {
            padding: var(--spacing-md) var(--spacing-lg) !important;
            background: none;
            border: none;
            display: flex;
            align-items: center;
            width: 100%;
            cursor: pointer;
            text-align: left;
          }
          .user-section li {
            border-bottom: 1px solid var(--color-gray-100);
          }
          .user-section li:last-child {
            border-bottom: none;
          }
          .login-buttons {
            width: 100%;
            padding: var(--spacing-md) var(--spacing-lg);
          }
          .auth-buttons-container {
            display: flex;
            gap: var(--spacing-md);
          }
          .nav-icon {
            width: 20px;
            height: 20px;
            margin-right: var(--spacing-md);
            color: var(--color-primary);
          }
          .create-card-cta {
            margin: var(--spacing-md) var(--spacing-lg);
            display: flex;
            justify-content: center;
            border-radius: var(--radius-full);
          }
          .auth-container {
            display: none;
          }
          .mobile-only {
            display: block;
          }
          .nav-auth.mobile-only .user-profile {
            cursor: pointer;
            pointer-events: auto;
            padding: var(--spacing-sm);
            border: 1px solid var(--color-gray-200);
            border-radius: var(--radius-md);
            background-color: var(--color-gray-50);
          }
          .nav-auth.mobile-only .user-profile:hover {
            background-color: var(--color-gray-100);
          }
          .mobile-avatar {
            display: inline-flex !important;
            margin-right: var(--spacing-sm);
            margin-left: 0;
          }
          .user-section {
            width: 100%;
            border-bottom: 1px solid var(--color-gray-200);
          }
          .user-nav-link .nav-icon {
            display: none; /* Hide the settings icon when we have the avatar */
          }
        }

        /* End Mobile styles */

        /* User dropdown menu */
        .user-dropdown {
          display: none;
          position: absolute;
          top: 100%;
          right: 0;
          margin-top: 8px;
          background-color: var(--color-white);
          border-radius: var(--radius-md);
          box-shadow: var(--shadow-lg);
          width: 180px;
          z-index: 100;
          overflow: hidden;
        }
        
        .user-dropdown.active {
          display: block;
        }
        
        .dropdown-item {
          display: flex;
          align-items: center;
          padding: var(--spacing-md);
          color: var(--color-gray-700);
          text-decoration: none;
          transition: background-color 0.2s;
        }
        
        .dropdown-item:hover {
          background-color: var(--color-gray-100);
        }
        
        .dropdown-item.danger {
          color: var(--color-error);
        }
        
        .dropdown-item.danger:hover {
          background-color: rgba(239, 68, 68, 0.1);
        }
        
        .dropdown-icon {
          margin-right: var(--spacing-sm);
          width: 16px;
          height: 16px;
        }
        
        /* Alpha Banner Styles */
        .alpha-banner {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 18px; /* Fixed height that matches header's top */
          background-color: rgba(128, 90, 213, 0.9); /* Purple color */
          color: white;
          padding: 3px 0;
          font-size: 11px;
          font-weight: bold;
          text-align: center;
          z-index: 1001;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
          transition: opacity 0.5s ease, top 0.5s ease;
          line-height: 1.2;
        }
        
        .alpha-banner.hidden {
          opacity: 0;
          pointer-events: none;
        }

        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }

        @keyframes slideUp {
          from { transform: translateY(20px); opacity: 0; }
          to { transform: translateY(0); opacity: 1; }
        }

        @keyframes float {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
        }

        /* Hamburger Menu Styles - reverted to 3 bars */
        .hamburger-menu {
          display: none; /* Hide by default, show only in mobile */
          flex-direction: column;
          justify-content: space-between;
          width: 28px;
          height: 22px;
          cursor: pointer;
          transition: all 0.3s ease;
        }
        
        .hamburger-menu span {
          display: block;
          height: 3px;
          width: 100%;
          border-radius: 3px;
          background: var(--color-primary);
          transition: all 0.3s ease;
        }

        /* Fix icon sizes for desktop vs mobile */
        @media (min-width: 769px) {
          .header-container {
            justify-content: space-between;
          }
          
          nav {
            margin-right: auto;
            margin-left: 2rem;
          }
          
          nav ul {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;
            gap: var(--spacing-xl);
            animation: slideUp var(--transition-slow) 0.1s;
          }
          
          /* Hide navigation icons on desktop */
          .nav-icon {
            display: none;
          }
        }

        @media (max-width: 768px) {
          /* Show hamburger on mobile */
          .hamburger-menu {
            display: flex;
          }
          
          /* Mobile - show navigation icons */
          .nav-icon {
            width: 20px;
            height: 20px;
            margin-right: var(--spacing-md);
            color: var(--color-primary);
          }
        }

        .logout-link {
          color: var(--color-error);
        }

        .logout-link:hover {
          color: var(--color-error);
          background-color: rgba(239, 68, 68, 0.1);
        }

        .mobile-only .nav-link svg {
          margin-right: var(--spacing-sm);
        }

        .desktop-only {
          display: none;
        }

        .mobile-only {
          display: none;
        }

        @media (min-width: 769px) {
          .desktop-only {
            display: block;
          }
          
          /* User avatar in nav styling */
          .user-avatar-small {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-dark) 100%);
            color: white;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-weight: var(--font-bold);
            font-size: 12px;
            margin-right: 8px;
          }
          
          .user-nav-link {
            display: flex;
            align-items: center;
          }
        }

        @media (max-width: 768px) {
          .mobile-only {
            display: block;
          }
        }
      </style>
    `;

    // Get user info if logged in
    const userInitials =
      this.currentUser?.email?.substring(0, 2).toUpperCase() ||
      this.currentUser?.username?.substring(0, 2).toUpperCase() ||
      '??';

    // Define the HTML structure
    const html = `
      <div class="app-container">
        <!-- Alpha Banner -->
        <div class="alpha-banner">ALPHA v${__APP_VERSION__}</div>
        
        <header>
          <div class="header-container">
            <div class="logo-container">
              <a href="#/" class="app-logo">
                <svg width="240" height="38" viewBox="0 0 240 38" fill="none">
                  <defs>
                    <linearGradient id="envelope-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                      <stop offset="0%" stop-color="var(--color-primary)"></stop>
                      <stop offset="50%" stop-color="var(--color-primary-dark)"></stop>
                      <stop offset="100%" stop-color="var(--color-primary)"></stop>
                    </linearGradient>
                    <linearGradient id="text-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                      <stop offset="0%" stop-color="var(--color-primary)"></stop>
                      <stop offset="50%" stop-color="var(--color-primary-light)"></stop>
                      <stop offset="100%" stop-color="var(--color-primary-dark)"></stop>
                    </linearGradient>
                    <filter id="glow" x="-20%" y="-20%" width="140%" height="140%">
                      <feGaussianBlur stdDeviation="2" result="blur" />
                      <feComposite in="SourceGraphic" in2="blur" operator="over" />
                    </filter>
                  </defs>
                  
                  <!-- Envelope Box -->
                  <rect class="envelope" x="0" y="0" width="38" height="38" rx="9" fill="url(#envelope-gradient)" filter="url(#glow)"></rect>
                  
                  <!-- Envelope Flap -->
                  <path d="M9 12l10 7 10-7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  
                  <!-- Text Elements -->
                  <text x="52" y="10" font-size="10" font-weight="500" fill="var(--color-gray-500)" letter-spacing="0.5" text-transform="uppercase" font-family="Inter" class="logo-text-top">Send a</text>
                  <text x="52" y="26" font-size="16" font-weight="600" fill="url(#text-gradient)" letter-spacing="-0.3" font-family="Inter" class="logo-text-primary">Thank You</text>
                  <text x="107" y="38" font-size="10" font-weight="500" fill="var(--color-gray-500)" letter-spacing="0.5" text-transform="uppercase" font-family="Inter" class="logo-text-bottom">Card</text>
                  
                  <!-- Greater Than -->
                  <path class="greater-than" d="M145 5l5 15-5 15" stroke="url(#text-gradient)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </a>
            </div>
            <div class="hamburger-menu" id="hamburger-menu">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <nav id="nav-menu">
              <ul>
                <li><a href="#/templates" class="nav-link">
                  <svg class="nav-icon" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"></path>
                  </svg>
                  Templates</a>
                </li>
                <li><a href="#/pricing" class="nav-link">
                  <svg class="nav-icon" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                  Pricing</a>
                </li>
                <li><a href="#/features" class="nav-link">
                  <svg class="nav-icon" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"></path>
                  </svg>
                  Features</a>
                </li>
                <li><a href="#/create" class="nav-link create-card-cta">Create your Card</a></li>
                
                ${
                  this.isUserLoggedIn
                    ? `
                    <!-- For Desktop -->
                    <li class="desktop-only">
                      <a href="#" id="desktop-logout-btn" class="nav-link" data-no-active="true">Logout</a>
                    </li>
                    <li class="desktop-only">
                      <a href="#/settings" class="nav-link user-nav-link">
                        <div class="user-avatar-small">${userInitials}</div>
                        <span>Settings</span>
                      </a>
                    </li>
                    
                    <!-- For Mobile -->
                    <div class="user-section mobile-only">
                      <li>
                        <a href="#/settings" class="nav-link user-nav-link">
                          <svg class="nav-icon" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                          </svg>
                          <div class="user-avatar-small mobile-avatar">${userInitials}</div>
                          Settings
                        </a>
                      </li>
                      <li>
                        <a href="#" id="mobile-logout-btn" class="nav-link" data-no-active="true">
                          <svg class="nav-icon" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
                          </svg>
                          Logout
                        </a>
                      </li>
                    </div>`
                    : `
                    <!-- For both mobile and desktop -->
                    <li class="login-buttons">
                      <a href="#/login" class="auth-button login-button">Login</a>
                      <a href="#/signup" class="auth-button signup-button">Sign Up</a>
                    </li>`
                }
              </ul>
            </nav>
          </div>
        </header>
        <main>
          <slot></slot>
        </main>
        <footer>
          <div class="footer-content">
            <div class="footer-section">
              <h3>Send a Thank You Card</h3>
              <p>Express your gratitude with beautifully crafted thank you cards. Digital or physical, delivered with care.</p>
              <a href="#/create" class="footer-cta">Create Your Card</a>
            </div>
            <div class="footer-section">
              <h3>Product</h3>
              <ul>
                <li><a href="#/templates">Templates</a></li>
                <li><a href="#/pricing">Pricing</a></li>
                <li><a href="#/features">Features</a></li>
                <li><a href="#/create">Create Card</a></li>
              </ul>
            </div>
            <div class="footer-section">
              <h3>Company</h3>
              <ul>
                <li><a href="#/about">About</a></li>
                <li><a href="#/blog">Blog</a></li>
                <li><a href="#/contact">Contact</a></li>
              </ul>
            </div>
            <div class="footer-section">
              <h3>Legal</h3>
              <ul>
                <li><a href="#/privacy">Privacy Policy</a></li>
                <li><a href="#/terms">Terms of Service</a></li>
              </ul>
            </div>
          </div>
          <div class="footer-bottom">
            <p>&copy; ${new Date().getFullYear()} SendAThankYouCard.com &middot; All rights reserved</p>
          </div>
        </footer>
      </div>
    `;

    // Combine style and HTML
    this.shadowRoot.innerHTML = `${style}${html}`;

    // Setup event listeners
    if (this.isUserLoggedIn) {
      // Mobile logout button
      const mobileLogoutBtn = this.shadowRoot.getElementById('mobile-logout-btn');
      if (mobileLogoutBtn) {
        mobileLogoutBtn.addEventListener('click', (e) => {
          e.preventDefault();
          logout();
        });
      }

      // Desktop logout button
      const desktopLogoutBtn = this.shadowRoot.getElementById('desktop-logout-btn');
      if (desktopLogoutBtn) {
        desktopLogoutBtn.addEventListener('click', (e) => {
          e.preventDefault();
          logout();
        });
      }
    }

    // Add hamburger menu event listener
    const hamburger = this.shadowRoot.getElementById('hamburger-menu');
    const navMenu = this.shadowRoot.getElementById('nav-menu');
    if (hamburger && navMenu) {
      hamburger.addEventListener('click', (e) => {
        e.stopPropagation();
        hamburger.classList.toggle('active');
        navMenu.classList.toggle('active');
      });
    }

    // Set active navigation
    this.updateActiveNavLink();

    // Setup banner scroll behavior
    this.setupBannerScroll();
  }

  updateActiveNavLink() {
    // Find all nav links in the shadow DOM
    const navLinks = this.shadowRoot.querySelectorAll('.nav-link');
    const currentPath = window.location.hash.slice(1) || '/';

    // Remove active class from all links
    navLinks.forEach((link) => {
      link.classList.remove('active');

      // Skip links with data-no-active attribute
      if (link.getAttribute('data-no-active') === 'true') {
        return;
      }

      const linkPath = link.getAttribute('href').slice(1);

      // Mark as active if paths match
      if (currentPath === linkPath || (linkPath !== '/' && currentPath.startsWith(linkPath))) {
        link.classList.add('active');
      }
    });
  }
}

customElements.define('app-layout', AppLayout);

// Export the class for tests and dynamic imports
export default AppLayout;
