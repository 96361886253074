import '../components/AppLayout/AppLayout.js';
import templates from '../config/templates.json';

export function loadTemplatesPage() {
  const app = document.getElementById('app');
  if (!app) {
    return;
  }

  // Group templates by category
  const templatesByCategory = templates.templates.reduce((acc, template) => {
    if (!acc[template.category]) {
      acc[template.category] = [];
    }
    acc[template.category].push(template);
    return acc;
  }, {});

  app.innerHTML = `
    <app-layout>
      <div class="templates-container">
        <div class="templates-header">
          <h1>Card Templates</h1>
          <p>Choose from our collection of professionally designed templates</p>
          <a href="#/create" class="create-button">Create Your Card</a>
          
          <!-- Background decorative elements -->
          <div class="header-bg-circle header-bg-circle-1"></div>
          <div class="header-bg-circle header-bg-circle-2"></div>
        </div>

        ${templates.categories
          .map(
            (category) => `
          <div class="category-section">
            <h2>${category.name}</h2>
            <p class="category-description">${category.description}</p>
            
            <div class="templates-grid">
              ${(templatesByCategory[category.id] || [])
                .map(
                  (template, index) => `
                <div class="template-card" style="animation-delay: ${index * 0.1}s">
                  <div class="template-preview">
                    <img src="/assets/images/templates/${template.thumbnail}" alt="${template.name}">
                    <div class="template-hover-overlay">
                      <span class="use-template-btn">Use Template</span>
                    </div>
                  </div>
                  <div class="template-info">
                    <h3>${template.name}</h3>
                    <p>${template.description}</p>
                  </div>
                </div>
              `,
                )
                .join('')}
            </div>
          </div>
        `,
          )
          .join('')}
      </div>

      <style>
        .templates-container {
          max-width: 1200px;
          margin: 0 auto;
          padding: 2rem;
        }

        .templates-header {
          text-align: center;
          margin-bottom: 4rem;
          position: relative;
          padding: var(--spacing-4xl) var(--spacing-md);
          overflow: hidden;
        }

        .header-bg-circle {
          position: absolute;
          border-radius: 50%;
          z-index: -1;
        }

        .header-bg-circle-1 {
          width: 400px;
          height: 400px;
          top: -150px;
          right: -100px;
          background: radial-gradient(circle, rgba(79, 70, 229, 0.08) 0%, rgba(79, 70, 229, 0) 70%);
        }

        .header-bg-circle-2 {
          width: 300px;
          height: 300px;
          bottom: -100px;
          left: -50px;
          background: radial-gradient(circle, rgba(16, 185, 129, 0.08) 0%, rgba(16, 185, 129, 0) 70%);
        }

        .templates-header h1 {
          font-size: var(--text-5xl);
          font-weight: var(--font-bold);
          color: var(--color-gray-900);
          margin-bottom: var(--spacing-md);
          position: relative;
          display: inline-block;
          animation: fadeIn 0.8s ease-out, float 6s ease-in-out infinite;
        }

        .templates-header h1::after {
          content: '';
          position: absolute;
          bottom: -10px;
          left: 50%;
          transform: translateX(-50%);
          width: 80px;
          height: 4px;
          background: linear-gradient(90deg, var(--color-primary-light), var(--color-primary));
          border-radius: var(--radius-full);
        }

        .templates-header p {
          font-size: var(--text-xl);
          color: var(--color-gray-500);
          margin-bottom: var(--spacing-2xl);
          max-width: 600px;
          margin-left: auto;
          margin-right: auto;
          line-height: 1.6;
          animation: slideUp 0.8s ease-out;
        }

        .create-button {
          display: inline-block;
          padding: var(--spacing-md) var(--spacing-2xl);
          background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-dark) 100%);
          color: white;
          text-decoration: none;
          border-radius: var(--radius-full);
          font-weight: var(--font-semibold);
          font-size: var(--text-lg);
          transition: all var(--transition-normal);
          box-shadow: var(--shadow-md);
          position: relative;
          overflow: hidden;
          animation: slideUp 0.8s ease-out 0.2s backwards;
        }

        .create-button::after {
          content: '';
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,0.2), rgba(255,255,255,0));
          transition: all 0.8s ease;
          z-index: 1;
        }

        .create-button:hover {
          transform: translateY(-3px);
          box-shadow: var(--shadow-lg);
          background: linear-gradient(135deg, var(--color-primary-dark) 0%, var(--color-primary) 100%);
          color: white;
        }

        .create-button:hover::after {
          left: 100%;
        }

        .category-section {
          margin-bottom: var(--spacing-4xl);
          animation: fadeIn 1s ease-out;
        }

        .category-section h2 {
          font-size: var(--text-3xl);
          font-weight: var(--font-semibold);
          color: var(--color-gray-900);
          margin-bottom: var(--spacing-xs);
          position: relative;
          display: inline-block;
        }

        .category-section h2::after {
          content: '';
          display: block;
          width: 60px;
          height: 3px;
          background: linear-gradient(90deg, var(--color-primary-light), var(--color-primary));
          margin-top: var(--spacing-xs);
          border-radius: var(--radius-full);
        }

        .category-description {
          color: var(--color-gray-500);
          margin-bottom: var(--spacing-2xl);
          max-width: 800px;
          line-height: 1.6;
        }

        .templates-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
          gap: var(--spacing-xl);
        }

        .template-card {
          background: white;
          border-radius: var(--radius-lg);
          box-shadow: var(--shadow-md);
          overflow: hidden;
          transition: all var(--transition-normal);
          cursor: pointer;
          transform: translateY(20px);
          opacity: 0;
          animation: slideUpFadeIn 0.5s ease-out forwards;
        }

        .template-card:hover {
          transform: translateY(-4px);
          box-shadow: var(--shadow-lg);
        }

        .template-preview {
          width: 100%;
          height: 200px;
          background: var(--color-gray-50);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1rem;
          border-bottom: 1px solid var(--color-gray-100);
          position: relative;
          overflow: hidden;
        }

        .template-preview img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          transition: transform 0.5s ease;
        }

        .template-hover-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(79, 70, 229, 0.9);
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          transition: opacity 0.3s ease;
        }

        .template-card:hover .template-hover-overlay {
          opacity: 1;
        }

        .template-card:hover .template-preview img {
          transform: scale(1.05);
        }

        .use-template-btn {
          color: white;
          font-weight: var(--font-medium);
          padding: var(--spacing-sm) var(--spacing-md);
          border: 2px solid white;
          border-radius: var(--radius-md);
          transition: all 0.3s ease;
        }

        .use-template-btn:hover {
          background: white;
          color: var(--color-primary);
        }

        .template-info {
          padding: var(--spacing-lg);
        }

        .template-info h3 {
          font-size: var(--text-lg);
          font-weight: var(--font-medium);
          color: var(--color-gray-900);
          margin-bottom: var(--spacing-xs);
        }

        .template-info p {
          color: var(--color-gray-500);
          font-size: var(--text-base);
          line-height: 1.5;
        }

        @keyframes slideUpFadeIn {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }

        @keyframes slideUp {
          from { transform: translateY(20px); opacity: 0; }
          to { transform: translateY(0); opacity: 1; }
        }

        @keyframes float {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
        }

        @media (max-width: 768px) {
          .templates-container {
            padding: var(--spacing-md);
          }

          .templates-header h1 {
            font-size: var(--text-3xl);
          }

          .templates-header p {
            font-size: var(--text-lg);
          }

          .templates-grid {
            grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
            gap: var(--spacing-md);
          }

          .template-preview {
            height: 160px;
          }

          .template-info {
            padding: var(--spacing-md);
          }
        }
      </style>
    </app-layout>
  `;
}
