// Authentication logic (login/logout, token management)

import { API_BASE_URL, POCKETBASE_URL } from '../config.js';

// Store user information
let currentUser = null;

// Event for authentication changes
const authChangeEvent = new CustomEvent('authchange', {
  bubbles: true,
  composed: true,
});

/**
 * Authenticate a user with the server
 * @param {string} username - The username or email to log in with
 * @param {string} password - The user's password
 * @returns {Promise<Object>} - Result of the authentication attempt
 */
export async function login(username, password) {
  try {
    // Call our auth endpoint in the proxy
    // Use POCKETBASE_URL which already points to /api/pocketbase
    const response = await fetch(`${POCKETBASE_URL}/auth/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password }),
    });

    console.log('Login response status:', response.status);

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Login failed:', errorData);
      throw new Error(errorData.message || 'Login failed. Please check your credentials.');
    }

    const data = await response.json();

    // Store user info in memory and localStorage
    currentUser = {
      id: data.userId,
      username,
      email: username, // Since we're using email as username
      token: data.token, // Store the token
      tokenExpiry: data.tokenExpiry || Date.now() + 30 * 24 * 60 * 60 * 1000, // Default to 30 days if not provided
    };

    // Save to localStorage for persisting across page refreshes
    localStorage.setItem('auth_user', JSON.stringify(currentUser));

    console.log('User authenticated successfully:', currentUser.id);

    // Dispatch auth change event globally
    document.dispatchEvent(
      new CustomEvent('authchange', {
        bubbles: true,
        composed: true,
        detail: { isLoggedIn: true, user: currentUser },
      }),
    );

    console.log('Login successful, dispatched authchange event');

    return {
      success: true,
      user: currentUser,
    };
  } catch (error) {
    console.error('Error during login:', error);
    throw error;
  }
}

/**
 * Get the current user if logged in
 * @returns {Object|null} - The current user or null if not logged in
 */
export function getCurrentUser() {
  if (currentUser) {
    return currentUser;
  }

  // Try to get from localStorage
  const savedUser = localStorage.getItem('auth_user');
  if (savedUser) {
    try {
      currentUser = JSON.parse(savedUser);
      return currentUser;
    } catch (e) {
      console.error('Error parsing saved user:', e);
      localStorage.removeItem('auth_user');
    }
  }

  return null;
}

/**
 * Check if a user is logged in
 * @returns {boolean} - True if a user is logged in
 */
export function isLoggedIn() {
  const user = getCurrentUser();
  if (!user) {
    return false;
  }

  // If token has expired, log the user out
  if (user.tokenExpiry && user.tokenExpiry < Date.now()) {
    console.warn('User token has expired, logging out');
    logout();
    return false;
  }

  return true;
}

/**
 * Get the user ID header for API requests
 * @returns {Object} - Headers to add to fetch requests
 */
export function getAuthHeaders() {
  const user = getCurrentUser();
  if (user) {
    const headers = {
      'X-User-Id': user.id,
    };

    // If we have a token, add it as well
    if (user.token) {
      console.log(`Adding auth token for user ${user.id} to request headers`);
      headers['Authorization'] = `Bearer ${user.token}`;
    } else {
      console.warn(`User ${user.id} has no token, authentication may fail`);
    }

    console.log('Generated auth headers:', JSON.stringify(headers));
    return headers;
  }

  console.log('No user authenticated, returning empty headers');
  return {};
}

/**
 * Log the current user out
 */
export function logout() {
  // Clear user info
  currentUser = null;
  localStorage.removeItem('auth_user');
  console.log('User logged out.');

  // Dispatch auth change event globally with detail
  document.dispatchEvent(
    new CustomEvent('authchange', {
      bubbles: true,
      composed: true,
      detail: { isLoggedIn: false },
    }),
  );

  // Redirect to home page
  window.location.hash = '/';
}

/**
 * Add an event listener for auth state changes
 * @param {Function} callback - Function to call when auth state changes
 */
export function onAuthChange(callback) {
  const handler = () => {
    console.log('Auth change detected, calling callback');
    callback();
  };
  document.addEventListener('authchange', handler);
  return () => document.removeEventListener('authchange', handler);
}
