// Environment constants and app-wide configuration

// Use the same base URL for all API calls
export const API_BASE_URL = '/api'; // Uses relative URL to work with Netlify redirects
export const IS_PRODUCTION = false; // Toggle for dev/production
export const POCKETBASE_URL = '/api/pocketbase';

// Get all config values as an object
export function getConfig() {
  return {
    API_BASE_URL,
    IS_PRODUCTION,
    POCKETBASE_URL,
  };
}
