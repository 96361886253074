// Simple global store object and subscription pattern

export const store = {
  user: null,
  // Add more global data/state here

  subscribers: [],

  subscribe(callback) {
    this.subscribers.push(callback);
  },

  notify() {
    this.subscribers.forEach((cb) => cb(this));
  },

  // Example setter that triggers notifications
  setUser(newUser) {
    this.user = newUser;
    this.notify();
  },
};
