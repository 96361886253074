// Settings page
import '../components/AppLayout/AppLayout.js';
import { isLoggedIn, getCurrentUser } from '../services/auth.js';

export function loadSettingsPage() {
  const app = document.getElementById('app');
  if (!app) {
    return;
  }

  // Check if user is logged in
  if (!isLoggedIn()) {
    // Redirect to login if not logged in
    window.location.hash = '/login';
    return;
  }

  const user = getCurrentUser();

  app.innerHTML = `
    <app-layout>
      <div class="settings-container">
        <div class="settings-header">
          <h1>Account Settings</h1>
          <p>Manage your account settings and preferences</p>
          
          <!-- Background decorative elements -->
          <div class="header-bg-circle header-bg-circle-1"></div>
          <div class="header-bg-circle header-bg-circle-2"></div>
        </div>
        
        <div class="settings-content">
          <div class="settings-sidebar">
            <nav class="settings-nav">
              <a href="#/settings" class="settings-nav-item active">Profile</a>
              <a href="#/settings/notifications" class="settings-nav-item">Notifications</a>
              <a href="#/settings/billing" class="settings-nav-item">Billing</a>
              <a href="#/settings/security" class="settings-nav-item">Security</a>
            </nav>
            <div class="settings-sidebar-note">
              <p><small>Note: Additional settings sections coming soon!</small></p>
            </div>
          </div>
          
          <div class="settings-main">
            <div class="settings-card">
              <h2>Profile Information</h2>
              <form class="settings-form">
                <div class="form-group">
                  <label for="name">Full Name</label>
                  <input type="text" id="name" value="${user?.name || ''}" placeholder="Your full name">
                </div>
                
                <div class="form-group">
                  <label for="email">Email Address</label>
                  <input type="email" id="email" value="${user?.email || user?.username || ''}" placeholder="Your email address" disabled>
                  <span class="form-hint">Email address cannot be changed</span>
                </div>
                
                <div class="form-group">
                  <label for="bio">Bio</label>
                  <textarea id="bio" placeholder="Tell us about yourself"></textarea>
                </div>
                
                <div class="form-actions">
                  <button type="submit" class="btn-primary">Save Changes</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </app-layout>
  `;

  // Add styles
  const style = document.createElement('style');
  style.textContent = `
    .settings-container {
      max-width: 1000px;
      margin: 0 auto;
      padding: var(--spacing-2xl) var(--spacing-md);
      position: relative;
    }
    
    .settings-header {
      margin-bottom: var(--spacing-3xl);
      text-align: center;
      position: relative;
      padding: var(--spacing-xl) var(--spacing-md);
    }
    
    .header-bg-circle {
      position: absolute;
      border-radius: 50%;
      z-index: -1;
      opacity: 0.5;
    }

    .header-bg-circle-1 {
      width: 300px;
      height: 300px;
      top: -150px;
      right: -100px;
      background: radial-gradient(circle, rgba(79, 70, 229, 0.08) 0%, rgba(79, 70, 229, 0) 70%);
    }

    .header-bg-circle-2 {
      width: 250px;
      height: 250px;
      bottom: -80px;
      left: -80px;
      background: radial-gradient(circle, rgba(16, 185, 129, 0.08) 0%, rgba(16, 185, 129, 0) 70%);
    }
    
    .settings-header h1 {
      font-family: var(--font-display);
      font-size: var(--text-4xl);
      font-weight: var(--font-bold);
      color: var(--color-gray-900);
      margin-bottom: var(--spacing-sm);
      position: relative;
      display: inline-block;
      animation: fadeIn 0.8s ease-out;
    }
    
    .settings-header h1::after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 60px;
      height: 3px;
      background: linear-gradient(90deg, var(--color-primary-light), var(--color-primary));
      border-radius: var(--radius-full);
    }
    
    .settings-header p {
      color: var(--color-gray-500);
      font-size: var(--text-lg);
      max-width: 600px;
      margin: 0 auto;
      animation: fadeIn 0.8s ease-out 0.1s backwards;
    }
    
    .settings-content {
      display: grid;
      grid-template-columns: 220px 1fr;
      gap: var(--spacing-2xl);
      animation: fadeIn 0.8s ease-out 0.2s backwards;
    }
    
    .settings-sidebar {
      position: relative;
    }
    
    .settings-sidebar::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 1px;
      background: linear-gradient(to bottom, 
        rgba(var(--color-gray-200-rgb), 0), 
        rgba(var(--color-gray-200-rgb), 1) 20%, 
        rgba(var(--color-gray-200-rgb), 1) 80%, 
        rgba(var(--color-gray-200-rgb), 0));
    }
    
    .settings-nav {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-sm);
    }
    
    .settings-sidebar-note {
      margin-top: var(--spacing-xl);
      font-size: var(--text-sm);
      color: var(--color-gray-500);
      padding: var(--spacing-md);
      border-top: 1px dashed var(--color-gray-200);
      border-radius: var(--radius-md);
      background-color: rgba(var(--color-gray-50-rgb), 0.5);
    }
    
    .settings-nav-item {
      padding: var(--spacing-md) var(--spacing-lg);
      border-radius: var(--radius-lg);
      color: var(--color-gray-700);
      text-decoration: none;
      transition: all var(--transition-normal);
      position: relative;
      overflow: hidden;
    }
    
    .settings-nav-item:hover {
      background-color: rgba(var(--color-gray-100-rgb), 0.7);
      color: var(--color-gray-900);
      transform: translateX(3px);
    }
    
    .settings-nav-item.active {
      background: linear-gradient(to right, rgba(var(--color-primary-rgb), 0.1), rgba(var(--color-primary-rgb), 0.05));
      color: var(--color-primary);
      font-weight: var(--font-medium);
      box-shadow: inset 3px 0 0 var(--color-primary);
    }
    
    .settings-main {
      max-width: 100%;
    }
    
    .settings-card {
      background-color: white;
      border-radius: var(--radius-xl);
      box-shadow: var(--shadow-md);
      padding: var(--spacing-2xl);
      border: 1px solid var(--color-gray-100);
      transition: all var(--transition-normal);
    }
    
    .settings-card:hover {
      box-shadow: var(--shadow-lg);
      border-color: var(--color-gray-200);
    }
    
    .settings-card h2 {
      font-size: var(--text-2xl);
      font-weight: var(--font-semibold);
      margin-bottom: var(--spacing-xl);
      padding-bottom: var(--spacing-md);
      border-bottom: 1px solid var(--color-gray-200);
      color: var(--color-gray-900);
      position: relative;
    }
    
    .settings-card h2::after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 60px;
      height: 3px;
      background: linear-gradient(90deg, var(--color-primary-light), var(--color-primary));
      border-radius: var(--radius-full);
    }
    
    .settings-form .form-group {
      margin-bottom: var(--spacing-xl);
    }
    
    .settings-form label {
      display: block;
      margin-bottom: var(--spacing-sm);
      font-weight: var(--font-medium);
      color: var(--color-gray-700);
    }
    
    .settings-form input, 
    .settings-form textarea {
      width: 100%;
      padding: var(--spacing-md);
      border: 1px solid var(--color-gray-300);
      border-radius: var(--radius-lg);
      font-size: var(--text-base);
      transition: all var(--transition-normal);
    }
    
    .settings-form input:focus,
    .settings-form textarea:focus {
      outline: none;
      border-color: var(--color-primary);
      box-shadow: 0 0 0 3px rgba(var(--color-primary-rgb), 0.15);
    }
    
    .settings-form input:disabled {
      background-color: var(--color-gray-100);
      cursor: not-allowed;
    }
    
    .settings-form textarea {
      min-height: 120px;
      resize: vertical;
    }
    
    .form-hint {
      display: block;
      font-size: var(--text-sm);
      color: var(--color-gray-500);
      margin-top: var(--spacing-xs);
    }
    
    .form-actions {
      margin-top: var(--spacing-2xl);
      display: flex;
      justify-content: flex-end;
    }
    
    .btn-primary {
      padding: var(--spacing-md) var(--spacing-2xl);
      background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-dark) 100%);
      color: white;
      border: none;
      border-radius: var(--radius-full);
      font-weight: var(--font-medium);
      cursor: pointer;
      transition: all var(--transition-normal);
      position: relative;
      overflow: hidden;
      box-shadow: var(--shadow-md);
    }
    
    .btn-primary::after {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,0.2), rgba(255,255,255,0));
      transition: all 0.8s ease;
      z-index: 1;
    }
    
    .btn-primary:hover {
      background: linear-gradient(135deg, var(--color-primary-dark) 0%, var(--color-primary) 100%);
      transform: translateY(-3px);
      box-shadow: var(--shadow-lg);
    }
    
    .btn-primary:hover::after {
      left: 100%;
    }
    
    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }
    
    @keyframes slideUp {
      from { transform: translateY(20px); opacity: 0; }
      to { transform: translateY(0); opacity: 1; }
    }
    
    @media (max-width: 768px) {
      .settings-content {
        grid-template-columns: 1fr;
        gap: var(--spacing-xl);
      }
      
      .settings-sidebar {
        border-right: none;
      }
      
      .settings-sidebar::after {
        display: none;
      }
      
      .settings-nav {
        flex-direction: row;
        flex-wrap: wrap;
        gap: var(--spacing-xs);
      }
      
      .settings-nav-item {
        padding: var(--spacing-sm) var(--spacing-md);
        font-size: var(--text-sm);
        flex: 1 0 auto;
        text-align: center;
      }
      
      .settings-nav-item.active {
        box-shadow: none;
      }
      
      .settings-card {
        padding: var(--spacing-xl);
      }
      
      .settings-header h1 {
        font-size: var(--text-3xl);
      }
    }
  `;
  document.head.appendChild(style);
}
