// Coming Soon page
import '../components/AppLayout/AppLayout.js';

export function loadComingSoonPage() {
  const app = document.getElementById('app');
  if (!app) {
    return;
  }

  // Get the requested path from the URL
  const hash = window.location.hash.slice(1);

  // Figure out the context and title based on the URL
  let pageTitle = '';
  let pageDescription = '';
  let fromPricing = false;

  if (hash.startsWith('/create/') || hash.includes('premium') || hash.includes('business')) {
    fromPricing = true;

    if (hash.includes('free')) {
      pageTitle = 'Free Plan Sign-up';
      pageDescription = 'Our free plan sign-up will be available soon!';
    } else if (hash.includes('premium')) {
      pageTitle = 'Premium Plan Sign-up';
      pageDescription = 'Our premium plan subscription service will be available soon!';
    } else if (hash.includes('business')) {
      pageTitle = 'Business Plan Inquiry';
      pageDescription = 'Our business plan request form will be available soon!';
    } else {
      pageTitle = 'Subscription';
      pageDescription = 'Our subscription service will be available soon!';
    }
  } else {
    // Default page formatting for non-pricing related pages
    const pageName = hash.split('/').pop();
    pageTitle = pageName.charAt(0).toUpperCase() + pageName.slice(1).replace(/-/g, ' ');
    pageDescription = `We're working hard to bring you this feature. Please check back soon!`;
  }

  app.innerHTML = `
    <app-layout>
      <div class="coming-soon-container">
        <div class="coming-soon-content">
          <div class="icon-container">
            <svg class="construction-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
            </svg>
          </div>
          <h1>Coming Soon</h1>
          <p class="feature-name">${pageTitle}</p>
          <p class="message">
            ${pageDescription}
            ${
              fromPricing
                ? `
              <br><br>
              <span class="pricing-note">We're currently finalizing our payment system to ensure a smooth experience for all users.</span>
            `
                : ''
            }
          </p>
          
          <div class="actions">
            ${
              fromPricing
                ? `
              <a href="#/pricing" class="btn-primary">Back to Pricing</a>
            `
                : `
              <a href="#/" class="btn-primary">Return to Homepage</a>
            `
            }
            <a href="javascript:history.back()" class="btn-secondary">Go Back</a>
          </div>
        </div>
        
        <!-- Background decoration -->
        <div class="bg-circle bg-circle-1"></div>
        <div class="bg-circle bg-circle-2"></div>
      </div>
    </app-layout>
  `;

  // Add some page-specific styles
  const style = document.createElement('style');
  style.textContent = `
    .coming-soon-container {
      min-height: calc(100vh - var(--header-height) - 100px);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--spacing-3xl) var(--spacing-md);
      text-align: center;
      position: relative;
      overflow: hidden;
    }
    
    .coming-soon-content {
      max-width: 600px;
      position: relative;
      z-index: 2;
      animation: fadeIn 0.8s ease-out;
    }
    
    .bg-circle {
      position: absolute;
      border-radius: 50%;
      z-index: 1;
      opacity: 0.5;
    }
    
    .bg-circle-1 {
      width: 600px;
      height: 600px;
      top: -200px;
      right: -200px;
      background: radial-gradient(circle, rgba(79, 70, 229, 0.08) 0%, rgba(79, 70, 229, 0) 70%);
      animation: float 15s ease-in-out infinite;
    }
    
    .bg-circle-2 {
      width: 400px;
      height: 400px;
      bottom: -100px;
      left: -100px;
      background: radial-gradient(circle, rgba(16, 185, 129, 0.08) 0%, rgba(16, 185, 129, 0) 70%);
      animation: float 20s ease-in-out infinite reverse;
    }
    
    .icon-container {
      margin-bottom: var(--spacing-xl);
      position: relative;
      display: inline-block;
    }
    
    .construction-icon {
      width: 80px;
      height: 80px;
      background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-dark) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: pulse 2s ease-in-out infinite, spin 30s linear infinite;
      filter: drop-shadow(0 10px 15px rgba(79, 70, 229, 0.2));
    }
    
    .coming-soon-content h1 {
      font-family: var(--font-display);
      font-size: var(--text-5xl);
      margin-bottom: var(--spacing-sm);
      color: var(--color-gray-900);
      font-weight: var(--font-bold);
      background: linear-gradient(135deg, var(--color-gray-900) 0%, var(--color-primary) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: slideUp 0.8s ease-out;
      position: relative;
    }
    
    .coming-soon-content h1::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 80px;
      height: 4px;
      background: linear-gradient(90deg, var(--color-primary-light), var(--color-primary));
      border-radius: var(--radius-full);
    }
    
    .feature-name {
      font-size: var(--text-2xl);
      background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-dark) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: var(--font-semibold);
      margin-bottom: var(--spacing-lg);
      animation: slideUp 0.8s ease-out 0.1s backwards;
    }
    
    .message {
      font-size: var(--text-lg);
      color: var(--color-gray-600);
      margin-bottom: var(--spacing-2xl);
      line-height: 1.6;
      animation: slideUp 0.8s ease-out 0.2s backwards;
    }
    
    .pricing-note {
      color: var(--color-gray-500);
      font-size: 0.9em;
      display: block;
      padding-top: var(--spacing-sm);
      animation: fadeIn 1s ease-out 0.3s backwards;
    }
    
    .actions {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-md);
      max-width: 300px;
      margin: 0 auto;
      animation: slideUp 0.8s ease-out 0.3s backwards;
    }
    
    .btn-primary {
      display: block;
      padding: var(--spacing-md) var(--spacing-xl);
      background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-dark) 100%);
      color: white;
      border-radius: var(--radius-full);
      text-decoration: none;
      font-weight: var(--font-medium);
      transition: all var(--transition-normal);
      box-shadow: var(--shadow-md);
      position: relative;
      overflow: hidden;
    }
    
    .btn-primary::after {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,0.2), rgba(255,255,255,0));
      transition: all 0.8s ease;
      z-index: 1;
    }
    
    .btn-primary:hover {
      background: linear-gradient(135deg, var(--color-primary-dark) 0%, var(--color-primary) 100%);
      transform: translateY(-3px);
      box-shadow: var(--shadow-lg);
      color: white;
    }
    
    .btn-primary:hover::after {
      left: 100%;
    }
    
    .btn-secondary {
      display: block;
      padding: var(--spacing-md) var(--spacing-xl);
      background: var(--color-gray-100);
      color: var(--color-gray-700);
      border-radius: var(--radius-full);
      text-decoration: none;
      font-weight: var(--font-medium);
      transition: all var(--transition-normal);
      border: 1px solid var(--color-gray-200);
    }
    
    .btn-secondary:hover {
      background: var(--color-gray-200);
      transform: translateY(-3px);
      box-shadow: var(--shadow-md);
      color: var(--color-gray-900);
    }
    
    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }
    
    @keyframes slideUp {
      from { transform: translateY(20px); opacity: 0; }
      to { transform: translateY(0); opacity: 1; }
    }
    
    @keyframes pulse {
      0%, 100% { transform: scale(1); }
      50% { transform: scale(1.05); }
    }
    
    @keyframes spin {
      from { transform: rotate(0deg); }
      to { transform: rotate(360deg); }
    }
    
    @keyframes float {
      0%, 100% { transform: translate(0, 0); }
      50% { transform: translate(-20px, 20px); }
    }
    
    @media (max-width: 768px) {
      .construction-icon {
        width: 60px;
        height: 60px;
      }
      
      .coming-soon-content h1 {
        font-size: var(--text-3xl);
      }
      
      .feature-name {
        font-size: var(--text-xl);
      }
      
      .message {
        font-size: var(--text-base);
      }
      
      .bg-circle-1 {
        width: 300px;
        height: 300px;
      }
      
      .bg-circle-2 {
        width: 200px;
        height: 200px;
      }
    }
  `;
  document.head.appendChild(style);
}
