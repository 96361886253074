class HeroSection extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
    this.shadowRoot.innerHTML = `
      <style>
        :host {
          display: block;
          padding: var(--spacing-3xl) var(--spacing-md) var(--spacing-6xl);
          background: linear-gradient(to bottom, var(--color-white), var(--color-gray-50));
          color: var(--color-gray-900);
          position: relative;
          overflow: hidden;
          margin-bottom: var(--spacing-4xl);
        }

        /* Background decorative elements */
        :host::before {
          content: '';
          position: absolute;
          top: -50%;
          right: -20%;
          width: 800px;
          height: 800px;
          border-radius: 50%;
          background: radial-gradient(circle, rgba(79, 70, 229, 0.05) 0%, rgba(79, 70, 229, 0) 70%);
          z-index: 0;
        }

        :host::after {
          content: '';
          position: absolute;
          bottom: -10%;
          left: -10%;
          width: 600px;
          height: 600px;
          border-radius: 50%;
          background: radial-gradient(circle, rgba(16, 185, 129, 0.05) 0%, rgba(16, 185, 129, 0) 70%);
          z-index: 0;
        }

        .hero-container {
          max-width: var(--content-width-lg);
          margin: 0 auto;
          text-align: center;
          position: relative;
          z-index: 1;
        }

        h1 {
          font-family: var(--font-display);
          font-size: var(--text-6xl);
          font-weight: var(--font-bold);
          line-height: 1.1;
          letter-spacing: -0.03em;
          margin-bottom: var(--spacing-lg);
          background: linear-gradient(135deg, var(--color-gray-900) 0%, var(--color-primary) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          animation: fadeIn var(--transition-slow), float 6s ease-in-out infinite;
        }

        @keyframes float {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
        }

        .subtitle {
          font-size: var(--text-xl);
          color: var(--color-gray-500);
          max-width: 650px;
          margin: 0 auto var(--spacing-2xl);
          line-height: 1.5;
          animation: slideUp var(--transition-slow);
        }

        .cta-container {
          display: flex;
          gap: var(--spacing-md);
          justify-content: center;
          margin-bottom: var(--spacing-3xl);
          animation: slideUp var(--transition-slow) 0.2s;
        }

        .cta-primary {
          background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-dark) 100%);
          color: var(--color-white);
          font-size: var(--text-lg);
          padding: var(--spacing-md) var(--spacing-2xl);
          border-radius: var(--radius-full);
          border: none;
          cursor: pointer;
          font-weight: var(--font-medium);
          transition: all var(--transition-normal);
          position: relative;
          overflow: hidden;
          box-shadow: var(--shadow-md);
        }

        .cta-primary::after {
          content: '';
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,0.2), rgba(255,255,255,0));
          transition: all 0.8s ease;
          z-index: 1;
        }

        .cta-primary:hover {
          transform: translateY(-3px);
          box-shadow: var(--shadow-lg);
          background: linear-gradient(135deg, var(--color-primary-dark) 0%, var(--color-primary) 100%);
        }

        .cta-primary:hover::after {
          left: 100%;
        }

        .cta-secondary {
          background: var(--color-gray-100);
          color: var(--color-gray-900);
          font-size: var(--text-lg);
          padding: var(--spacing-md) var(--spacing-2xl);
          border-radius: var(--radius-full);
          border: none;
          cursor: pointer;
          font-weight: var(--font-medium);
          transition: all var(--transition-normal);
          box-shadow: 0 0 0 1px var(--color-gray-200);
        }

        .cta-secondary:hover {
          background: var(--color-gray-200);
          transform: translateY(-3px);
          box-shadow: var(--shadow-md);
        }

        .features {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
          gap: var(--spacing-2xl);
          margin-top: var(--spacing-3xl);
          animation: fadeIn var(--transition-slow) 0.4s;
          margin-bottom: var(--spacing-2xl);
        }

        .feature {
          text-align: center;
          padding: var(--spacing-xl);
          background: rgba(255, 255, 255, 0.7);
          backdrop-filter: blur(10px);
          border-radius: var(--radius-lg);
          box-shadow: var(--shadow-md);
          transition: all var(--transition-normal);
          border: 1px solid var(--color-gray-100);
        }
        
        .feature:hover {
          transform: translateY(-5px);
          box-shadow: var(--shadow-lg);
          border-color: var(--color-primary-light);
        }

        .feature-icon {
          font-size: var(--text-4xl);
          margin-bottom: var(--spacing-md);
          background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-light) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: inline-block;
        }

        .feature h3 {
          font-size: var(--text-xl);
          font-weight: var(--font-semibold);
          margin-bottom: var(--spacing-sm);
          color: var(--color-gray-900);
          position: relative;
          display: inline-block;
        }

        .feature h3::after {
          content: '';
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
          width: 40px;
          height: 2px;
          background: var(--color-primary-light);
          border-radius: var(--radius-full);
          transition: width var(--transition-normal);
        }

        .feature:hover h3::after {
          width: 60px;
        }

        .feature p {
          color: var(--color-gray-500);
          line-height: 1.6;
        }

        /* Section divider */
        .section-divider {
          height: 80px;
          width: 100%;
          position: relative;
          margin-top: var(--spacing-2xl);
        }

        .section-divider::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 60px;
          height: 4px;
          background: linear-gradient(90deg, var(--color-primary-light), var(--color-primary));
          border-radius: var(--radius-full);
        }

        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }

        @keyframes slideUp {
          from { transform: translateY(20px); opacity: 0; }
          to { transform: translateY(0); opacity: 1; }
        }

        @media (max-width: 768px) {
          :host {
            padding: var(--spacing-2xl) var(--spacing-md) var(--spacing-4xl);
            margin-bottom: var(--spacing-3xl);
          }

          h1 {
            font-size: var(--text-4xl);
          }

          .subtitle {
            font-size: var(--text-lg);
            padding: 0 var(--spacing-md);
          }

          .cta-container {
            flex-direction: column;
            padding: 0 var(--spacing-xl);
            gap: var(--spacing-md);
          }

          .features {
            grid-template-columns: 1fr;
            gap: var(--spacing-lg);
          }

          .section-divider {
            height: 50px;
          }
        }

        @media (min-width: 769px) and (max-width: 1024px) {
          h1 {
            font-size: var(--text-5xl);
          }

          .features {
            grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
          }
        }
      </style>

      <div class="hero-container">
        <h1>Express Gratitude<br>with Elegance</h1>
        <p class="subtitle">Create and send beautiful thank you cards that leave a lasting impression. Designed for those who appreciate the art of gratitude.</p>
        
        <div class="cta-container">
          <button class="cta-primary">Create Your Card</button>
          <button class="cta-secondary">View Templates</button>
        </div>

        <div class="features">
          <div class="feature">
            <div class="feature-icon">✨</div>
            <h3>Beautiful Templates</h3>
            <p>Professionally designed templates for every occasion, customizable to your style.</p>
          </div>
          <div class="feature">
            <div class="feature-icon">⚡️</div>
            <h3>Quick & Easy</h3>
            <p>Create stunning cards in minutes with our intuitive editor.</p>
          </div>
          <div class="feature">
            <div class="feature-icon">🎯</div>
            <h3>Perfect Delivery</h3>
            <p>Send digitally or have it printed and mailed. Always on time.</p>
          </div>
        </div>
        
        <div class="section-divider"></div>
      </div>
    `;

    // Add event listeners
    this.shadowRoot.querySelector('.cta-primary').addEventListener('click', () => {
      window.location.hash = '/create';
    });

    this.shadowRoot.querySelector('.cta-secondary').addEventListener('click', () => {
      window.location.hash = '/templates';
    });
  }
}

customElements.define('hero-section', HeroSection);
