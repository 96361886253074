import '../components/AppLayout/AppLayout.js';

export function loadBlogPage() {
  const app = document.getElementById('app');
  if (!app) {
    return;
  }

  app.innerHTML = `
    <app-layout>
      <div class="blog-container">
        <div class="blog-header">
          <h1>Blog</h1>
          <p class="subtitle">Insights on gratitude, relationships, and personal growth</p>
        </div>

        <div class="blog-grid">
          <article class="blog-card">
            <img src="/assets/images/blog/gratitude-science.jpg" alt="The Science of Gratitude" class="blog-image">
            <div class="blog-content">
              <div class="blog-meta">
                <span class="blog-category">Research</span>
                <span class="blog-date">March 15, 2024</span>
              </div>
              <h2>The Science of Gratitude: Why Thank You Notes Matter</h2>
              <p>Discover the psychological benefits of expressing gratitude through written notes and how it impacts both the sender and receiver.</p>
              <a href="#" class="read-more">Read More</a>
            </div>
          </article>

          <article class="blog-card">
            <img src="/assets/images/blog/business-thanks.jpg" alt="Business Thank You Notes" class="blog-image">
            <div class="blog-content">
              <div class="blog-meta">
                <span class="blog-category">Business</span>
                <span class="blog-date">March 10, 2024</span>
              </div>
              <h2>Writing Effective Business Thank You Notes</h2>
              <p>Learn how to craft professional thank you notes that strengthen business relationships and leave a lasting impression.</p>
              <a href="#" class="read-more">Read More</a>
            </div>
          </article>

          <article class="blog-card">
            <img src="/assets/images/blog/digital-gratitude.jpg" alt="Digital Gratitude" class="blog-image">
            <div class="blog-content">
              <div class="blog-meta">
                <span class="blog-category">Digital</span>
                <span class="blog-date">March 5, 2024</span>
              </div>
              <h2>Digital Gratitude in a Connected World</h2>
              <p>Explore how technology is transforming the way we express appreciation while maintaining authenticity.</p>
              <a href="#" class="read-more">Read More</a>
            </div>
          </article>
        </div>

        <div class="blog-newsletter">
          <h2>Stay Updated</h2>
          <p>Get the latest articles on gratitude and personal growth delivered to your inbox.</p>
          <form class="newsletter-form">
            <input type="email" placeholder="Enter your email" required>
            <button type="submit" class="btn-primary">Subscribe</button>
          </form>
        </div>
      </div>
    </app-layout>
  `;

  // Add styles
  const style = document.createElement('style');
  style.textContent = `
    .blog-container {
      padding: var(--spacing-3xl) var(--spacing-md);
      max-width: var(--content-width-lg);
      margin: 0 auto;
    }

    .blog-header {
      text-align: center;
      margin-bottom: var(--spacing-3xl);
    }

    .blog-header h1 {
      font-family: var(--font-display);
      font-size: var(--text-5xl);
      color: var(--color-gray-900);
      margin-bottom: var(--spacing-md);
    }

    .subtitle {
      font-size: var(--text-xl);
      color: var(--color-gray-500);
    }

    .blog-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: var(--spacing-xl);
      margin-bottom: var(--spacing-3xl);
    }

    .blog-card {
      background: var(--color-white);
      border-radius: var(--radius-lg);
      overflow: hidden;
      box-shadow: var(--shadow-md);
      transition: all var(--transition-normal);
    }

    .blog-card:hover {
      transform: translateY(-4px);
      box-shadow: var(--shadow-lg);
    }

    .blog-image {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }

    .blog-content {
      padding: var(--spacing-xl);
    }

    .blog-meta {
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--spacing-md);
      font-size: var(--text-sm);
    }

    .blog-category {
      color: var(--color-primary);
      font-weight: var(--font-medium);
    }

    .blog-date {
      color: var(--color-gray-500);
    }

    .blog-content h2 {
      font-size: var(--text-xl);
      color: var(--color-gray-900);
      margin-bottom: var(--spacing-md);
      line-height: 1.4;
    }

    .blog-content p {
      color: var(--color-gray-500);
      line-height: 1.6;
      margin-bottom: var(--spacing-md);
    }

    .read-more {
      color: var(--color-primary);
      text-decoration: none;
      font-weight: var(--font-medium);
      display: inline-flex;
      align-items: center;
      gap: var(--spacing-xs);
    }

    .read-more:hover {
      text-decoration: underline;
    }

    .blog-newsletter {
      text-align: center;
      background: var(--color-gray-50);
      border-radius: var(--radius-lg);
      padding: var(--spacing-2xl);
      margin-top: var(--spacing-3xl);
    }

    .blog-newsletter h2 {
      font-family: var(--font-display);
      font-size: var(--text-3xl);
      color: var(--color-gray-900);
      margin-bottom: var(--spacing-sm);
    }

    .blog-newsletter p {
      color: var(--color-gray-500);
      margin-bottom: var(--spacing-xl);
    }

    .newsletter-form {
      display: flex;
      gap: var(--spacing-md);
      max-width: 500px;
      margin: 0 auto;
    }

    .newsletter-form input {
      flex: 1;
      padding: var(--spacing-md);
      border: 1px solid var(--color-gray-200);
      border-radius: var(--radius-md);
      font-size: var(--text-base);
    }

    .newsletter-form input:focus {
      outline: none;
      border-color: var(--color-primary);
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    }

    .btn-primary {
      padding: var(--spacing-md) var(--spacing-xl);
      background: var(--color-primary);
      color: var(--color-white);
      border: none;
      border-radius: var(--radius-md);
      font-weight: var(--font-medium);
      cursor: pointer;
      transition: all var(--transition-normal);
    }

    .btn-primary:hover {
      transform: translateY(-2px);
      box-shadow: var(--shadow-lg);
    }

    @media (max-width: 768px) {
      .blog-header h1 {
        font-size: var(--text-4xl);
      }

      .blog-grid {
        grid-template-columns: 1fr;
        padding: 0 var(--spacing-md);
      }

      .newsletter-form {
        flex-direction: column;
        padding: 0 var(--spacing-md);
      }
    }
  `;
  document.head.appendChild(style);
}
